import {
    WATCHMAN_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL,
    WATCHMAN_VEHICLE_DASHBOARD_DETAILS_ERROR,
    WATCHMAN_VEHICLE_DASHBOARD_DETAILS_LOADING,
    WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_SUCCESSFULL,
    WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_ERROR,
    WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_LOADING,
    WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_SUCCESSFULL,
    WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_ERROR,
    WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_LOADING,
    WATCHMAN_GET_VEHICLE_DETAILS_SUCCESSFULL,
    WATCHMAN_GET_VEHICLE_DETAILS_ERROR,
    WATCHMAN_GET_VEHICLE_DETAILS_LOADING
} from '../../action/type';

import * as AuthService from '../../service/services'

export const Vehicle_watchman_dashboard_details=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: WATCHMAN_VEHICLE_DASHBOARD_DETAILS_LOADING,
                data: true
            })
            AuthService.WatchmanVehicleDashboardDetails()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:WATCHMAN_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:WATCHMAN_VEHICLE_DASHBOARD_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_watchman_change_status_on_field=(vehicle_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_LOADING,
                data: true
            })
            AuthService.vehicleWatchmanChangeStatusOnField(vehicle_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_watchman_change_status_parked=(vehicle_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_LOADING,
                data: true
            })
            AuthService.vehicleWatchmanChangeStatusParked(vehicle_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const get_vehicle_details=(vehicle_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: WATCHMAN_GET_VEHICLE_DETAILS_LOADING,
                data: true
            })
            AuthService.getVehicleDetails(vehicle_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:WATCHMAN_GET_VEHICLE_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:WATCHMAN_GET_VEHICLE_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}