import React, { useEffect, useRef, useState} from 'react'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'date-fns'
import Footer from '../../DashBoard/Footer';
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Loader from '../../common/Loader/Loader';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/Vehicle/vehicle_action';
import $ from 'jquery'
import { renderToString } from 'react-dom/server'
function Vehicle_List() {
    $.DataTable = require('datatables.net')
    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.vehicleListReducer)
    const tableRef = useRef();
    const [vehicleList,setVihicleList]=useState([]);
    const setUpdateVehicle = (vehicle) => {
        // let new_address = address[6];
        // setEditAddress({
        //     addressId:new_address._id,
        //     address: new_address.address,
        //     dispatchAddress: new_address.dispatchAddress,
        //     pincode: new_address.pincode,
        //     country: "India",
        //     state: new_address.state,
        //     city: new_address.city,
        //     landmark: new_address.landmark,
        //     contactPersonName: new_address.contactPersonName,
        //     contactPersonMobile: new_address.contactPersonMobile,
        //     latitude: new_address.latitude,
        //     longitude: new_address.longitude,
        //     isDefault: new_address.isDefault,
        // });
    }
    const changeDateFormate = (date) => {
        if (date) {
            let new_date = String(date);
            let ndate = new_date.split('T');
            let format_date = format(new Date(ndate[0]), 'dd MMM yyyy');
            let format_time = new Date(date).toLocaleTimeString('en-US');;
            return format_date +'<br/>'+format_time;
        }
    }
    const activeDeactiveAlertPopup = (vehicle) => {
        console.log(vehicle);
        dispatch(action.vehicle_status_change(vehicle[10])).then((data)=>{
            toast.success(data.msg);
            let newVehicleList = [];
            vehicleList.map(item => {
                if(item[10] === vehicle[10]){
                    let vihicle_data = data.data;
                    newVehicleList.push([vihicle_data.hasOwnProperty('sr_number') ? vihicle_data.sr_number:null, vihicle_data.registered === 1 ? vihicle_data.number: vihicle_data.name ? vihicle_data.name:null, `<a style="cursor: pointer;" href=${item.tracknowurl ? item.tracknowurl : '#'} target=${item.tracknowurl ? '_blank' : '_self'} class="btn btn-info btn-sm view-btn view-act-btn" data-id="${item._id}">Track Now</a>`, vihicle_data.ownerName, vihicle_data.type, vehicle[5], vehicle[6], vihicle_data.isActive, `<a style="cursor: pointer;" href="/adminside/vehicle/view/${vihicle_data._id}" class="btn btn-info btn-sm view-btn view-act-btn">View</a>`,`<a style="cursor: pointer;" href="/adminside/vehicle/use/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn">Export</a>`, vihicle_data._id, vihicle_data]);
                } else {
                    newVehicleList.push(item);
                }
            })
            setVihicleList(newVehicleList)
        }).catch(error=>{
            toast.error(error);
        })
    }
    const tableName = "vehicle-list";
    useEffect(() => {
        dispatch(action.Vehicle_list()).then((data)=>{
            let newVehicleList = [];
            data.data.map(item => {
                let driver_details = '';
                if(item.vehicleDriverData){
                    driver_details = item.vehicleDriverData.name+'<br/>'+changeDateFormate(item.vehicleDriverData.vehicleAssignDate);
                }
                let customer_details = '';
                if(item.customerData){
                    customer_details = item.customerData.name;
                }
                newVehicleList.push([item.hasOwnProperty('sr_number') ? item.sr_number:null, item.registered === 1 ? item.number: item.name ? item.name:null, `<a style="cursor: pointer;" href=${item.tracknowurl ? item.tracknowurl : '#'} target=${item.tracknowurl ? '_blank' : '_self'} class="btn btn-info btn-sm view-btn view-act-btn" data-id="${item._id}">Track Now</a>`, item.ownerName, item.type, driver_details, customer_details, item.isActive, `<a style="cursor: pointer;" href="/adminside/vehicle/view/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${item._id}">View</a>`,`<a style="cursor: pointer;" href="/adminside/vehicle/use/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn">Export</a>`, item._id, item]);
            })
            setVihicleList(newVehicleList)
        }).catch({})
    },[])
    useEffect(()=>{
        const table = $(`#${tableName}`).DataTable(
            {
                dom: 'Bfrtip',
                buttons: [
                    'copyHtml5', 'excelHtml5', 'pdfHtml5', 'csvHtml5'
                ],
                data: vehicleList,
                columns: [
                    { title: "Sr. Number"},
                    { title: "Vehicle Number"},
                    { title: "Track Now"},
                    { title: "Owner Name"},
                    { title: "Type (In Wheeler)"},
                    { title: "Driver"},
                    { title: "Customer Name"},
                    { title: "Status"},
                    { title: "Action"},
                    { title: "Export"},
                ],
                columnDefs: [
                    {
                        targets: [7],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <a style={{cursor: "pointer"}} className={rowData[7] === 1 ? 'btn btn-success btn-sm status-act-btn' : 'btn btn-danger btn-sm status-act-btn'} onClick={() => activeDeactiveAlertPopup(rowData)}> {rowData[7] === 1 ? 'Active' : 'Deactive'} </a>,td
                        )
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                oLanguage: {
                    oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                    //sInfo: "Showing page _PAGE_ of _PAGES_",
                    sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                    sSearchPlaceholder: "Search...",
                    sLengthMenu: "Results :  _MENU_",
                },
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[vehicleList])
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover  theme="light"/>
            <Header />
            <Sub_Header/>
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                <div className="layout-px-spacing">

                    <div className="row layout-top-spacing">

                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                            <div className="widget-content widget-content-area br-6">
                                <h5 className="" style={{margin: '10px'}}>Vehicle List</h5>
                                <div className="table-responsive mb-4 mt-4">
                                    <table id="vehicle-list" className="table table-hover" style={{width:'100%'}}>
                                        <thead>
                                            <tr>
                                                <th>Sr. Number</th>
                                                <th>Vehicle Number</th>
                                                <th>Track Now</th>
                                                <th>Owner Name</th>
                                                <th>Type (In Wheeler)</th>
                                                <th>Driver</th>
                                                <th>Customer Name</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                                <th>Export</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <Footer></Footer>
            </div>
            </div>
        </>
    )
}
export default Vehicle_List