import React, { useEffect, useRef, useState} from 'react'
import { useNavigate, useParams }from 'react-router-dom'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import { format } from 'date-fns'
import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import Loader from '../../common/Loader/Loader';
import Footer from '../../DashBoard/Footer';
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import {ExportToExcel} from './ExportToExcelForVehicle'
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/Vehicle/vehicle_action';
import $ from 'jquery'
const Driver_Work_history = props => {
    $.DataTable = require('datatables.net')
    const dispatch = useDispatch();
    const {vehicle_id} = useParams();
    const {loading} = useSelector(state => state.GetDriverListVehicleUseReducer)
    var date = new Date();
    const [input , setInput]= useState([
        new Date(date.getFullYear(), date.getMonth(), 1),
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
    ]);
    const changeDateFormate = (date) => {
        if (date) {
            let new_date = String(date);
            let ndate = new_date.split('T');
            let format_date = format(new Date(ndate[0]), 'dd MMM yyyy');
            return format_date;
        }
    }
    const predefinedRanges = [
        {
            label: 'Current month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 60 days',
            value: [subDays(new Date(), 59), new Date()],
            placement: 'left'
        }
    ];
    const [driverVehicleList,setDriverVehicleList]=useState([]);
    const [driverVehicleListExcel,setDriverVehicleListExcel]=useState([]);
    useEffect(() => {
        dispatch(action.driver_use_vehicle_list(input[0], input[1], vehicle_id)).then((data)=>{
            let newDriverWorkList = [];
            let vehicleData = '';
            let vehicleType = '';
            data.data.map(item => {
                if(item.vehicleData){
                    vehicleType = item.vehicleData.type;
                    if(item.vehicleData.number){
                        vehicleData = item.vehicleData.number;
                    } else {
                        vehicleData = item.vehicleData.name;
                    }
                }
                newDriverWorkList.push([changeDateFormate(item.createdAt), item.driverData.name, vehicleData, vehicleType, `${item.customerData.businessName} <br> ${item.customerData.name} <br> ${item.customerData.mobile}`]);
            })
            setDriverVehicleList(newDriverWorkList)
            setDriverVehicleListExcel(data.data);
        }).catch({})
    },[input])
    useEffect(()=>{
        const tableName = "driver-vehicles-list";
        const table = $(`#${tableName}`).DataTable({
            dom: 'Bfrtip',
            buttons: [
                'copyHtml5', 'excelHtml5', 'pdfHtml5', 'csvHtml5'
            ],
            data: driverVehicleList,
            columns: [
                {title: "Date"},
                {title: "Driver"},
                {title: "Vehicle Number"},
                {title: "Type (In Wheeler)"},
                {title: "Customer"},
            ],
            destroy: true,  // I think some clean up is happening here
            searching: true,
            oLanguage: {
                oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                //sInfo: "Showing page _PAGE_ of _PAGES_",
                sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                sSearchPlaceholder: "Search...",
                sLengthMenu: "Results :  _MENU_",
            },
        })
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[driverVehicleList])
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover  theme="light"/>
            <Header />
            <Sub_Header/>
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing">

                        <div className="row layout-top-spacing">

                            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                <div className="widget-content widget-content-area br-6">
                                    <h5 className="" style={{margin: '10px'}}>Vehicle Usage List</h5>
                                    <div class="table-responsive mb-4 mt-4">
                                        <div class="form-group row col-12 ml-1">
                                            <DateRangePicker ranges={predefinedRanges} value={input} onChange={setInput} style={{ width: 300 }} placeholder="Select Date Range"/> &nbsp;
                                            {
                                                (driverVehicleListExcel && driverVehicleListExcel.length) ?
                                                <ExportToExcel vehicleData={driverVehicleListExcel}/> : <div className='btn btn-success disabled'>Export to XLS</div> 
                                            }
                                        </div>
                                        <table id="driver-vehicles-list" class="table table-hover"
                                            style={{width:'100%'}}>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Driver</th>
                                                    <th>Vehicle Number</th>
                                                    <th>Type In Wheeler</th>
                                                    <th>Customer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Driver_Work_history