import {
    ADD_BIKE_TYPE_SUCCESSFULL,
    ADD_BIKE_TYPE_ERROR,
    ADD_BIKE_TYPE_LOADING,
    BIKE_TYPE_LIST_SUCCESSFULL,
    BIKE_TYPE_LIST_ERROR,
    BIKE_TYPE_LIST_LOADING,
    BIKE_TYPE_STATUS_CHANGE_SUCCESSFULL,
    BIKE_TYPE_STATUS_CHANGE_ERROR,
    BIKE_TYPE_STATUS_CHANGE_LOADING,
    BIKE_TYPE_DETAILS_SUCCESSFULL,
    BIKE_TYPE_DETAILS_ERROR,
    BIKE_TYPE_DETAILS_LOADING,
    BIKE_TYPE_EDIT_SUCCESSFULL,
    BIKE_TYPE_EDIT_ERROR,
    BIKE_TYPE_EDIT_LOADING,
    BIKE_TYPE_DELETE_SUCCESSFULL,
    BIKE_TYPE_DELETE_ERROR,
    BIKE_TYPE_DELETE_LOADING
} from '../../action/type';

import * as AuthService from '../../service/services'

export const add_bike_type=(credentail)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: ADD_BIKE_TYPE_LOADING,
                data: true
            })
            AuthService.AddBikeType(credentail)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:ADD_BIKE_TYPE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:ADD_BIKE_TYPE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const bike_type_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: BIKE_TYPE_LIST_LOADING,
                data: true
            })
            AuthService.BikeTypeList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:BIKE_TYPE_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:BIKE_TYPE_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const bike_type_status_change=(bike_type_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: BIKE_TYPE_STATUS_CHANGE_LOADING,
                data: true
            })
            AuthService.BikeTypeStatusChange(bike_type_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:BIKE_TYPE_STATUS_CHANGE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:BIKE_TYPE_STATUS_CHANGE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const bike_type_details=(bike_type_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: BIKE_TYPE_DETAILS_LOADING,
                data: true
            })
            AuthService.BikeTypeDetails(bike_type_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:BIKE_TYPE_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:BIKE_TYPE_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const edit_bike_type=(credential)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: BIKE_TYPE_EDIT_LOADING,
                data: true
            })
            AuthService.EditBikeType(credential)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:BIKE_TYPE_EDIT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:BIKE_TYPE_EDIT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const delete_bike_type=(credential)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: BIKE_TYPE_DELETE_LOADING,
                data: true
            })
            AuthService.deleteBikeType(credential)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:BIKE_TYPE_DELETE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:BIKE_TYPE_DELETE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}