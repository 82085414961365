import {
    ADD_VEHICLE_HUB_SUCCESSFULL,
    ADD_VEHICLE_HUB_ERROR,
    ADD_VEHICLE_HUB_LOADING,
    VEHICLE_HUB_LIST_SUCCESSFULL,
    VEHICLE_HUB_LIST_ERROR,
    VEHICLE_HUB_LIST_LOADING,
    VEHICLE_HUB_STATUS_CHANGE_SUCCESSFULL,
    VEHICLE_HUB_STATUS_CHANGE_ERROR,
    VEHICLE_HUB_STATUS_CHANGE_LOADING,
    VEHICLE_HUB_DETAILS_SUCCESSFULL,
    VEHICLE_HUB_DETAILS_ERROR,
    VEHICLE_HUB_DETAILS_LOADING,
    VEHICLE_HUB_EDIT_SUCCESSFULL,
    VEHICLE_HUB_EDIT_ERROR,
    VEHICLE_HUB_EDIT_LOADING,
    VEHICLE_HUB_MANAGER_LIST_SUCCESSFULL,
    VEHICLE_HUB_MANAGER_LIST_ERROR,
    VEHICLE_HUB_MANAGER_LIST_LOADING,
} from '../../action/type';

import * as AuthService from '../../service/services'

export const add_vehicle_hub=(credentail)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: ADD_VEHICLE_HUB_LOADING,
                data: true
            })
            AuthService.AddVehicleHub(credentail)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:ADD_VEHICLE_HUB_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:ADD_VEHICLE_HUB_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_hub_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_HUB_LIST_LOADING,
                data: true
            })
            AuthService.VehicleHubList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_HUB_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_HUB_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_hub_status_change=(vehicle_hub_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_HUB_STATUS_CHANGE_LOADING,
                data: true
            })
            AuthService.VehicleHubStatusChange(vehicle_hub_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_HUB_STATUS_CHANGE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_HUB_STATUS_CHANGE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_hub_details=(vehicle_hub_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_HUB_DETAILS_LOADING,
                data: true
            })
            AuthService.VehicleHubDetails(vehicle_hub_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_HUB_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_HUB_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const edit_vehicle_hub=(credential)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_HUB_EDIT_LOADING,
                data: true
            })
            AuthService.EditVehicleHub(credential)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_HUB_EDIT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_HUB_EDIT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_hub_manager_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_HUB_MANAGER_LIST_LOADING,
                data: true
            })
            AuthService.VehicleHubManagerList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_HUB_MANAGER_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_HUB_MANAGER_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}