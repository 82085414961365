import React, { useEffect, useState } from 'react';
import { useNavigate }from 'react-router-dom'
import { App } from '../../assets/js/app.js';
import {useDispatch, useSelector} from 'react-redux'
import * as action from '../../action/Auth/login_action';
function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {notificationData} = useSelector(state => state.notificationReducer)
    const [notificationList,setNotificaationList]=useState(notificationData?.AdminNotificationList || []);
    const logout = () => {
        dispatch(action.logout()).then((data)=>{
            navigate('/adminside/login');
        }).catch(error=>{
            navigate('/adminside/login');
        })
    }
    useEffect(() => {
        setInterval(() => {
            dispatch(action.notification()).then((data)=>{
                setNotificaationList(data.AdminNotificationList)
            }).catch({})
        }, 10000);
    },[])
    useEffect(() => {
        App.init()
    }, [])
    return (
        <div>
            <div className="header-container fixed-top">
                <header className="header navbar navbar-expand-sm">

                    <ul className="navbar-item theme-brand flex-row  text-center">
                        <li className="nav-item theme-logo">
                            <a href="#">
                                <img src="/assets/img/logo.png" className="navbar-logo" alt="logo" />
                            </a>
                        </li>
                        <li className="nav-item theme-text">
                            <a href="#" className="nav-link"> EVIFY </a>
                        </li>
                    </ul>
                    <ul className="navbar-item flex-row ml-md-auto">
                        <li className="nav-item dropdown user-profile-dropdown">
                            <a href="/" className="nav-link dropdown-toggle user text-white p-0" id="notificationDropdown" data-toggle="dropdown" aria-expanded="true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>   
                                <span class="badge badge-light" style={{width: '20px', height: '20px', lineHeight: '20px', position: 'relative', bottom: '10px', right: '10px', borderRadius: '50%', padding: '0px'}}>{notificationList.length}</span>
                            </a>
                            {
                                notificationList.length > 0 ?
                                <div className="dropdown-menu position-absolute arrow-postion" aria-labelledby="notificationDropdown">
                                    <div className="">
                                        {
                                            notificationList.map((notification)=>(
                                                <>
                                                    <div className="dropdown-item">
                                                        <h6>{notification.title}</h6>
                                                        <p>{notification.text}</p>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                </div> : <div className="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                                <div className="">
                                    <div className="dropdown-item">
                                        <p>No Notification Available</p>
                                    </div>
                                </div>
                            </div>
                            }
                        </li>
                        <li className="nav-item dropdown user-profile-dropdown">
                            <a href="/" className="nav-link dropdown-toggle user pl-0" id="userProfileDropdown" data-toggle="dropdown" aria-expanded="true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                            </a>
                            <div className="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                                <div className="">
                                    <div className="dropdown-item">
                                        <a className="" href="/adminside/profile"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> My Profile</a>
                                    </div>
                                    <div className="dropdown-item">
                                        <a className="" style={{cursor: "pointer"}} onClick={logout}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg> Sign Out</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </header>
            </div>
        </div>
    )
}

export default React.memo(Header);