import React, {useState, useEffect} from 'react';
import './Offline.css';

const Offline = ({ children }) => {
    const [online,setOnline] = useState(navigator.onLine);
    
    useEffect(() => {
        window.addEventListener('online', () => setOnline(true));
        window.addEventListener('offline', () => setOnline(false));

        return () => {
            window.removeEventListener('online', () => setOnline(true));
            window.removeEventListener('offline', () => setOnline(false));
        };
    }, []);

    return (
        <>
            <div>
                {
                    !online && (
                        <div className="offline">
                            <div className="content">
                                <div className="text offline-wrap">
                                    <div className='pr-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-wifi-off"><line x1="1" y1="1" x2="23" y2="23"></line><path d="M16.72 11.06A10.94 10.94 0 0 1 19 12.55"></path><path d="M5 12.55a10.94 10.94 0 0 1 5.17-2.39"></path><path d="M10.71 5.05A16 16 0 0 1 22.58 9"></path><path d="M1.42 9a15.91 15.91 0 0 1 4.7-2.88"></path><path d="M8.53 16.11a6 6 0 0 1 6.95 0"></path><line x1="12" y1="20" x2="12.01" y2="20"></line></svg>
                                    </div>
                                    <div>
                                        <div className="offline-title mt-0 mb-1">You're offline</div>
                                        <div className="offline-sub mt-0 mb-0">Check your internet connection.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className={online ? 'offlinevisible' : 'offlineoverlay'}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default Offline;