import React, { useEffect, useRef, useState} from 'react'
import { useNavigate, useParams }from 'react-router-dom'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Footer from '../../DashBoard/Footer';
import { ToastContainer, toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import { format } from 'date-fns'
import subDays from 'date-fns/subDays';
import Select from 'react-select'
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import Loader from '../../common/Loader/Loader';
import {ExportToExcel} from './ExportToExcel'
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/Driver/driver_action';
import $ from 'jquery'
const All_Driver_Work_history = props => {
    $.DataTable = require('datatables.net')
    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.AllDriverWorkListReduces)
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    let end  =  new Date()
    end.setHours(23, 59, 59, 999);
    const [driverList,setDriverList]=useState([])
    const [driverId,setDriverId]=useState('')
    const [values,setValues]=useState({
        driverValues:""
    });
    // const customFilter = (option, inputValue) => {
    //     const input = inputValue.toLowerCase();
    //     const filterText = new RegExp('^'+input+'.*');
    //     const label = option.data.label.toLowerCase();
    //     return filterText.test(label) && option.label.toLowerCase().includes(input);
    // };
    const changeDriverDropDown = (data) => {
        setValues({...values, driverValues:data});
        setDriverId(data.value);
    }
    const setInputData = (e) => {
        setInput([new Date(e.target.value), new Date(e.target.value)])
    }
    const [input , setInput]= useState([
        new Date(start),
        new Date(end)
    ]);
    const today = new Date();
    const defaultValue = new Date(today).toISOString().split('T')[0]
    const changeDateFormate = (date) => {
        if (date) {
            let new_date = String(date);
            let ndate = new_date.split('T');
            let format_date = format(new Date(ndate[0]), 'dd MMM yyyy');
            return format_date;
        }
    }
    const [totalDeliveredCount, setTotalDeliveredCount] = useState(0);
    const [parcelDetails, setParcelDetails] = useState({
        pickupParcelCount : 0,
        deliveredParcelCount : 0,
        undeliveredOnDoorParcelCount : 0,
        undeliveredParcelCount : 0,
        returnParcelCount : 0,
    });
    useEffect(() => {
        dispatch(action.Driver_list_select()).then((data)=>{
            let driver_details = data.data;
            let newDriverList = [];
            driver_details.map(item => {
                newDriverList.push({
                    value: item._id,
                    label: 'EV_'+item.Driver_Id+' - '+item.name
                });
            })
            setDriverList(newDriverList);
        }).catch({})
    },[])
    const predefinedRanges = [
        {
            label: 'Daily',
            value: [start, end],
            placement: 'left'
        },
        {
            label: 'Current month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 60 days',
            value: [subDays(new Date(), 59), new Date()],
            placement: 'left'
        }
    ];
    const [driverWorkList,setDriverWorkList]=useState([]);
    const [driverWorkExcelList,setDriverWorkExcelList]=useState([]);
    useEffect(() => {
        dispatch(action.all_driver_work_list(input[0], input[1], driverId)).then((data)=>{
            let newDriverWorkList = [];
            setTotalDeliveredCount(data.totalDeliveredCount);
            data.data.map(item => {
                newDriverWorkList.push([item.driverData.name, `${item.customerData.businessName} <br> ${item.customerData.name} <br> ${item.customerData.mobile}`, item.pickupParcelCount ? item.pickupParcelCount:0, item.deliveredParcelCount ? item.deliveredParcelCount:0, `<a style="cursor: pointer;" href="/adminside/driver/View_Driver_Work_Images/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn">View</a>`]);
            })
            setDriverWorkList(newDriverWorkList)
        }).catch({})
    },[input, driverId])
    useEffect(() => {
        dispatch(action.all_driver_work_list(input[0], input[1], driverId)).then((data)=>{
            setDriverWorkExcelList(data.data)
        }).catch({})
    },[input, driverId])
    useEffect(()=>{
        const tableName = "driver-works-list";
        const table = $(`#${tableName}`).DataTable({
            dom: 'Bfrtip',
            buttons: [
                'copyHtml5', 'excelHtml5', 'pdfHtml5', 'csvHtml5'
            ],
            data: driverWorkList,
            columns: [
                {title: "Driver"},
                // {title: "Vehicle"},
                {title: "Customer"},
                // {title: "Customer Address"},
                {title: "Pickup"},
                {title: "Done"},
                {title: "Action"},
            ],
            destroy: true,  // I think some clean up is happening here
            searching: true,
            oLanguage: {
                oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                //sInfo: "Showing page _PAGE_ of _PAGES_",
                sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                sSearchPlaceholder: "Search...",
                sLengthMenu: "Results :  _MENU_",
            },
            'fnInitComplete': function (oSettings) {
                $('#driver-works-list_filter').prepend(`No. Of Deliveries: ${totalDeliveredCount}`);
            },
        })
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[driverWorkList])
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing">

                        <div className="row layout-top-spacing">
                            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                <div className="widget-content widget-content-area br-6">
                                    <h5 className="" style={{ margin: '10px'}}>Driver Daily Works</h5>
                                    <div className="table-responsive mb-4 mt-4">
                                        <div class="form-group row col-12 ml-1">
                                            {/* <DateRangePicker ranges={predefinedRanges} value={input} onChange={setInput} style={{ width: 300 }} placeholder="Select Date Range"/> &nbsp; */}
                                            <input type="date" className="form-control" defaultValue={defaultValue} onChange={(e) => setInputData(e)} style={{ width: 300 }} placeholder="Select Date Range"/> &nbsp;
                                            <div style={{width: '300px'}}><Select id="inputDriverSelect" value={values.driverValues || 'Select'} onChange={value => changeDriverDropDown(value)} options={driverList} /></div> &nbsp;
                                            {
                                                (driverWorkExcelList && driverWorkExcelList.length) ?
                                                <ExportToExcel apiData={driverWorkExcelList} startDate={input[0]}/>
                                                : <div className='btn btn-success disabled'>Export to XLS</div> 
                                            }
                                            
                                        </div>
                                        <table id="driver-works-list" class="table table-hover"
                                            style={{width:'100%'}}>
                                            <thead>
                                                <tr>
                                                    <th>Driver</th>
                                                    <th>Customer</th>
                                                    <th>Pickup</th>
                                                    <th>Done</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}
export default All_Driver_Work_history