import React, { useEffect, useState} from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { format } from 'date-fns'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
ReactHTMLTableToExcel.format = (s, c) => {
	if (c && c['table']) {
	  const html = c.table;
	  const parser = new DOMParser();
	  const doc = parser.parseFromString(html, 'text/html');
	  c.table = doc.querySelector('table').outerHTML;
	}
	return s.replace(/{(\w+)}/g, (m, p) => c[p]);
};
export const ExportToExcel = props => {
	const apiData = props.apiData;
	const changeDateFormate = (date) => {
		if (date) {
			let new_date = String(date);
			let ndate = new_date.split('T');
			let format_date = format(new Date(ndate[0]), 'dd MMM yyyy');
			return format_date;
		}
	}
	const startDate = format(new Date(props.startDate), 'dd MMM yyyy');
	const checkVal = (array, value) => {
		return array.some(function(entry) {
			if (Array.isArray(entry)) {
				return checkVal(entry, value);
			}
			return entry === value;
		});
	}
	let totalColumn = 2;
	let customers = [];
	apiData.map(item => {
		let index = customers.findIndex( x => x.id === item.customerId );
		if(index === -1){
			customers.push({
				id:item.customerId,
				name:`${item.customerData.name}`
			});
			totalColumn += 2;
		}
	})
	let tableData = [];
	let totalDeliveredParcelCount = 0;
	let totalPickupParcelCount = 0;
	apiData.map(item => {
		let driverId = (item.driverData.Driver_Id) ? item.driverData.Driver_Id :item.driverId
		let index = tableData.findIndex( x => x.driverId === driverId );
		if(index === -1){
			tableData.push({
				id:item.customerId,
				name:`${item.customerData.name}`,
				driverId: driverId,
				driverName: item.driverData.name,
				data: [
					{
						deliveredParcelCount: item.deliveredParcelCount ? item.deliveredParcelCount:0,
						pickupParcelCount: item.pickupParcelCount ? item.pickupParcelCount:0
					}
				],
				totalDeliveredParcelCount:item.deliveredParcelCount ? item.deliveredParcelCount:0,
				totalPickupParcelCount:item.pickupParcelCount ? item.pickupParcelCount:0,
			});
		} else {
			tableData[index].data.push({
				deliveredParcelCount: item.deliveredParcelCount ? item.deliveredParcelCount:0,
				pickupParcelCount: item.pickupParcelCount ? item.pickupParcelCount:0
			});
			tableData[index].totalDeliveredParcelCount += item.deliveredParcelCount ? item.deliveredParcelCount:0;
			tableData[index].totalPickupParcelCount += item.pickupParcelCount ? item.pickupParcelCount:0;
		}
		totalDeliveredParcelCount += item.deliveredParcelCount ? item.deliveredParcelCount:0;
		totalPickupParcelCount += item.pickupParcelCount ? item.pickupParcelCount:0;
	})
	tableData.map((item, index) => {
		if(item.data.length != customers.length){
			tableData[index].data.push({
				deliveredParcelCount: 0,
				pickupParcelCount: 0
			});
		}
	})
	return (
		<>
			<ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success" table="getDetails" filename="dailyWorkReport" sheet="tablexls" buttonText="Export to XLS"/>
			<table id="getDetails" style={{display:'none'}}>
				<thead>
					<tr>
						<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Date</th>
						<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>{startDate}</th>
						<th></th>
						<th></th>
					</tr>
					<tr></tr>
					<tr style={{'font-weight': 'bold'}}>
						<th style={{border:'1px solid black'}}></th>
						<th style={{border:'1px solid black'}}></th>
						{
							customers.map((item)=>(
								<th colSpan={2} style={{border:'1px solid black', fontSize: '20px'}}>{item.name}</th>
							))
						}
						<th colSpan={2} style={{border:'1px solid black', fontSize: '20px'}}>Total</th>
					</tr>
					<tr style={{height: '80px', padding: '45px'}}>
						<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Driver Id</th>
						<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Driver Name</th>
						{
							customers.map((item)=>(
								<>
									<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Pickup</th>
									<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Done (Successful)</th>
								</>
							))
							
						}
						<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Pickup</th>
						<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Done (Successful)</th>
					</tr>
				</thead>
				<tbody>
					{
						tableData.map((item)=>(
							<tr style={{textAlign: 'center'}}>
								<td>EV{item.driverId}</td>
								<td>{item.driverName}</td>
								{
									item.data.map((item1)=>(
										<>
											<td>{item1.pickupParcelCount}</td>
											<td>{item1.deliveredParcelCount}</td>
										</>
									))
								}
								<td>{item.totalPickupParcelCount}</td>
								<td>{item.totalDeliveredParcelCount}</td>
							</tr>
						))
					}
					<tr style={{'font-weight': 'bold'}}>
						<td colSpan={totalColumn} style={{border:'1px solid black', textAlign: 'center'}}>Total</td>
						<td style={{border:'1px solid black', 'text-align': 'center'}}>{totalPickupParcelCount}</td>
						<td style={{border:'1px solid black', 'text-align': 'center'}}>{totalDeliveredParcelCount}</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};