import React, { useEffect, useRef, useState} from 'react'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'date-fns'
import Footer from '../../DashBoard/Footer';
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import AssignedVehicleList from './AssignedVehicleList';
import WorkingVehicleList from './WorkingVehicleList';
import RepairedVehicleList from './RepairedVehicleList';
import Loader from '../../common/Loader/Loader';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/TechnicianVehiclesAdmin/vehicle_action';
import $ from 'jquery'
import { renderToString } from 'react-dom/server'
function Vehicle_List() {
    $.DataTable = require('datatables.net')
    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.HubVehicleList)
    const tableRef = useRef();
    const [reloadAssigned, setReloadAssigned]=useState(false);
    const [reloadWorking, setReloadWorking]=useState(false);
    const [reloadRepaired, setReloadRepaired]=useState(false);
    const clickOnAssign = () => {
        setReloadAssigned(true);
        setReloadWorking(false);
        setReloadRepaired(false);
    }
    const clickOnWorking = () => {
        setReloadAssigned(false);
        setReloadWorking(true);
        setReloadRepaired(false);
    }
    const clickOnRepaired = () => {
        setReloadAssigned(false);
        setReloadWorking(false);
        setReloadRepaired(true);
    }
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover  theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                <div className="layout-px-spacing">

                    <div className="row layout-top-spacing">

                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                            <div className="widget-content widget-content-area br-6">
                                <ul class="nav nav-tabs  mb-3 mt-3" id="simpletab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="view-tab" data-toggle="tab" href="#view"
                                            role="tab" aria-controls="view" aria-selected="true" onClick={ ()=>clickOnAssign()}>Assigned Vehicle List</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="tracking-tab" data-toggle="tab" href="#tracking"
                                            role="tab" aria-controls="tracking" aria-selected="false" onClick={ ()=>clickOnWorking()}>Working On Vehicle List</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="workHistory-tab" data-toggle="tab"
                                            href="#workHistory" role="tab" aria-controls="workHistory"
                                            aria-selected="false" onClick={ ()=>clickOnRepaired()}>Repaired Vehicle List</a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="simpletabContent">
                                    <div class="tab-pane fade show active" id="view" role="tabpanel"
                                        aria-labelledby="view-tab">
                                        <AssignedVehicleList reload={reloadAssigned}/>
                                    </div>
                                    <div class="tab-pane fade show" id="tracking" role="tabpanel"
                                        aria-labelledby="tracking-tab">
                                        <WorkingVehicleList reload={reloadWorking}/>
                                    </div>
                                    <div class="tab-pane fade show" id="workHistory" role="tabpanel"
                                        aria-labelledby="workHistory-tab">
                                        <RepairedVehicleList reload={reloadRepaired}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
            </div>
        </>
    )
}
export default Vehicle_List