import React, { useEffect, useRef, useState} from 'react'
import { useNavigate, useParams }from 'react-router-dom'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Footer from '../../DashBoard/Footer';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select'
import AsyncSelect from "react-select/async";
import { DatePicker } from 'rsuite';
import { format } from 'date-fns'
import Loader from '../../common/Loader/Loader';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/Driver/driver_action';
import $ from 'jquery'
import {baseURL} from '../../../service/baseService';
const View_Driver_Work_history = props => {
    $.DataTable = require('datatables.net')
    var moment = require('moment');
    const dispatch = useDispatch();
    const [modalImgURL, setModalImgURL]=useState("");
    const {driver_id} = useParams();
    const {loading} = useSelector(state => state.AllDriverWorkListReduces)
    const [undeliveredReasoneList,setUndeliveredReasoneList]=useState([]);
    const [defaultOptions,setDefaultOptions]=useState([]);
    const [disabled, setDisabled]=useState(true);
    const [editButtonHide, setEditButtonHide]=useState(false);
    const [cancelButtonHide, setCancelButtonHide]=useState(true);
    const [submitButtonHide, setSubmitButtonHide]=useState(true);
    const [driverWorkDetails,setDriverWorkDetails]=useState({
        driver_id: '',
        driver_name: '',
        vehicle_id: '',
        vehicle_name: '',
        customer_id: '',
        customer_name: '',
        customer_address_id: '',
        customer_address: '',
        customer_work_start_date: '',
        customer_work_end_date: '',
        pickupParcelCount:'',
        deliveredParcelCount:'',
        undeliveredOnDoorParcelCount:'',
        undeliveredParcelCount:'',
        undeliveredReason:'',
        redeliveredParcelCount:'',
        returnParcelCount:'',
        parcelImageUrls:[],
        overtime:'',
        customerRate:'',
        driverRate:'',
    });
    const setEditFormEditable = (type) => {
        if(type){
            setDisabled(false);
            setCancelButtonHide(false);
            setSubmitButtonHide(false);
            setEditButtonHide(true);
        } else {
            setDisabled(true);
            setCancelButtonHide(true);
            setSubmitButtonHide(true);
            setEditButtonHide(false);
        }
    }
    const uploadMultipleFile = (e) => {
        let file = e.target.files;
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
            const onefile = file[i];
            formData.append('images', onefile, `driverWorks-${onefile.name}`);    
        }
        dispatch(action.driver_multiple_file_upload(formData)).then((data)=>{
            let newParcelImageUrls = driverWorkDetails.parcelImageUrls;
            data.data.map(item => {
                newParcelImageUrls.push(item.url);
            })
            setDriverWorkDetails({...driverWorkDetails, parcelImageUrls:newParcelImageUrls})
        }).catch({})
    }
    useEffect(() => {
        dispatch(action.undelivered_reason_list()).then((data)=>{
            let newUndeliveredReasoneList = [];
            data.data.map(item => {
                newUndeliveredReasoneList.push({
                    value: item._id,
                    label: item.reason
                });
            })
            setUndeliveredReasoneList(newUndeliveredReasoneList)
        }).catch({})
    },[])
    useEffect(() => {
        dispatch(action.driver_work_details(driver_id)).then((data)=>{
            let newDefaultOptions = [];
            const reason_array = data.data.undeliveredReason.split(',');
            undeliveredReasoneList.map(item => {
                if(reason_array.find(o => o === item.value)){
                    newDefaultOptions.push({
                        value: item._id,
                        label: item.reason
                    });
                }
            })
            setDefaultOptions(newDefaultOptions)
            setDriverWorkDetails({
                driver_id: data.data.driverData._id,
                driver_name: data.data.driverData.name,
                vehicle_id: data.data.vehicleData._id,
                vehicle_name: data.data.vehicleData.number+' | '+data.data.vehicleData.type+' Wheeler',
                customer_id: data.data.customerData._id,
                customer_name: `${data.data.customerData.businessName} | ${data.data.customerData.name} | ${data.data.customerData.mobile}`,
                customer_address_id: data.data.customerAddressData._id,
                customer_address: data.data.customerAddressData.address,
                customer_work_start_date: data.data.startDate ? changeDateFormate(data.data.startDate) :'',
                customer_work_end_date: data.data.endDate ? changeDateFormate(data.data.endDate) :'',
                pickupParcelCount:data.data.pickupParcelCount,
                deliveredParcelCount:data.data.deliveredParcelCount,
                undeliveredOnDoorParcelCount:data.data.undeliveredOnDoorParcelCount,
                undeliveredParcelCount:data.data.undeliveredParcelCount,
                undeliveredReason:data.data.undeliveredReason,
                redeliveredParcelCount:data.data.redeliveredParcelCount,
                returnParcelCount:data.data.returnParcelCount,
                parcelImageUrls:data.data.parcelImages,
                overtime:data.data.overtime,
                customerRate:data.data.customerRate,
                driverRate:data.data.driverRate
            })
        }).catch({})
    },[])
    const [inputValue, setInputValue] = useState("");
    const filterColors = (inputValue) => {
        return undeliveredReasoneList.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };
    
    const promiseOptions = (inputValue, callback) => {
        setTimeout(() => {
          callback(filterColors(inputValue));
        }, 1000);
    };
    
    const handleChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, "");
        setInputValue(inputValue);
        return inputValue;
    };
    const changeDateFormate = (date) => {
        if (date) {
            let formate = moment(date);
            return new Date(formate);
        }
    }
    const changeSelectedReasonValue = (reasons) => {
        let newReasone = "";
        let i = 0;
        reasons.map(item => {
            if(i === 0){
                newReasone += item.value;
            } else {
                newReasone += ","+item.value;
            }
            i++;
        })
        setDriverWorkDetails({...driverWorkDetails ,  undeliveredReason:newReasone});
    }
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing">

                        <div className="row layout-top-spacing">
                            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                <div className="widget-content widget-content-area br-6">
                                    <div className="input-group edit-btn-group">
                                        <h5 className="">Driver Work</h5>
                                        <span id="editidspan" className="btn btn-warning btn-sm float-right ms-3 mb-1" style={{cursor: 'pointer'}} onClick={ ()=>setEditFormEditable(true)} hidden={editButtonHide}> Edit </span>
                                    </div>
                                    <br/>

                                    <form>
                                        <div className="form-group">
                                            <div id="append-div-id">
                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputDriver">Driver *</label>
                                                    </div>
                                                    <div className="form-group col-md-9">
                                                        <input type="hidden" name="driver_id" id="inputDriverId" className="form-control" value={driverWorkDetails.driver_id} />
                                                        <input disabled type="text" name="driver_name" id="inputDriver" className="form-control" value={driverWorkDetails.driver_name}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputVehicle">Vehicle *</label>
                                                    </div>
                                                    <div className="form-group col-md-9">
                                                        <input type="hidden" name="vehicle_id" id="inputVehicleId" className="form-control" value={driverWorkDetails.vehicle_id}/>
                                                        <input disabled type="text" name="inputVehicle" id="inputVehicle" className="form-control" value={driverWorkDetails.vehicle_name}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputCustomer">Customer/Company *</label>
                                                    </div>
                                                    <div className="form-group col-md-9">
                                                        <input type="hidden" name="Customer_id" id="inputCustomerId" className="form-control" value={driverWorkDetails.customer_id}/>
                                                        <input disabled type="text" name="inputCustomer" id="inputCustomer" className="form-control" value={driverWorkDetails.customer_name}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputCustomerAddress">Customer/Company Address *</label>
                                                    </div>
                                                    <div className="form-group col-md-9">
                                                        <input type="hidden" name="CustomerAddress_id" id="inputCustomerAddressId" className="form-control" value={driverWorkDetails.customer_address_id}/>
                                                        <input disabled type="text" name="inputCustomerAddress" id="inputCustomerAddress" className="form-control" value={driverWorkDetails.customer_address}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputStartDate">Work Start Date *</label>
                                                    </div>
                                                    <div className="form-group col-md-3 ">
                                                        <DatePicker disabled format="yyyy-MM-dd HH:mm:ss" value={driverWorkDetails.customer_work_start_date} onChange={(e)=>setDriverWorkDetails({...driverWorkDetails ,  customer_work_start_date:e})}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputEndDate">Work End Date *</label>
                                                    </div>
                                                    <div className="form-group col-md-3 ">
                                                        <DatePicker disabled format="yyyy-MM-dd HH:mm:ss" value={driverWorkDetails.customer_work_end_date} onChange={(e)=>setDriverWorkDetails({...driverWorkDetails ,  customer_work_end_date:e})}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputPickupParcelCount">Total No Of Parcel *</label>
                                                    </div>
                                                    <div className="form-group col-md-3 ">
                                                        <input disabled={disabled} type="number" className="form-control" id="inputPickupParcelCount" placeholder="Enter pickup parcel count" min="0" value={driverWorkDetails.pickupParcelCount} required onChange={(e)=>setDriverWorkDetails({...driverWorkDetails ,  pickupParcelCount:e.target.value})}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputDeliveredParcelCount">Successful Parcel Count *</label>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <input disabled={disabled} type="number" className="form-control" id="inputDeliveredParcelCount" placeholder="Enter delivered parcel count" min="0" required value={driverWorkDetails.deliveredParcelCount} onChange={(e)=>setDriverWorkDetails({...driverWorkDetails ,  deliveredParcelCount:e.target.value})}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputUndeliveredOnDoorParcelCount">Cancel On Door *</label>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <input disabled={disabled} type="number" className="form-control" id="inputUndeliveredOnDoorParcelCount" value={driverWorkDetails.undeliveredOnDoorParcelCount} onChange={(e)=>setDriverWorkDetails({...driverWorkDetails ,  undeliveredOnDoorParcelCount:e.target.value})} placeholder="Enter cancle parcel count" min="0" required />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputUndeliveredParcelCount">Cancel On Way *</label>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <input disabled={disabled} type="number" className="form-control" id="inputUndeliveredParcelCount" placeholder="Enter cancle parcel count" min="0" required value={driverWorkDetails.undeliveredParcelCount} onChange={(e)=>setDriverWorkDetails({...driverWorkDetails ,  undeliveredParcelCount:e.target.value})}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputUndeliveredReason">Unsuccessful Reason</label>
                                                    </div>
                                                    <div className="form-group col-md-5">
                                                        <AsyncSelect disabled={disabled} cacheOptions defaultOptions={defaultOptions} loadOptions={promiseOptions} onInputChange={handleChange} isMulti onChange={changeSelectedReasonValue}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputReturnParcelCount">Return Parcel Count *</label>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <input disabled={disabled} type="number" className="form-control" id="inputReturnParcelCount" placeholder="Enter return parcel count" min="0" value={driverWorkDetails.returnParcelCount} required onChange={(e)=>setDriverWorkDetails({...driverWorkDetails ,  returnParcelCount:e.target.value})}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputRedeliveredParcelCount">Redelivered Parcel Count *</label>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <input disabled={disabled} type="number" className="form-control" id="inputRedeliveredParcelCount" placeholder="Enter redelivered parcel count" min="0" value={driverWorkDetails.redeliveredParcelCount} required onChange={(e)=>setDriverWorkDetails({...driverWorkDetails ,  redeliveredParcelCount:e.target.value})}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="parcelImages">Images</label>
                                                    </div>
                                                    <div className="form-group col-md-5">
                                                        <input disabled={disabled} accept="image/jpg,image/jpeg,image/png,image/bmp" multiple type="file" name="parcelImages[]" className="form-control p-2" id="parcelImages" onChange={(e)=>uploadMultipleFile(e)}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                    </div>
                                                    <div className="form-group" id="append-show-img-div-id">
                                                    {
                                                        driverWorkDetails.parcelImageUrls.map((images)=>(
                                                            <>
                                                                <img src={`${baseURL}${images}`} alt="no-image" id="panImgId" className="form-group p-2 img-responsive imgFile" onClick={ ()=>setModalImgURL(images)} data-toggle="modal" data-target="#showImageModalCenter" style={{width: "70px", height: "70px"}} />
                                                            </>
                                                        ))
                                                    }
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="form-group col-md-3">
                                                        <label for="inputOvertime">Add Overtime</label>
                                                    </div>
                                                    <div className="form-group row col-md-9 ml-1">
                                                        <input disabled={disabled} id="inputOvertime" className="form-control flatpickr flatpickr-input active col-md-3" type="time" placeholder="Select Time.." value={driverWorkDetails.overtime} onChange={(e)=>setDriverWorkDetails({...driverWorkDetails ,  overtime:e.target.value})}/>
                                                        <div className="form-group row ml-1">
                                                            <label for="inputCustomerRate" className="ml-3">Customer Rate</label>
                                                            <input disabled={disabled} type="number" name="inputCustomerRate" className="form-control allowdecimal col-md-3 ml-2" id="inputCustomerRate" placeholder="Customer Rate" required value={driverWorkDetails.customerRate} onChange={(e)=>setDriverWorkDetails({...driverWorkDetails ,  customerRate:e.target.value})}/>
                                                            <label for="inputDriverRate" className="ml-3">Driver Rate</label>
                                                            <input disabled={disabled} type="number" name="inputDriverRate" className="form-control allowdecimal col-md-3 ml-2" id="inputDriverRate" placeholder="Driver Rate" required value={driverWorkDetails.driverRate} onChange={(e)=>setDriverWorkDetails({...driverWorkDetails ,  driverRate:e.target.value})}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal fade" id="showImageModalCenter" tabindex="-1" role="dialog"
                                                    aria-labelledby="showImageModalCenterLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered modal-lg" role="img">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            {
                                                                modalImgURL !== '' ? <img src={`${baseURL}${modalImgURL}`} alt="no-image" id="modalImgId" className="img-responsive" style={{width: "100%", height: "100%"}} />: ''
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group" id="saveAndCancleButtons">
                                                <button hidden={cancelButtonHide} onClick={ ()=>setEditFormEditable(false)} type="reset" className="btn btn-danger mt-3">Cancel</button>&nbsp;
                                                <button hidden={submitButtonHide} type="submit" className="btn btn-success mt-3 view-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}
export default View_Driver_Work_history