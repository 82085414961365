import {DRIVER_DETACHED_VEHICLE_SUCCESSFULL, DRIVER_DETACHED_VEHICLE_ERROR,DRIVER_DETACHED_VEHICLE_LOADING} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case DRIVER_DETACHED_VEHICLE_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case DRIVER_DETACHED_VEHICLE_SUCCESSFULL:{
            return Object.assign({},state,{addDriverData: action.data, loading: false})
        }
        case DRIVER_DETACHED_VEHICLE_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}