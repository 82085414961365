import React from 'react'
import { format } from 'date-fns'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
export const ExportToExcelWithParcel = props => {
    const startDate = props.startDate;
    const endDate = props.endDate;
    const dates = props.dates;
    const customerList = props.customerList;
    const totalList = props.totalList;
	const changeDateFormate = (date) => {
		if (date) {
			let new_date = String(date);
			let ndate = new_date.split('T');
			let format_date = format(new Date(ndate[0]), 'dd MMM');
			return format_date;
		}
	}
    const changeDate = (date) => {
		if (date) {
			let cd = new Date(date);
            let format_date = format(new Date(cd), 'MMM yyyy');
            return format_date;
		}
	}
    const changeFullDate = (date) => {
		if (date) {
			let cd = new Date(date);
            let format_date = format(new Date(cd), 'dd MMM yyyy');
            return format_date;
		}
	}
    let totalDates = (dates.length * 4) + 1;
    return (
      <>
	  	<ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success" table="customerMonthlyReport" filename="customerMonthlyReport" sheet="tablexls" buttonText="Export to XLS"/>
          <table id="customerMonthlyReport" style={{display:'none', textAlign:"center"}}>
			<thead>
                <tr style={{paddingLeft: '15px', paddingRight: '15px'}}>
					<th colSpan={totalDates} style={{border:'1px solid black', textAlign:'center'}}>Based On Date Selection</th>
				</tr>
                <tr style={{paddingLeft: '15px', paddingRight: '15px'}}>
					<th rowSpan={2} style={{border:'1px solid black', textAlign:'center'}}>{changeDate(dates[0].date)}</th>
                    {
                        dates.map((date)=>(
                            <th colSpan={4} style={{border:'1px solid black', textAlign:'center', backgroundColor: '#fbeb9b'}}>{changeFullDate(date.date)}</th>
                        ))
                    }
				</tr>
                <tr style={{height: '50px', paddingLeft: '15px', paddingRight: '15px'}}>
                    {
                        dates.map((date)=>(
                            <>
                                <th style={{border:'1px solid black', textAlign:'center', backgroundColor:'#f7cad1'}}>Total Rider</th>
                                <th style={{border:'1px solid black', textAlign:'center', backgroundColor:'#f7cad1'}}>Pickup Order</th>
                                <th style={{border:'1px solid black', textAlign:'center', backgroundColor:'#f7cad1'}}>Done Order</th>
                                <th style={{border:'1px solid black', textAlign:'center', backgroundColor:'#f7cad1'}}>Eff%</th>
                            </>
                        ))
                    }
                </tr>
			</thead>
			<tbody>
                {
                    customerList.map((customer)=>(
                        <tr style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <td style={{border:'1px solid black', textAlign:'center', backgroundColor:'#f7cad1'}}>{customer.name}</td>
                            {
                                customer.data.map((data)=>(
                                    <>
                                        <td style={{border:'1px solid black', textAlign:'center'}}>{data.driverCount}</td>
                                        <td style={{border:'1px solid black', textAlign:'center'}}>{data.pickupOrder}</td>
                                        <td style={{border:'1px solid black', textAlign:'center'}}>{data.deliverOrder}</td>
                                        <td style={{border:'1px solid black', textAlign:'center', backgroundColor:'#b2d9e8'}}>{parseFloat(data.eff.toFixed(2))}</td>
                                    </>
                                ))
                            }
                        </tr>
                    ))
                }
                <tr>
                    <td colSpan={totalDates}></td>
                </tr>
                {
                    <tr style={{paddingLeft: '15px', paddingRight: '15px'}}>
                        <td style={{border:'1px solid black', textAlign:'center', backgroundColor:'#4f9dd5'}}>Total</td>
                        {
                            totalList.map((data)=>(
                                <>
                                    <td style={{border:'1px solid black', textAlign:'center'}}>{data.driverCount}</td>
                                    <td style={{border:'1px solid black', textAlign:'center'}}>{data.pickupOrder}</td>
                                    <td style={{border:'1px solid black', textAlign:'center'}}>{data.deliverOrder}</td>
                                    <td style={{border:'1px solid black', textAlign:'center', backgroundColor: '#b2d9e8'}}>{parseFloat(data.eff.toFixed(2))}</td>
                                </>
                            ))
                        }
                    </tr>
                }
			</tbody>
		</table>
	  </>
    );
};