import {NOTIFICATION_LOADING,NOTIFICATION_SUCCESSFULL,NOTIFICATION_ERROR} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case NOTIFICATION_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case NOTIFICATION_SUCCESSFULL:{
            return Object.assign({},state,{notificationData: action.data, loading: false})
        }
        case NOTIFICATION_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}