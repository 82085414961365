import {
    DASHBOARD_VEHICLE_DETAILS_LOADING, 
    DASHBOARD_VEHICLE_DETAILS_SUCCESSFULL,
    DASHBOARD_VEHICLE_DETAILS_ERROR,
    DASHBOARD_DRIVER_DETAILS_LOADING,
    DASHBOARD_DRIVER_DETAILS_SUCCESSFULL,
    DASHBOARD_DRIVER_DETAILS_ERROR,
    DASHBOARD_CUSTOMER_DETAILS_DAILY_LOADING,
    DASHBOARD_CUSTOMER_DETAILS_DAILY_SUCCESSFULL,
    DASHBOARD_CUSTOMER_DETAILS_DAILY_ERROR
} from '../../action/type';

import * as AuthService from '../../service/services'

export const dashboard_vehicles_details=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: DASHBOARD_VEHICLE_DETAILS_LOADING,
                data: true
            })
            AuthService.DashboardVehicleDetails()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:DASHBOARD_VEHICLE_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:DASHBOARD_VEHICLE_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const dashboard_driver_details=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: DASHBOARD_DRIVER_DETAILS_LOADING,
                data: true
            })
            AuthService.DashboardDriverDetails()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:DASHBOARD_DRIVER_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:DASHBOARD_DRIVER_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const dashboard_customer_details_daily=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: DASHBOARD_CUSTOMER_DETAILS_DAILY_LOADING,
                data: true
            })
            AuthService.DashboardCustomerDetailsDaily()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:DASHBOARD_CUSTOMER_DETAILS_DAILY_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:DASHBOARD_CUSTOMER_DETAILS_DAILY_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}