import {WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_LOADING, WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_SUCCESSFULL,WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_ERROR} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_SUCCESSFULL:{
            return Object.assign({},state,{watchmanVehicleStatusChangeParkedData: action.data, loading: false})
        }
        case WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}