import {
    ADMIN_LIST_SUCCESSFULL,
    ADMIN_LIST_ERROR, 
    ADMIN_LIST_LOADING,
    ADMIN_ADD_SUCCESSFULL,
    ADMIN_ADD_ERROR,
    ADMIN_ADD_LOADING,
    ADMIN_DETAILS_SUCCESSFULL,
    ADMIN_DETAILS_ERROR,
    ADMIN_DETAILS_LOADING,
    ADMIN_EDIT_SUCCESSFULL,
    ADMIN_EDIT_ERROR,
    ADMIN_EDIT_LOADING,
    VIHICLE_HUB_LIST_SELECT_SUCCESSFULL,
    VIHICLE_HUB_LIST_SELECT_ERROR,
    VIHICLE_HUB_LIST_SELECT_LOADING,
    GET_ADMIN_PROFILE_SUCCESSFULL,
    GET_ADMIN_PROFILE_ERROR,
    GET_ADMIN_PROFILE_LOADING,
    UPDATE_ADMIN_PROFILE_SUCCESSFULL,
    UPDATE_ADMIN_PROFILE_LOADING,
    UPDATE_ADMIN_PROFILE_ERROR
} from '../../action/type';

import * as AuthService from '../../service/services'

export const admin_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: ADMIN_LIST_LOADING,
                data: true
            })
            AuthService.AdminList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:ADMIN_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:ADMIN_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const add_admin=(credentail)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: ADMIN_ADD_LOADING,
                data: true
            })
            AuthService.AddAdmin(credentail)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:ADMIN_ADD_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:ADMIN_ADD_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const admin_details=(admin_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: ADMIN_DETAILS_LOADING,
                data: true
            })
            AuthService.AdminDetails(admin_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:ADMIN_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:ADMIN_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const admin_profile_details=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: GET_ADMIN_PROFILE_LOADING,
                data: true
            })
            AuthService.AdminProfileDetails()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:GET_ADMIN_PROFILE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:GET_ADMIN_PROFILE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const admin_profile_update=(credentail)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: UPDATE_ADMIN_PROFILE_LOADING,
                data: true
            })
            AuthService.AdminProfileUpdate(credentail)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:UPDATE_ADMIN_PROFILE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:UPDATE_ADMIN_PROFILE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const edit_admin=(credentail)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: ADMIN_EDIT_LOADING,
                data: true
            })
            AuthService.EditAdmin(credentail)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:ADMIN_EDIT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:ADMIN_EDIT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_hub_list_select=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_HUB_LIST_SELECT_LOADING,
                data: true
            })
            AuthService.VehicleHubsListSelect()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_HUB_LIST_SELECT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_HUB_LIST_SELECT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}