import React from 'react';
import { useLocation } from "react-router-dom";

function SideBar() {
    const role = localStorage.getItem('role');
    console.log(role);
    const location = useLocation();
    let sidebarMenu = [];
    if(role === 'H'){
        sidebarMenu = [
            {
                id: 'dashboard',
                name: 'Dashboard',
                link: '/adminside/hub/dashboard',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>,
                child: []
            },
            {
                id: 'vehicle',
                name: 'Vehicle',
                link: '#',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg>,
                child: [
                    {
                        name: 'Vehicle List',
                        link: '/adminside/hub/vehicle'
                    }
                ]
            },
        ]
    } else if(role === 'T') {
        sidebarMenu = [
            {
                id: 'dashboard',
                name: 'Dashboard',
                link: '/adminside/technician/dashboard',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>,
                child: []
            },
            {
                id: 'vehicle',
                name: 'Vehicle',
                link: '#',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg>,
                child: [
                    {
                        name: 'Vehicle List',
                        link: '/adminside/technician/vehicle'
                    }
                ]
            },
        ]
    } else if(role === 'W') {
        sidebarMenu = [
            {
                id: 'dashboard',
                name: 'Dashboard',
                link: '/adminside/watchman/dashboard',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>,
                child: []
            }
        ]
    } else if(role === 'O') {
        sidebarMenu = [
            {
                id: 'dashboard',
                name: 'Dashboard',
                link: '/adminside/owners/dashboard',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>,
                child: []
            }
        ]
    } else {
        sidebarMenu = [
            {
                id: 'dashboard',
                name: 'Dashboard',
                link: '/adminside/dashboard',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>,
                child: []
            },
            {
                id: 'customer',
                name: 'Customer',
                link: '#',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>,
                child: [
                    {
                        name: 'Customer List',
                        link: '/adminside/customer'
                    },
                    {
                        name: 'Add Customer',
                        link: '/adminside/customer/add'
                    }
                ]
            },
            {
                id: 'vehicle',
                name: 'Vehicle',
                link: '#',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg>,
                child: [
                    {
                        name: 'Vehicle List',
                        link: '/adminside/vehicle'
                    },
                    {
                        name: 'Add Vehicle',
                        link: '/adminside/vehicle/add'
                    }
                ]
            },
            {
                id: 'driver',
                name: 'Driver',
                link: '#',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>,
                child: [
                    {
                        name: 'Driver List ',
                        link: '/adminside/driver'
                    },
                    {
                        name: 'Add Driver',
                        link: '/adminside/driver/add'
                    },
                    {
                        name: 'To Be Verified',
                        link: '/adminside/driver/toBeVerify'
                    },
                    {
                        name: 'Daily Works',
                        link: '/adminside/driver/dailyWorks'
                    },
                    {
                        name: 'Login Request',
                        link: '/adminside/driver/loginRequest'
                    },
                    {
                        name: 'Issues',
                        link: '/adminside/driver/issueList'
                    }
                ]
            },
            {
                id: 'admin',
                name: 'Admin',
                link: '#',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>,
                child: [
                    {
                        name: ' Admin List',
                        link: '/adminside/admin'
                    },
                    {
                        name: 'Add Admin',
                        link: '/adminside/admin/add'
                    }
                ]
            },
            // {
            //     id: 'account',
            //     name: 'Accounts',
            //     link: '#',
            //     Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>,
            //     child: [
            //         {
            //             name: 'Transaction List',
            //             link: '#'
            //         },
            //         {
            //             name: 'Add Transaction',
            //             link: '#'
            //         },
            //         {
            //             name: 'Bank Accounts',
            //             link: '#'
            //         },
            //         {
            //             name: 'Category',
            //             link: '#'
            //         }
            //     ]

            // },
            // {
            //     id: 'customeFields',
            //     name: 'Custom Fields',
            //     link: '#',
            //     Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>,
            //     child: [
            //         {
            //             name: 'Undelivered Reason',
            //             link: '#'
            //         }
            //     ]
            // },
            {
                id: 'vehicleLeaseCompany',
                name: 'Lease Company',
                link: '#',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>,
                child: [
                    {
                        name: 'Lease Company List',
                        link: '/adminside/vehicleLeaseCompany'
                    },
                    {
                        name: 'Add Lease Company',
                        link: '/adminside/vehicleLeaseCompany/add'
                    }
                ]
            },
            // {
            //     id: 'vehicleTelematicsUnit',
            //     name: 'Telematics Unit',
            //     link: '#',
            //     Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>,
            //     child: [
            //         {
            //             name: 'Telematics Unit List',
            //             link: '/adminside/vehicleTelematicsUnit'
            //         }
            //     ]
            // },
            {
                id: 'vehicleHub',
                name: 'Vehicle Hub',
                link: '#',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>,
                child: [
                    {
                        name: 'Vehicle Hub List',
                        link: '/adminside/vehicleHub'
                    }
                ]
            },
            {
                id: 'bikeType',
                name: 'Bike Type',
                link: '#',
                Icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>,
                child: [
                    {
                        name: 'Bike Type',
                        link: '/adminside/bikeType'
                    }
                ]
            },
        ]
    }

    return (
        <div className="sidebar-wrapper sidebar-theme">
            <nav id="sidebar">
                <div className="shadow-bottom"></div>
                <ul className="list-unstyled menu-categories" id="accordionExample">
                    {
                        sidebarMenu.map((item) =>
                            <li className="menu" key={`list${item.id}`}>
                                <a href={`${item.link !== '#' ? item.link : `#${item.id}`}`} data-toggle={item.link === '#' ? 'collapse' : ""} aria-expanded={(item.link !== '#' && location.pathname === item.link) || item.child.filter(val => val.link === location.pathname).length ? "true" : "false"} data-active={(item.link !== '#' && location.pathname === item.link) || item.child.filter(val => val.link === location.pathname).length ? "true" : "false"} className="dropdown-toggle sidebarmenu1">
                                    <div>
                                        {item.Icon}
                                        <span>{item.name}</span>
                                    </div>
                                    {
                                        item.child.length ?
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                                            </div>
                                            : null
                                    }
                                </a>
                                <ul className={`collapse submenu list-unstyled ${item.child.filter(val => val.link === location.pathname).length ? 'show':''}`} id={item.id} data-parent="#accordionExample">
                                    {
                                        item.child.map((childItem, index) => (
                                            <li key={`sublist-${index}`} className={`sidebarmenu3 ${childItem.link === location.pathname ? 'active' : ''}`}>
                                                <a href={childItem.link}> {childItem.name} </a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </li>
                        )
                    }
                </ul>
            </nav>
        </div>
    )
}

export default React.memo(SideBar);