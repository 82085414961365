import React, { useEffect, useRef, useState} from 'react'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { format } from 'date-fns'
import Footer from '../../DashBoard/Footer';
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Loader from '../../common/Loader/Loader';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/BikeType/bike_type_action';
import $ from 'jquery'
import { renderToString } from 'react-dom/server'
function Vehicle_Telematics_Unit() {
    $.DataTable = require('datatables.net')
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const {loading} = useSelector(state => state.BikeTypeListReducer)
    const {Addloading} = useSelector(state => state.AddBikeTypeReducer)
    const {Editloading} = useSelector(state => state.EditBikeTypeDetailsReducer)
    const {Deleteloading} = useSelector(state => state.BikeTypeDeleteReducer)
    const {Statusloading} = useSelector(state => state.BikeTypeStatusChangeReducer)
    const [disabled, setDisabled]=useState(true);
    const [editButtonHide, setEditButtonHide]=useState(false);
    const [submitButtonHide, setSubmitButtonHide]=useState(true);
    const [bikeTypeList,setBikeTypeListList]=useState([]);
    const [addBikeType, setAddBikeType]= useState({
        bikeName: "",
        noOfBattery: 1,
        registered: 1,
        isActive: 1,
    });
    const [editBikeType, setEditBikeType]= useState({
        id:"",
        bikeName: "",
        noOfBattery: 1,
        registered: 1,
        isActive: 1,
    });
    const [errors,setErrors] = useState({});
    const bikeFocus = useRef();
    const bikeEditFocus = useRef();
    const setEditFormEditable = (type) => {
        if(type){
            setDisabled(false);
            setSubmitButtonHide(false);
            setEditButtonHide(true);
        } else {
            setDisabled(true);
            setSubmitButtonHide(true);
            setEditButtonHide(false);
        }
    }
    const setUpdateBikeType = (bikeType) => {
        let new_bike_type = bikeType[6];
        setEditBikeType({
            id:new_bike_type._id,
            bikeName: new_bike_type.bikeName,
            noOfBattery: parseInt(new_bike_type.noOfBattery),
            registered: new_bike_type.registered,
            isActive: new_bike_type.isActive,
        });
    }
    const deleteBikeType = (bikeType) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this bike type",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let param = {id: bikeType[4], isActive: 2};
                dispatch(action.delete_bike_type(param)).then((data)=>{
                    toast.success("Bike type deleted successfully");
                    let newBikeTypeList = [];
                    bikeTypeList.map(item => {
                        if(item[4] !== bikeType[4]){
                            newBikeTypeList.push(item);
                        }
                    })
                    setBikeTypeListList(newBikeTypeList)
                }).catch(error=>{
                    toast.error(error);
                })
            }
        })
    }
    const ClearModelData = () => {
        setAddBikeType({
            bikeName:"",
            noOfBattery:1,
            registered:1,
            isActive:1,
        });
    }
    const activeDeactiveAlertPopup = (bikeType) => {
        dispatch(action.bike_type_status_change(bikeType[4])).then((data)=>{
            toast.success(data.msg);
            let newBikeTypeList = [];
            bikeTypeList.map(item => {
                if(item[4] === bikeType[4]){
                    let telematics_data = data.data;
                    newBikeTypeList.push([telematics_data.bikeName, telematics_data.noOfBattery, telematics_data.registered, telematics_data.isActive, telematics_data._id, telematics_data._id, telematics_data]);
                } else {
                    newBikeTypeList.push(item);
                }
            })
            setBikeTypeListList(newBikeTypeList)
        }).catch(error=>{
            toast.error(error);
        })
    }
    const tableName = "bike-type-list";
    useEffect(() => {
        dispatch(action.bike_type_list()).then((data)=>{
            let newBikeTypeList = [];
            data.data.map(item => {
                newBikeTypeList.push([item.bikeName, item.noOfBattery, item.registered, item.isActive, item._id, item._id, item]);
            })
            setBikeTypeListList(newBikeTypeList)
        }).catch({})
    },[])
    useEffect(()=>{
        const table = $(`#${tableName}`).DataTable(
            {
                dom: 'Bfrtip',
                buttons: [
                    'copyHtml5', 'excelHtml5', 'pdfHtml5', 'csvHtml5'
                ],
                data: bikeTypeList,
                columns: [
                    { title: "Bike Name"},
                    { title: "No. Of Battery"},
                    { title: "Registered"},
                    { title: "Status"},
                    { title: "Delete"},
                    { title: "Action"}
                ],
                columnDefs: [
                    {
                        targets: [2],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <a style={{cursor: "pointer"}} className={rowData[2] === 1 ? 'btn btn-success btn-sm status-act-btn' : 'btn btn-danger btn-sm status-act-btn'}> {rowData[2] === 1 ? 'Registered' : 'Not Registered'} </a>,td
                        )
                    },
                    {
                        targets: [3],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <a style={{cursor: "pointer"}} className={rowData[3] === 1 ? 'btn btn-success btn-sm status-act-btn' : 'btn btn-danger btn-sm status-act-btn'} onClick={() => activeDeactiveAlertPopup(rowData)}> {rowData[3] === 1 ? 'Active' : 'Deactive'} </a>,td
                        )
                    },
                    {
                        targets: [4],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <a style={{cursor: "pointer"}} className="btn btn-danger btn-sm status-act-btn" onClick={() => deleteBikeType(rowData)}> Delete </a>,td
                        )
                    }, 
                    {
                        targets: [5],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <a style={{cursor: "pointer"}} data-toggle="modal" onClick={() => setUpdateBikeType(rowData)} data-target="#editBikeTypeModalCenter" class="btn btn-info btn-sm view-btn view-act-btn">View</a>,td
                        )
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                oLanguage: {
                    oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                    //sInfo: "Showing page _PAGE_ of _PAGES_",
                    sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                    sSearchPlaceholder: "Search...",
                    sLengthMenu: "Results :  _MENU_",
                },
                'fnInitComplete': function (oSettings) {
                    $('#bike-type-list_filter').prepend('<button type="button" id="ClearData" class="btn btn-warning btn-sm add-act-btn" data-toggle="modal" data-target="#addBikeTypeModalCenter">Add Bike Type</button>');
                    const myButton = document.querySelector('#ClearData');
                    myButton.addEventListener('click', ClearModelData);
                }
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[bikeTypeList])
    const onsubmitHandler = (e) => {
        e.preventDefault()
        setErrors({});
        let customErrors = {};
        if(addBikeType.bikeName === ''){
            customErrors = {...customErrors, bikeName: 'Please enter bike type'}
            bikeFocus.current.focus();
        }
        if(Object.keys(customErrors).length > 0){
            setErrors(customErrors)
            return true
        }
        dispatch(action.add_bike_type(addBikeType)).then((data)=>{
            toast.success(data.msg);
            let response = data.data;
            setBikeTypeListList([...bikeTypeList, [response.bikeName, response.noOfBattery, response.registered, response.isActive, response._id, response._id, response]]);
            setAddBikeType({
                bikeName:"",
                noOfBattery:"",
                registered:1,
                isActive:1,
            });
            $("#addBikeTypeModalCenter .close").click();
        }).catch(error=>{
            toast.error(error);
        })
    }
    const onUpdateHandler = (e) => {
        e.preventDefault()
        setErrors({})
        let customErrors = {};
        if(editBikeType.bikeName === ''){
            customErrors = {...customErrors, editBikeName: 'Please enter bike name'}
            bikeEditFocus.current.focus();
        }
        if(Object.keys(customErrors).length > 0){
            setErrors(customErrors)
            return true
        }
        dispatch(action.edit_bike_type(editBikeType)).then((data)=>{
            toast.success(data.msg);
            let newBikeTypeList = [];
            bikeTypeList.map(item => {
                if(item[4] === editBikeType.id){
                    let bike_type_data = data.data;
                    newBikeTypeList.push([bike_type_data.bikeName, bike_type_data.noOfBattery, bike_type_data.registered, bike_type_data.isActive, bike_type_data._id, bike_type_data._id, bike_type_data]);
                } else {
                    newBikeTypeList.push(item);
                }
            })
            setBikeTypeListList(newBikeTypeList)
            setEditBikeType({
                id:'',
                bikeName:"",
                noOfBattery:"",
                registered:1,
                isActive:1,
            });
            setEditFormEditable(false);
            $("#editBikeTypeModalCenter .close").click();
        }).catch(error=>{
            toast.error(error);
        })
    }
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover  theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                {
                    Addloading ? <Loader /> : null
                }
                {
                    Editloading ? <Loader /> : null
                }
                {
                    Deleteloading ? <Loader /> : null
                }
                {
                    Statusloading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                <div className="layout-px-spacing">

                    <div className="row layout-top-spacing">

                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                            <div className="widget-content widget-content-area br-6">
                                <h5 className="" style={{margin: '10px'}}>Bike Type List</h5>
                                <div className="table-responsive mb-4 mt-4">
                                    <table id="bike-type-list" className="table table-hover" style={{width:'100%'}}>
                                        <thead>
                                            <tr>
                                                <th>Bike Name</th>
                                                <th>No. Of Battery</th>
                                                <th>Registered</th>
                                                <th>Status</th>
                                                <th>Delete</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <Footer></Footer>
            </div>
            </div>
            <div className="modal fade" id="addBikeTypeModalCenter" tabindex="-1" role="dialog" aria-labelledby="addBikeTypeModalCenterTitle" style={{display: 'none'}} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <form onSubmit={onsubmitHandler}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addBikeTypeModalCenterTitle">Add Bike Type</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="customer-address-class" id="customer-address-div-id">
                                    <div className="row" id="defaultaddressdiv0">
                                        <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputName-add">Bike Name *</label>
                                                <input type="text" className="form-control" ref={bikeFocus} name="bikeName" id="inputName-add" placeholder="Enter Bike Name" onChange={(e)=>setAddBikeType({...addBikeType,  bikeName:e.target.value})} value={addBikeType.bikeName}/>
                                                <span style={{color : 'red'}}>{errors?.bikeName}</span>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputTelematicsNumber-add">No. Of Battery *</label>
                                                <input type="number" name="noOfBattery" className="form-control" id="inputTelematicsNumber-add" placeholder="Enter No." required onChange={(e)=>setAddBikeType({...addBikeType,  noOfBattery:e.target.value})} value={addBikeType.noOfBattery} pattern='^[1-2]*$'/>
                                            </div>
                                        </div> */}
                                        <div className="form-group col-md-12 required">
                                            <label>No. Of Battery *</label><br/>
                                            <div className="custom-control custom-radio custom-control-inline" id="vehicleNoOfBattery">
                                                {
                                                    addBikeType.noOfBattery === 1 ? <input type="radio" id="vehicleNoOfBattery1" name="vehicleNoOfBattery" className="custom-control-input" onClick={(e)=>setAddBikeType({...addBikeType,  noOfBattery:1})} value="1" checked/> : <input type="radio" id="vehicleNoOfBattery1" name="vehicleNoOfBattery" className="custom-control-input" onClick={(e)=>setAddBikeType({...addBikeType ,  noOfBattery:1})} value="1" />
                                                }
                                                <label className="custom-control-label status-radio" for="vehicleNoOfBattery1">1</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                {
                                                    addBikeType.noOfBattery === 2 ? <input type="radio" id="vehicleNoOfBattery0" name="vehicleNoOfBattery" className="custom-control-input" value="2" onClick={(e)=>setAddBikeType({...addBikeType,  noOfBattery:2})} checked/> : <input type="radio" id="vehicleNoOfBattery0" name="vehicleNoOfBattery" className="custom-control-input" value="2" onClick={(e)=>setAddBikeType({...addBikeType,  noOfBattery:2})} />
                                                }
                                                <label className="custom-control-label status-radio" for="vehicleNoOfBattery0">2</label>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12 required">
                                            <label>Bike Type Registered *</label>
                                            <div className="custom-control custom-radio custom-control-inline" id="vehicleRegistered">
                                                {
                                                    addBikeType.registered === 1 ? <input type="radio" id="vehicleRegistered1" name="vehicleRegistered" className="custom-control-input" onClick={(e)=>setAddBikeType({...addBikeType,  registered:1})} value="1" checked/> : <input type="radio" id="vehicleRegistered1" name="vehicleRegistered" className="custom-control-input" onClick={(e)=>setAddBikeType({...addBikeType ,  registered:1})} value="1" />
                                                }
                                                <label className="custom-control-label status-radio" for="vehicleRegistered1">Registered</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                {
                                                    addBikeType.registered === 0 ? <input type="radio" id="vehicleRegistered0" name="vehicleRegistered" className="custom-control-input" value="0" onClick={(e)=>setAddBikeType({...addBikeType,  registered:0})} checked/> : <input type="radio" id="vehicleRegistered0" name="vehicleRegistered" className="custom-control-input" value="0" onClick={(e)=>setAddBikeType({...addBikeType,  registered:0})} />
                                                }
                                                <label className="custom-control-label status-radio" for="vehicleRegistered0">Not Registered</label>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12 required">
                                            <label>Bike Type Status *</label><br/>
                                            <div className="custom-control custom-radio custom-control-inline" id="vehicleStatus">
                                                {
                                                    addBikeType.isActive === 1 ? <input type="radio" id="vehicleStatus1" name="vehicleStatus" className="custom-control-input" onClick={(e)=>setAddBikeType({...addBikeType,  isActive:1})} value="1" checked/> : <input type="radio" id="vehicleStatus1" name="vehicleStatus" className="custom-control-input" onClick={(e)=>setAddBikeType({...addBikeType ,  isActive:1})} value="1" />
                                                }
                                                <label className="custom-control-label status-radio" for="vehicleStatus1">Active</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                {
                                                    addBikeType.isActive === 0 ? <input type="radio" id="vehicleStatus0" name="vehicleStatus" className="custom-control-input" value="0" onClick={(e)=>setAddBikeType({...addBikeType,  isActive:0})} checked/> : <input type="radio" id="vehicleStatus0" name="vehicleStatus" className="custom-control-input" value="0" onClick={(e)=>setAddBikeType({...addBikeType,  isActive:0})} />
                                                }
                                                <label className="custom-control-label status-radio" for="vehicleStatus0">Deactive</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>      
                            </div>
                            
                            <div className="modal-footer">
                                <button className="btn" data-dismiss="modal"><i className="flaticon-cancel-12"></i>Discard</button>
                                <button type="submit" className="btn btn-primary view-btn">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="editBikeTypeModalCenter" tabindex="-1" role="dialog" aria-labelledby="addBikeTypeModalCenterTitle" style={{display: 'none'}} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <form onSubmit={onUpdateHandler}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addBikeTypeModalCenterTitle">Update Bike Type</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="customer-address-class" id="customer-address-div-id">
                                    <div className="row" id="defaultaddressdiv0">
                                        <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputName-edit">Bike Name *</label>
                                                <input disabled={disabled} type="text" ref={bikeEditFocus} className="form-control" name="bikeName" id="inputName-edit" placeholder="Enter Name" onChange={(e)=>setEditBikeType({...editBikeType, bikeName:e.target.value})} value={editBikeType.bikeName}/>
                                                <span style={{color : 'red'}}>{errors?.editBikeName}</span>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputTelematicsNumber-add">No. Of Battery *</label>
                                                <input disabled={disabled} type="text" name="noOfBattery" className="form-control" id="inputTelematicsNumber-add" pattern="^[1-2]$" placeholder="Enter No. Of Battery" required onChange={(e)=>setEditBikeType({...editBikeType, noOfBattery:e.target.value})} value={editBikeType.noOfBattery}/>
                                            </div>
                                        </div> */}
                                        <div className="form-group col-md-12 required">
                                            <label>No. Of Battery *</label><br/>
                                            <div className="custom-control custom-radio custom-control-inline" id="editVehicleNoOfBattery">
                                                {
                                                    editBikeType.noOfBattery === 1 ? <input disabled={disabled} type="radio" id="editVehicleNoOfBattery1" name="editVehicleNoOfBattery" className="custom-control-input" onClick={(e)=>setEditBikeType({...editBikeType,  noOfBattery:1})} value="1" checked/> : <input disabled={disabled} type="radio" id="editVehicleNoOfBattery1" name="editVehicleNoOfBattery" className="custom-control-input" onClick={(e)=>setEditBikeType({...editBikeType ,  noOfBattery:1})} value="1" />
                                                }
                                                <label className="custom-control-label status-radio" for="editVehicleNoOfBattery1">1</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                {
                                                    editBikeType.noOfBattery === 2 ? <input disabled={disabled} type="radio" id="editVehicleNoOfBattery0" name="editVehicleNoOfBattery" className="custom-control-input" value="2" onClick={(e)=>setEditBikeType({...editBikeType,  noOfBattery:2})} checked/> : <input disabled={disabled} type="radio" id="editVehicleNoOfBattery0" name="editVehicleNoOfBattery" className="custom-control-input" value="2" onClick={(e)=>setEditBikeType({...editBikeType,  noOfBattery:2})} />
                                                }
                                                <label className="custom-control-label status-radio" for="editVehicleNoOfBattery0">2</label>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12 required">
                                            <label>Bike Type Registered *</label>
                                            <div className="custom-control custom-radio custom-control-inline" id="editvehicleRegistered">
                                                {
                                                    editBikeType.registered === 1 ? <input disabled={disabled} type="radio" id="editvehicleRegistered1" name="vehicleRegistered" className="custom-control-input" onClick={(e)=>setEditBikeType({...editBikeType,  registered:1})} value="1" checked/> : <input disabled={disabled} type="radio" id="editvehicleRegistered1" name="vehicleRegistered" className="custom-control-input" onClick={(e)=>setEditBikeType({...editBikeType,  registered:1})} value="1" />
                                                }
                                                <label className="custom-control-label status-radio" for="editvehicleRegistered1">Registered</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                {
                                                    editBikeType.registered === 0 ? <input disabled={disabled} type="radio" id="editvehicleRegistered0" name="vehicleRegistered" className="custom-control-input" value="0" onClick={(e)=>setEditBikeType({...editBikeType,  registered:0})} checked/> : <input disabled={disabled} type="radio" id="editvehicleRegistered0" name="vehicleRegistered" className="custom-control-input" value="0" onClick={(e)=>setEditBikeType({...editBikeType,  registered:0})} />
                                                }
                                                <label className="custom-control-label status-radio" for="editvehicleRegistered0">Not Registered</label>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12 required">
                                            <label>Bike Type Status *</label><br/>
                                            <div className="custom-control custom-radio custom-control-inline" id="editvehicleStatus">
                                                {
                                                    editBikeType.isActive === 1 ? <input disabled={disabled} type="radio" id="editvehicleStatus1" name="vehicleStatus" className="custom-control-input" onClick={(e)=>setEditBikeType({...editBikeType,  isActive:1})} value="1" checked/> : <input disabled={disabled} type="radio" id="editvehicleStatus1" name="vehicleStatus" className="custom-control-input" onClick={(e)=>setEditBikeType({...editBikeType,  isActive:1})} value="1" />
                                                }
                                                <label className="custom-control-label status-radio" for="editvehicleStatus1">Active</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                {
                                                    editBikeType.isActive === 0 ? <input disabled={disabled} type="radio" id="editvehicleStatus0" name="vehicleStatus" className="custom-control-input" value="0" onClick={(e)=>setEditBikeType({...editBikeType,  isActive:0})} checked/> : <input disabled={disabled} type="radio" id="editvehicleStatus0" name="vehicleStatus" className="custom-control-input" value="0" onClick={(e)=>setEditBikeType({...editBikeType,  isActive:0})} />
                                                }
                                                <label className="custom-control-label status-radio" for="editvehicleStatus0">Deactive</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>      
                            </div>
                            
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary view-btn" hidden={editButtonHide} onClick={ ()=>setEditFormEditable(true)}>Edit</button>
                                <button type="submit" className="btn btn-primary view-btn" hidden={submitButtonHide}>Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Vehicle_Telematics_Unit