import React, { useEffect, useRef, useState} from 'react'
import { useNavigate, useParams }from 'react-router-dom'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import { format } from 'date-fns'
import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import {ExportToExcel} from './ExportToExcelForDriver'
import Loader from '../../common/Loader/Loader';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/Driver/driver_action';
import $ from 'jquery'
const Driver_Work_history = props => {
    $.DataTable = require('datatables.net')
    const driver_id = props.driver_id;
    const dispatch = useDispatch();
    var date = new Date();
    const [input , setInput]= useState([
        new Date(date.getFullYear(), date.getMonth(), 1),
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
    ]);
    const changeDateFormate = (date) => {
        if (date) {
            let new_date = String(date);
            let ndate = new_date.split('T');
            let format_date = format(new Date(ndate[0]), 'dd MMM yyyy');
            return format_date;
        }
    }
    const predefinedRanges = [
        {
            label: 'Current month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 60 days',
            value: [subDays(new Date(), 59), new Date()],
            placement: 'left'
        }
    ];
    const [driverDetails , setDriverDetail]= useState({});
    useEffect(() => {
        dispatch(action.driver_details(driver_id)).then((data)=>{
            let driver_details = data.data;
            setDriverDetail(driver_details);
        }).catch({})
    },[])  
    const [driverWorkList,setDriverWorkList]=useState([]);
    const [driverWorkExcelList,setDriverWorkExcelList]=useState([]);
    useEffect(() => {
        dispatch(action.all_driver_work_list(input[0], input[1], driver_id)).then((data)=>{
            let newDriverWorkList = [];
            data.data.map(item => {
                newDriverWorkList.push([changeDateFormate(item.createdAt), `${item.customerData.businessName} <br> ${item.customerData.name} <br> ${item.customerData.mobile}`, item.pickupParcelCount ? item.pickupParcelCount:0, item.deliveredParcelCount ? item.deliveredParcelCount:0, `<a style="cursor: pointer;" href="/adminside/driver/View_Driver_Work_Images/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn">View</a>`]);
            })
            setDriverWorkList(newDriverWorkList)
        }).catch({})
    },[input])
    useEffect(() => {
        dispatch(action.all_driver_work_list(input[0], input[1], driver_id)).then((data)=>{
            setDriverWorkExcelList(data.data)
        }).catch({})
    },[input])
    useEffect(()=>{
        const tableName = "driver-works-list";
        const table = $(`#${tableName}`).DataTable({
            dom: 'Bfrtip',
            buttons: [
                'copyHtml5', 'excelHtml5', 'pdfHtml5', 'csvHtml5'
            ],
            data: driverWorkList,
            columns: [
                // {title: "Driver"},
                // {title: "Vehicle"},
                {title: "Date"},
                {title: "Customer"},
                // {title: "Customer Address"},
                // {title: "No. Of Deliveries"},
                // {title: "Start Date"},
                // {title: "End Date"},
                {title: "Pickup"},
                {title: "Done"},
                {title: "Action"},
            ],
            destroy: true,  // I think some clean up is happening here
            searching: true,
            oLanguage: {
                oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                //sInfo: "Showing page _PAGE_ of _PAGES_",
                sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                sSearchPlaceholder: "Search...",
                sLengthMenu: "Results :  _MENU_",
            },
        })
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[driverWorkList])
    return (
        <>
            <div class="table-responsive mb-4 mt-4">
                <div class="form-group row col-12 ml-1">
                    <DateRangePicker ranges={predefinedRanges} value={input} onChange={setInput} style={{ width: 300 }} placeholder="Select Date Range"/>&nbsp;
                    {
                        (driverWorkExcelList && driverWorkExcelList.length) ?
                         <ExportToExcel apiData={driverWorkExcelList} driverDetails={driverDetails}/>
                         : <div className='btn btn-success disabled'>Export to XLS</div> 
                    }
                </div>
                <table id="driver-works-list" class="table table-hover"
                    style={{width:'100%'}}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Customer</th>
                            <th>Pickup</th>
                            <th>Done</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default Driver_Work_history