import React,{useEffect, useState}  from 'react'
import { useNavigate, useParams }from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Footer from '../../DashBoard/Footer';
import Loader from '../../common/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import * as action from '../../../action/Vehicle/vehicle_action';
import * as action1 from '../../../action/HubVehiclesAdmin/vehicle_action';
const ViewVehicle = props => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {vehicle_id} = useParams();
    const [disabled, setDisabled]=useState(true);
    const [editButtonHide, setEditButtonHide]=useState(false);
    const [addDocButtonHide, setAddDocButtonHide]=useState(true);
    const [cancelButtonHide, setCancelButtonHide]=useState(true);
    const [submitButtonHide, setSubmitButtonHide]=useState(true);
    const {loading} = useSelector(state => state.addCustomerReducer)
    const [modalImgURL, setModalImgURL]=useState("");
    const [otherDocumentRow,setOtherDocumentRow]=useState([])
    const [telematicsUnitList,setTelematicsUnitList]=useState([])
    const [leaseCompanyList,setLeaseCompanyList]=useState([])
    const [hubList,setHubList]=useState([])
    const [technicianList,setTechnicianList]=useState([])
    const [input , setInput]= useState({
        id:vehicle_id,
        number:"",
        type:"",
        details:"",
        ownerName:"",
        rcBookFile:"",
        insuranceFile:"",
        permitFile:"",
        insuranceExpiryDate:"",
        permitExpiryDate:"",
        qrCodeSrc:"",
        insuranceNo:"",
        batteryNo:"",
        chasisNo:"",
        rcBookNo:"",
        city:"",
        registered:1,
        owned:1,
        isActive:1,
        isRepaired:2,
        leaseCompanyId:"",
        telematicsUnit:"",
        sr_number:"",
        vehicleHubId:"",
        vehicleTechnicianId:"",
        deactivateReason:"",
        deactivateReasonInput:""
    });
    const changeDateFormate = (date) =>{
        if(date){
            let new_date = String(date);
            let ndate = new_date.split('T');
            return ndate[0];
        }
    }
    useEffect(() => {
        dispatch(action.vehicle_telematics_unit_list_select()).then((data)=>{
            setTelematicsUnitList(data.data);
        }).catch({})
    },[])
    useEffect(() => {
        dispatch(action.vehicle_lease_company_list_select()).then((data)=>{
            setLeaseCompanyList(data.data);
        }).catch({})
    },[])
    useEffect(() => {
        dispatch(action.vehicle_hub_list_select()).then((data)=>{
            setHubList(data.data);
        }).catch({})
    },[])
    useEffect(() => {
        dispatch(action1.Vehicle_technician_list()).then((data)=>{
            setTechnicianList(data.data);
        }).catch({})
    },[])
    useEffect(() => {
        dispatch(action.vehicle_details(vehicle_id)).then((data)=>{
            let vehicle_details = data.data;
            setInput({
                id:vehicle_id,
                number:vehicle_details.number,
                type:vehicle_details.type,
                details:vehicle_details.details,
                ownerName:vehicle_details.ownerName,
                rcBookFile:vehicle_details.rcBookFile,
                insuranceFile:vehicle_details.insuranceFile,
                permitFile:vehicle_details.permitFile,
                insuranceExpiryDate:vehicle_details.insuranceExpiryDate,
                permitExpiryDate:vehicle_details.permitExpiryDate,
                qrCodeSrc:vehicle_details.qrCodeSrc,
                insuranceNo:vehicle_details.insuranceNo,
                batteryNo:vehicle_details.batteryNo,
                chasisNo:vehicle_details.chasisNo,
                rcBookNo:vehicle_details.rcBookNo,
                city:vehicle_details.city,
                registered:vehicle_details.registered,
                owned:vehicle_details.owned,
                isActive:vehicle_details.isActive,
                isRepaired:vehicle_details.isRepaired,
                leaseCompanyId:vehicle_details.leaseCompanyId,
                telematicsUnit:vehicle_details.telematicsUnit,
                sr_number:vehicle_details.sr_number,
                vehicleHubId:vehicle_details.vehicleHubId,
                vehicleTechnicianId:vehicle_details.vehicleTechnicianId,
                deactivateReason:vehicle_details.deactivateReason,
                deactivateReasonInput:vehicle_details.deactivateReasonInput
            });
            let newDocList = [];
            let docCount = 1;
            console.log(vehicle_details);
            data.data.otherDocuments.map(item => {
                newDocList.push({
                    id: docCount,
                    docName:item.docName,
                    docUrl:item.docUrl
                });
                docCount++;
            })
            setOtherDocumentRow(newDocList)
            console.log(otherDocumentRow);
        }).catch({})
    },[])  
    const setEditFormEditable = (type) => {
        if(type){
            setDisabled(false);
            setAddDocButtonHide(false);
            setCancelButtonHide(false);
            setSubmitButtonHide(false);
            setEditButtonHide(true);
        } else {
            setDisabled(true);
            setAddDocButtonHide(true);
            setCancelButtonHide(true);
            setSubmitButtonHide(true);
            setEditButtonHide(false);
        }
    }
    const uploadRCBookFile = (e) => {
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("image", file, file.name);
        dispatch(action.vehicle_file_upload(formData)).then((data)=>{
            setInput({...input, rcBookFile:data.data.url})
        }).catch({})
    }
    const uploadInsuranceFile = (e) => {
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("image", file, file.name);
        dispatch(action.vehicle_file_upload(formData)).then((data)=>{
            setInput({...input, insuranceFile:data.data.url})
        }).catch({})
    }
    const uploadPermitFile = (e) => {
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("image", file, file.name);
        dispatch(action.vehicle_file_upload(formData)).then((data)=>{
            setInput({...input, permitFile:data.data.url})
        }).catch({})
    }
    const uploadOtherDocFile = (e, otherDocId) => {
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("image", file, file.name);
        dispatch(action.vehicle_file_upload(formData)).then((data)=>{
            let oldotherDocument = otherDocumentRow.filter(item => item.id === otherDocId)?.[0]
            oldotherDocument = {...oldotherDocument, docUrl: data.data.url}
            let newOtherDocument = []
            otherDocumentRow.map(item => {
                if(item.id === otherDocId){
                    newOtherDocument.push(oldotherDocument)
                } else 
                newOtherDocument.push(item)
            })
            setOtherDocumentRow(newOtherDocument)
        }).catch({})
    }
    const addExtraDocumentsInForm = () => {
        if(otherDocumentRow.length > 0){
            setOtherDocumentRow([...otherDocumentRow, {id: otherDocumentRow.length+1, docName:"", docUrl:""}])
        } else {
            setOtherDocumentRow([{id: 1, docName:"", docUrl:""}]);
        }
    }
    const deleteExtraDocumentsInForm = (otherDocId, docUrl) => {
        if(docUrl != ''){
            const fparr = docUrl.split('/');
            const imageName = fparr[fparr.length - 1];
            const sendData = { name: imageName }
            console.log(sendData);
            dispatch(action.delete_vehicle_file(sendData)).then((data)=>{}).catch({})
        }
        setOtherDocumentRow(otherDocumentRow.filter(item => item.id !== otherDocId))
    }
    const addExtraDocumentsValue = (e, otherDocId) => {
        let oldotherDocument = otherDocumentRow.filter(item => item.id === otherDocId)?.[0]
        oldotherDocument = {...oldotherDocument, [e.target.name]: e.target.value}
        let newOtherDocument = []
        otherDocumentRow.map(item => {
            if(item.id === otherDocId){
                newOtherDocument.push(oldotherDocument)
            } else 
            newOtherDocument.push(item)
        })
        setOtherDocumentRow(newOtherDocument)
    }
    const onsubmitHandler = (e) => {
        e.preventDefault()
        let documents = [];
        otherDocumentRow.map(item => {
            documents.push({
                docName: item.docName,
                docUrl: item.docUrl
            });
        })
        let parameter = {
            id:vehicle_id,
            number:input.number,
            type:input.type,
            details:input.details,
            ownerName:input.ownerName,
            rcBookFile:input.rcBookFile,
            insuranceFile:input.insuranceFile,
            permitFile:input.permitFile,
            insuranceExpiryDate:input.insuranceExpiryDate,
            permitExpiryDate:input.permitExpiryDate,
            insuranceNo:input.insuranceNo,
            batteryNo:input.batteryNo,
            chasisNo:input.chasisNo,
            rcBookNo:input.rcBookNo,
            city:input.city,
            registered:input.registered,
            owned:input.owned,
            isActive:input.isActive,
            isRepaired:input.isRepaired,
            leaseCompanyId:input.leaseCompanyId,
            telematicsUnit:input.telematicsUnit,
            sr_number:input.sr_number,
            vehicleHubId:input.vehicleHubId,
            vehicleTechnicianId:input.vehicleTechnicianId,
            deactivateReason:input.deactivateReason,
            deactivateReasonInput:input.deactivateReasonInput
        };
        if(documents.length > 0){
            parameter.otherDocuments = documents
        }
        dispatch(action.edit_vehicle(parameter)).then((data)=>{
            toast.success(data.msg);
            setDisabled(true);
            setAddDocButtonHide(true);
            setCancelButtonHide(true);
            setSubmitButtonHide(true);
            setEditButtonHide(false);
            setInput({...input, qrCodeSrc: data.data.qrCodeSrc});
        }).catch(error=>{
            toast.error(error);
        })
    }
    return (
        <>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
        <Header />
        <Sub_Header />
        <div className="main-container" id="container">
            {
                loading ? <Loader /> : null
            }
            <div className="overlay"></div>
            <div className="search-overlay"></div>

            <SideBar></SideBar>
            <div id="content" className="main-content">
                <div className="layout-px-spacing">

                    <div className="row layout-top-spacing">
                    
                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                            <div className="widget-content widget-content-area br-6">
                                <div className="input-group edit-btn-group">
                                    <h5 className="">Vehicle</h5>
                                    {/* <span id="editidspan" className="btn btn-warning btn-sm float-right ms-3 mb-1" hidden={editButtonHide} style={{cursor: 'pointer'}} onClick={ ()=>setEditFormEditable(true)}> Edit </span> */}
                                </div>
                                <br/>
                                <form onSubmit={onsubmitHandler}>
                                    <div id="append-div-id">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputVehicleNumber">Vehicle Number *</label>
                                                        <input disabled className="form-control" id="inputVehicleNumber" name="number"  minlength="10" maxlength="13" placeholder="GJ 05 EF 1234" required onChange={(e)=>setInput({...input ,  number:e.target.value})} value={input.number}/>
                                                    </div>        
                                                </div>
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputVehicleType">Select Vehicle Type *</label>
                                                        <select disabled id="inputVehicleType" name="type" className="form-control selectpicker" required onChange={(e)=>setInput({...input ,  type:e.target.value})} value={input.type}>
                                                            <option value="">Select Vehicle Type</option>
                                                            <option value="2">2 Wheeler</option>
                                                            <option value="3">3 Wheeler</option>
                                                            <option value="4">4 Wheeler</option>
                                                        </select>
                                                    </div>    
                                                </div>
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputVehicleBatteryNumber">Vehicle Battery No.*</label>
                                                        <input disabled className="form-control" id="inputVehicleBatteryNumber" name="batteryNo" placeholder="Enter Battery No." required onChange={(e)=>setInput({...input ,  batteryNo:e.target.value})} value={input.batteryNo}/>
                                                    </div>        
                                                </div>
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputVehicleSrNumber">Vehicle SR No.*</label>
                                                        <input disabled className="form-control" id="inputVehicleSrNumber" name="sr_number" placeholder="Enter Sr No." required onChange={(e)=>setInput({...input ,  sr_number:e.target.value})} value={input.sr_number}/>
                                                    </div>        
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputInsuranceNo">Insurance Number *</label>
                                                        <input disabled className="form-control" id="inputInsuranceNo" name="text"  minlength="10" maxlength="20" placeholder="Enter Vehicle Insurance Number" required onChange={(e)=>setInput({...input ,  insuranceNo:e.target.value})} value={input.insuranceNo}/>
                                                    </div>  
                                                </div>
                                                {/* <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputVehicleTelematicsUnit">Telematics Unit *</label>
                                                        <select disabled id="inputVehicleTelematicsUnit" name="type" className="form-control selectpicker" required onChange={(e)=>setInput({...input ,  telematicsUnitId:e.target.value})} value={input.telematicsUnitId}>
                                                            <option value="">Select Vehicle Telematics Unit</option>
                                                            {
                                                                telematicsUnitList.map((telematicsUnit)=>(
                                                                    <>
                                                                        <option value={telematicsUnit._id}>{telematicsUnit.name}</option>
                                                                    </>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>      
                                                </div> */}
                                                <div className="form-group col-md-12 required">
                                                    <label for="inputVehicleTelematicsUnit">Vehicle Telematics Unit *</label>
                                                    <input disabled className="form-control" id="inputVehicleTelematicsUnit" name="text" placeholder="Enter Vehicle TelematicUnit" onChange={(e)=>setInput({...input ,  telematicsUnit:e.target.value})} value={input.telematicsUnit}/>
                                                </div>  
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputVehicleChasisNumber">Vehicle Chasis No.*</label>
                                                        <input disabled className="form-control" id="inputVehicleChasisNumber" name="chasisNo" placeholder="Enter Chasis No." required onChange={(e)=>setInput({...input ,  chasisNo:e.target.value})} value={input.chasisNo}/>
                                                    </div>        
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputCity">City *</label>
                                                        <input disabled className="form-control" id="inputCity" name="text" placeholder="Enter City" required onChange={(e)=>setInput({...input ,  city:e.target.value})} value={input.city}/>
                                                    </div>  
                                                </div>
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputVehicleHub">Select Vehicle Hub *</label>
                                                        <select disabled id="inputVehicleHub" name="type" className="form-control selectpicker" required onChange={(e)=>setInput({...input ,  vehicleHubId:e.target.value})} value={input.vehicleHubId}>
                                                            <option value="">Select Vehicle Hub</option>
                                                            {
                                                                hubList.map((hub)=>(
                                                                    <>
                                                                        <option value={hub._id}>{hub.name}</option>
                                                                    </>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>            
                                                </div>
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputVehicleRCBookNumber">Vehicle RC Book No.*</label>
                                                        <input disabled className="form-control" id="inputVehicleRCBookNumber" name="rcBookNo" placeholder="Enter RC Book No." required onChange={(e)=>setInput({...input ,  rcBookNo:e.target.value})} value={input.rcBookNo}/>
                                                    </div>        
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required" id="vehicleStatusRegisted">
                                                        <label>Owned /Leased *</label><br/>
                                                        <div className="custom-control custom-radio custom-control-inline" id="vehicleOwnedLeased">
                                                            {
                                                                input.owned === 1 ? <input disabled type="radio" id="statusOwnedLeased1" name="vehicleOwnedLeasedStatus" className="custom-control-input" value="1" onClick={(e)=>setInput({...input ,  owned:1})} checked/> : <input disabled type="radio" id="statusOwnedLeased1" name="vehicleOwnedLeasedStatus" className="custom-control-input" value="1" onClick={(e)=>setInput({...input ,  owned:1})}/>
                                                            }
                                                            <label className="custom-control-label status-radio" for="statusOwnedLeased1">Owned</label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            { 
                                                                input.owned === 2 ? <input disabled type="radio" id="statusOwnedLeased0" name="vehicleOwnedLeasedStatus" className="custom-control-input" value="0" onClick={(e)=>setInput({...input ,  owned:2})} checked/> : <input disabled type="radio" id="statusOwnedLeased0" name="vehicleOwnedLeasedStatus" className="custom-control-input" value="0" onClick={(e)=>setInput({...input ,  owned:2})}/>
                                                            }
                                                            <label className="custom-control-label status-radio" for="statusOwnedLeased0">Leased</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    {
                                                        input.owned === 2 ? <div className="form-group col-md-12 required">
                                                            <label for="inputVehicleLeaseCompany">Lease Company *</label>
                                                            <select disabled id="inputVehicleLeaseCompany" name="type" className="form-control selectpicker" required onChange={(e)=>setInput({...input ,  leaseCompanyId:e.target.value})} value={input.leaseCompanyId}>
                                                                <option value="">Select Vehicle Lease Company</option>
                                                                {
                                                                    leaseCompanyList.map((leaseCompany)=>(
                                                                        <>
                                                                            <option value={leaseCompany._id}>{leaseCompany.name}</option>
                                                                        </>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div> :''
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3">
                                                <div className="card component-card_2" id="qrCodeDiv">
                                                    <div className="card-header">QR Code</div>
                                                    <div className="card-body" style={{display: 'flex', "justify-content": "center","align-content": "center","flex-direction": "column"}}>
                                                        <img src={input.qrCodeSrc} alt="no-image" id="qr_img_src" className="img-responsive" style={{width: "100%", height: "100%"}} />                    
                                                        <a href={input.qrCodeSrc} className="btn btn-dark view-btn" id="qr_img_src_download" data-href="" download>Download</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="form-group col-md-12 required">
                                                <label for="inputVehicleOwnername">Owner Name *</label>
                                                <input disabled type="text" className="form-control" name="ownerName" id="inputVehicleOwnername" placeholder="Enter vehicle owner name" required onChange={(e)=>setInput({...input ,  ownerName:e.target.value})} value={input.ownerName}/>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="form-group col-md-12 required">
                                                <label for="inputVehicleDetails">Details</label>
                                                <input disabled type="text" className="form-control" id="inputVehicleDetails" placeholder="Enter vehicle details" name="details" required onChange={(e)=>setInput({...input ,  details:e.target.value})} value={input.details}/>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="form-group col-md-12 required">
                                                <label for="rcBookFile">RC Book *</label>
                                                <span hidden={addDocButtonHide} id="addAnotherDocIdspan" className="btn btn-warning btn-sm float-right ms-3 mb-1" style={{cursor: "pointer"}} onClick={ ()=>addExtraDocumentsInForm()}> Add Other Document </span>
                                                <input disabled accept="image/jpg,image/jpeg,image/png,image/bmp" multiple="" type="file" onChange={(e)=>uploadRCBookFile(e)} name="rcBookFile[]" className="form-control p-2" id="rcBookFile" />
                                                {
                                                    input.rcBookFile !== '' ? <img src={`http://localhost:3004/${input.rcBookFile}`} alt="no-image" id="rcBookImgId" className="form-group p-2 img-responsive imgFile" onClick={ ()=>setModalImgURL(input.rcBookFile)} data-toggle="modal" data-target="#showImageModalCenter" style={{width: "70px", height: "70px"}} /> : ''
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="form-group col-md-8 required">
                                                <label for="insuranceFile">Insurance *</label>
                                                <input disabled accept="image/jpg,image/jpeg,image/png,image/bmp" multiple="" type="file" onChange={(e)=>uploadInsuranceFile(e)} name="insuranceFile[]" className="form-control p-2" id="insuranceFile" />
                                                {
                                                    input.insuranceFile !== '' ? <img src={`http://localhost:3004/${input.insuranceFile}`} alt="no-image" id="insuranceImgId" className="form-group p-2 img-responsive imgFile" onClick={ ()=>setModalImgURL(input.insuranceFile)} data-toggle="modal" data-target="#showImageModalCenter" style={{width: "70px", height: "70px"}} /> : ''
                                                }
                                            </div>
                                            <div className="form-group col-md-4 required">
                                                <label for="insuranceExpiry">Expiry Date *</label>
                                                <input disabled type="date" name="insuranceExpiryDate" className="form-control datepicker" id="insuranceExpiry" onChange={(e)=>setInput({...input ,  insuranceExpiryDate:e.target.value})} value={changeDateFormate(input.insuranceExpiryDate)} autocomplete="off" required />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="form-group col-md-8 required">
                                                <label for="permitFile">Permit </label>
                                                <input disabled accept="image/jpg,image/jpeg,image/png,image/bmp" multiple="" type="file" onChange={(e)=>uploadPermitFile(e)} name="permitFile[]" className="form-control p-2" id="permitFile"/>
                                                {
                                                    input.permitFile !== '' ? <img src={`http://localhost:3004/${input.permitFile}`} alt="no-image" id="permitImgId" className="form-group p-2 img-responsive imgFile" onClick={ ()=>setModalImgURL(input.permitFile)} data-toggle="modal" data-target="#showImageModalCenter" style={{width: "70px", height: "70px"}} /> : ''
                                                }
                                            </div>
                                            <div className="form-group col-md-4 required">
                                                <label for="permitExpiry">Expiry Date </label>
                                                <input disabled type="date" name="permitExpiryDate" className="form-control datepicker" id="permitExpiry" onChange={(e)=>setInput({...input ,  permitExpiryDate:e.target.value})} value={changeDateFormate(input.permitExpiryDate)} autocomplete="off" />
                                            </div>
                                        </div>
                                        {
                                            otherDocumentRow.map((otherDocumentItem)=>(
                                                <>
                                                    <div className="form-group row" id={`doc${otherDocumentItem.id}`}>
                                                        <div className="form-group col-md-4 required">
                                                            <label for={`inputDocumentName${otherDocumentItem.id}`}>Document Name</label>
                                                            <input disabled type="text" className="form-control" name="docName" id={`inputDocumentName${otherDocumentItem.id}`} placeholder="Enter document name" onChange={(e)=>addExtraDocumentsValue(e, otherDocumentItem.id)} value={otherDocumentItem.docName} required="" />
                                                        </div>
                                                        <div className="form-group col-md-8 required">
                                                            <label for={`otherFile${otherDocumentItem.id}`}>Document File </label>
                                                            <span hidden={addDocButtonHide} className="badge badge-danger float-right" style={{cursor:'pointer'}} onClick={ ()=>deleteExtraDocumentsInForm(otherDocumentItem.id, otherDocumentItem.docUrl)} id={`delethisidspan${otherDocumentItem.id}`}> Delete This</span>
                                                            <input disabled accept="image/jpg,image/jpeg,image/png,image/bmp" multiple="" type="file" className="form-control p-2" id={`otherFile${otherDocumentItem.id}`} onChange={(e)=>uploadOtherDocFile(e, otherDocumentItem.id)}/>
                                                            {
                                                                otherDocumentItem.docUrl !== '' ? <img src={`http://localhost:3004/${otherDocumentItem.docUrl}`} alt="no-image" id={`otherImgId${otherDocumentItem.id}`} className="form-group p-2 img-responsive imgFile" onClick={ ()=>setModalImgURL(otherDocumentItem.docUrl)} data-toggle="modal" data-target="#showImageModalCenter" style={{width: "70px", height: "70px"}} /> : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                        <div className="form-group row">
                                            <div className="form-group col-md-12 required">
                                                <div className="form-group required" id="vehicleStatusRegisted">
                                                    <label>Vehicle Registered *</label><br/>
                                                    <div className="custom-control custom-radio custom-control-inline" id="vehicleStatusRegisted">
                                                        {
                                                            input.registered === 1 ? <input disabled type="radio" id="statusRegisted1" name="vehicleRegisteredStatus" className="custom-control-input" value="1" onClick={(e)=>setInput({...input ,  registered:1})} checked/> : <input disabled type="radio" id="statusRegisted1" name="vehicleRegisteredStatus" className="custom-control-input" value="1" onClick={(e)=>setInput({...input ,  registered:1})}/>
                                                        }
                                                        <label className="custom-control-label status-radio" for="statusRegisted1">Registered</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        {
                                                            input.registered === 0 ? <input disabled type="radio" id="statusRegisted0" name="vehicleRegisteredStatus" className="custom-control-input" value="0" onClick={(e)=>setInput({...input ,  registered:0})} checked/> : <input disabled type="radio" id="statusRegisted0" name="vehicleRegisteredStatus" className="custom-control-input" value="0" onClick={(e)=>setInput({...input ,  registered:0})}/>
                                                        }
                                                        <label className="custom-control-label status-radio" for="statusRegisted0">Un-Registered</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="form-group col-md-12 required">
                                                <div className="form-group required" id="vehicleStatusMain">
                                                    <label>Vehicle Status *</label><br/>
                                                    <div className="custom-control custom-radio custom-control-inline" id="vehicleStatus">
                                                        {
                                                            input.isActive === 1 ? <input disabled={disabled} type="radio" id="status1" name="vehicleStatus" className="custom-control-input" onClick={(e)=>setInput({...input ,  isActive:1})} value="1" checked/> : <input disabled={disabled} type="radio" id="status1" name="vehicleStatus" className="custom-control-input" onClick={(e)=>setInput({...input ,  isActive:1})} value="1" />
                                                        }
                                                        <label className="custom-control-label status-radio" for="status1">Active</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        {
                                                            input.isActive === 0 ? <input disabled={disabled} type="radio" id="status0" name="vehicleStatus" className="custom-control-input" value="0" onClick={(e)=>setInput({...input ,  isActive:0})} checked/> : <input disabled={disabled} type="radio" id="status0" name="vehicleStatus" className="custom-control-input" value="0" onClick={(e)=>setInput({...input ,  isActive:0})} />
                                                        }
                                                        <label className="custom-control-label status-radio" for="status0">Deactive</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12 required">
                                            {
                                                input.isActive === 0 ? <div className="form-group required" id="vehicleStatusDeactiveMain">
                                                    <label>Vehicle Deactivate Reason *</label>
                                                    <div class="form-check">
                                                        {
                                                            input.deactivateReason === "At Hub" ? <input disabled={disabled} class="form-check-input" type="radio" name="flexRadioDefault" value="At Hub" onClick={(e)=>setInput({...input ,  deactivateReason:"At Hub"})} id="flexRadioDefault1" checked/> : <input disabled={disabled} class="form-check-input" type="radio" name="flexRadioDefault" value="At Hub" onClick={(e)=>setInput({...input ,  deactivateReason:"At Hub"})} id="flexRadioDefault1"/>
                                                        }
                                                        <label class="form-check-label" for="flexRadioDefault1">
                                                            At Hub
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        {
                                                            input.deactivateReason === "At Dealership For Repair" ? <input disabled={disabled} class="form-check-input" type="radio" name="flexRadioDefault" value="At Dealership For Repair" id="flexRadioDefault2" onClick={(e)=>setInput({...input ,  deactivateReason:"At Dealership For Repair"})} checked/> : <input disabled={disabled} class="form-check-input" type="radio" name="flexRadioDefault" value="At Dealership For Repair" id="flexRadioDefault2" onClick={(e)=>setInput({...input ,  deactivateReason:"At Dealership For Repair"})}/>
                                                        }
                                                        <label class="form-check-label" for="flexRadioDefault2">
                                                            At Dealership For Repair
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        {
                                                            input.deactivateReason === "Waiting For Spair Parts" ? <input disabled={disabled} class="form-check-input" type="radio" name="flexRadioDefault" value="Waiting For Spair Parts" id="flexRadioDefault3" onClick={(e)=>setInput({...input ,  deactivateReason:"Waiting For Spair Parts"})} checked/> : <input disabled={disabled} class="form-check-input" type="radio" name="flexRadioDefault" value="Waiting For Spair Parts" id="flexRadioDefault3" onClick={(e)=>setInput({...input ,  deactivateReason:"Waiting For Spair Parts"})}/>
                                                        }
                                                        <label class="form-check-label" for="flexRadioDefault3">
                                                            Waiting For Spair Parts
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        {
                                                            input.deactivateReason === "Insurance Claim" ? <input disabled={disabled} class="form-check-input" type="radio" name="flexRadioDefault" value="Insurance Claim" id="flexRadioDefault4" onClick={(e)=>setInput({...input ,  deactivateReason:"Insurance Claim"})} checked/> : <input disabled={disabled} class="form-check-input" type="radio" name="flexRadioDefault" value="Insurance Claim" id="flexRadioDefault4" onClick={(e)=>setInput({...input ,  deactivateReason:"Insurance Claim"})}/>
                                                        }
                                                        <label class="form-check-label" for="flexRadioDefault4">
                                                            Insurance Claim
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        {
                                                            input.deactivateReason === "Under Maintenance" ? <input class="form-check-input" type="radio" name="flexRadioDefault" value="Under Maintenance" id="flexRadioDefault4" onClick={(e)=>setInput({...input ,  deactivateReason:"Under Maintenance"})} checked/> : <input class="form-check-input" type="radio" name="flexRadioDefault" value="Under Maintenance" id="flexRadioDefault4" onClick={(e)=>setInput({...input ,  deactivateReason:"Under Maintenance"})}/>
                                                        }
                                                        <label class="form-check-label" for="flexRadioDefault4">
                                                            Under Maintenance
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        {
                                                            input.deactivateReason === "Others" ? <input disabled={disabled} class="form-check-input" type="radio" name="flexRadioDefault" value="Others" id="flexRadioDefault4" onClick={(e)=>setInput({...input ,  deactivateReason:"Others"})} checked/> : <input disabled={disabled} class="form-check-input" type="radio" name="flexRadioDefault" value="Others" id="flexRadioDefault4" onClick={(e)=>setInput({...input ,  deactivateReason:"Others"})}/>
                                                        }
                                                        <label class="form-check-label" for="flexRadioDefault4">
                                                            Others
                                                        </label>
                                                    </div>
                                                    {
                                                        input.deactivateReason === 'Others' ? <div class="form-check">
                                                            <input disabled={disabled} class="form-control col-md-12" type="text" name="deactivateReasonInput"  id="deactivateReasonInput" onChange={(e)=>setInput({...input ,  deactivateReasonInput:e.target.value})} value={input.deactivateReasonInput}/><br/>
                                                        </div> :''
                                                    }
                                                </div>:''
                                            }
                                            {
                                                input.isActive === 0 ? <div className="form-group row">
                                                    <div className="form-group col-md-6 required">
                                                        <label for="inputVehicleHub">Select Technicion *</label>
                                                        <select disabled={disabled} id="inputVehicleHub" name="type" className="form-control selectpicker" required onChange={(e)=>setInput({...input ,  vehicleTechnicianId:e.target.value})} value={input.vehicleTechnicianId}>
                                                            <option value="">Select Technicion</option>
                                                            {
                                                                technicianList.map((technician)=>(
                                                                    <>
                                                                        <option value={technician._id}>{technician.name}</option>
                                                                    </>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>            
                                                </div>:''
                                            }
                                            </div>
                                            <div className="form-group col-md-12 required">
                                                <div className="form-group required" id="vehicleStatusMain">
                                                    <label>Vehicle Repair Status *</label><br/>
                                                    <div className="custom-control custom-radio custom-control-inline" id="vehicleRepairStatus">
                                                        {
                                                            input.isRepaired === 0 ? <input disabled={disabled} type="radio" id="vehicleRepairStatus0" name="vehicleRepairStatus" className="custom-control-input" onClick={(e)=>setInput({...input ,  isRepaired:0})} value="0" checked/> : <input disabled={disabled} type="radio" id="vehicleRepairStatus0" name="vehicleRepairStatus" className="custom-control-input" onClick={(e)=>setInput({...input ,  isRepaired:0})} value="0" />
                                                        }
                                                        <label className="custom-control-label status-radio" for="vehicleRepairStatus0">Not Repaired</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        {
                                                            input.isRepaired === 1 ? <input disabled={disabled} type="radio" id="vehicleRepairStatus1" name="vehicleRepairStatus" className="custom-control-input" onClick={(e)=>setInput({...input ,  isRepaired:1})} value="1" checked/> : <input disabled={disabled} type="radio" id="vehicleRepairStatus1" name="vehicleRepairStatus" className="custom-control-input" onClick={(e)=>setInput({...input ,  isRepaired:1})} value="1" />
                                                        }
                                                        <label className="custom-control-label status-radio" for="vehicleRepairStatus1">Repair Start</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        {
                                                            input.isRepaired === 2 ? <input disabled={disabled} type="radio" id="vehicleRepairStatus2" name="vehicleRepairStatus" className="custom-control-input" value="0" onClick={(e)=>setInput({...input ,  isRepaired:2})} checked/> : <input disabled={disabled} type="radio" id="vehicleRepairStatus2" name="vehicleRepairStatus" className="custom-control-input" value="0" onClick={(e)=>setInput({...input ,  isRepaired:2})} />
                                                        }
                                                        <label className="custom-control-label status-radio" for="vehicleRepairStatus2">Repair Completed</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade" id="showImageModalCenter" tabindex="-1" role="dialog" aria-labelledby="showImageModalCenterLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="img">
                                                <div className="modal-content">         
                                                    <div className="modal-body">
                                                        {
                                                            modalImgURL !== '' ? <img src={`http://localhost:3004/${modalImgURL}`} alt="no-image" id="modalImgId" className="img-responsive" style={{width: "100%", height: "100%"}} />: ''
                                                        }
                                                    </div>            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group" id="saveAndCancleButtons">
                                        <button onClick={ ()=>setEditFormEditable(false)} hidden={cancelButtonHide} type="reset" class="btn btn-danger mt-3">Cancel</button>&nbsp;
                                        <button hidden={submitButtonHide} type="submit" class="btn btn-success mt-3 view-btn">Update</button>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>

                </div>
                <Footer></Footer>
            </div>
        </div>
    </>
    )
}
export default ViewVehicle