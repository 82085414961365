import {CUSTOMER_ADD_ADDRESS_LOADING, CUSTOMER_ADD_ADDRESS_ERROR,CUSTOMER_ADD_ADDRESS_SUCCESSFULL} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case CUSTOMER_ADD_ADDRESS_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case CUSTOMER_ADD_ADDRESS_SUCCESSFULL:{
            return Object.assign({},state,{customerAddressData: action.data, loading: false})
        }
        case CUSTOMER_ADD_ADDRESS_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}