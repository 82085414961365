import {
    ADD_VEHICLE_LEASE_COMPANY_SUCCESSFULL,
    ADD_VEHICLE_LEASE_COMPANY_ERROR,
    ADD_VEHICLE_LEASE_COMPANY_LOADING,
    FILE_UPLOAD_VEHICLE_LEASE_COMPANY_SUCCESSFULL,
    FILE_UPLOAD_VEHICLE_LEASE_COMPANY_ERROR,
    FILE_UPLOAD_VEHICLE_LEASE_COMPANY_LOADING,
    VEHICLE_LEASE_COMPANY_LIST_SUCCESSFULL,
    VEHICLE_LEASE_COMPANY_LIST_ERROR,
    VEHICLE_LEASE_COMPANY_LIST_LOADING,
    VEHICLE_LEASE_COMPANY_STATUS_CHANGE_SUCCESSFULL,
    VEHICLE_LEASE_COMPANY_STATUS_CHANGE_ERROR,
    VEHICLE_LEASE_COMPANY_STATUS_CHANGE_LOADING,
    VEHICLE_LEASE_COMPANY_DETAILS_SUCCESSFULL,
    VEHICLE_LEASE_COMPANY_DETAILS_ERROR,
    VEHICLE_LEASE_COMPANY_DETAILS_LOADING,
    VEHICLE_LEASE_COMPANY_EDIT_SUCCESSFULL,
    VEHICLE_LEASE_COMPANY_EDIT_ERROR,
    VEHICLE_LEASE_COMPANY_EDIT_LOADING
} from '../../action/type';

import * as AuthService from '../../service/services'

export const add_vehicle_lease_company=(credentail)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: ADD_VEHICLE_LEASE_COMPANY_LOADING,
                data: true
            })
            AuthService.AddVehicleLeaseCompany(credentail)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:ADD_VEHICLE_LEASE_COMPANY_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:ADD_VEHICLE_LEASE_COMPANY_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_file_upload=(credential)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: FILE_UPLOAD_VEHICLE_LEASE_COMPANY_LOADING,
                data: true
            })
            AuthService.VehicleFileUpload(credential)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:FILE_UPLOAD_VEHICLE_LEASE_COMPANY_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:FILE_UPLOAD_VEHICLE_LEASE_COMPANY_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_lease_company_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_LEASE_COMPANY_LIST_LOADING,
                data: true
            })
            AuthService.VehicleLeaseCompanyList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_LEASE_COMPANY_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_LEASE_COMPANY_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_lease_company_status_change=(vehicle_lease_company_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_LEASE_COMPANY_STATUS_CHANGE_LOADING,
                data: true
            })
            AuthService.VehicleLeaseCompanyStatusChange(vehicle_lease_company_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_LEASE_COMPANY_STATUS_CHANGE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_LEASE_COMPANY_STATUS_CHANGE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_lease_company_details=(vehicle_lease_company_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_LEASE_COMPANY_DETAILS_LOADING,
                data: true
            })
            AuthService.VehicleLeaseCompanyDetails(vehicle_lease_company_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_LEASE_COMPANY_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_LEASE_COMPANY_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const edit_vehicle_lease_company=(credential)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_LEASE_COMPANY_EDIT_LOADING,
                data: true
            })
            AuthService.EditVehicleLeaseCompany(credential)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_LEASE_COMPANY_EDIT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_LEASE_COMPANY_EDIT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}