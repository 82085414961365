import React, { useEffect, useRef, useState} from 'react'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { format } from 'date-fns'
import Footer from '../../DashBoard/Footer';
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Loader from '../../common/Loader/Loader';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/HubVehiclesAdmin/vehicle_action';
import * as action1 from '../../../action/Vehicle/vehicle_action';
import $ from 'jquery';

function Vehicle_List() {
    $.DataTable = require('datatables.net')
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const {loading} = useSelector(state => state.HubVehicleList)
    const tableRef = useRef();
    const [vehicleList,setVihicleList]=useState([]);
    const [technicianList,setTechnicianList]=useState([])
    const [hubList,setHubList]=useState([])
    const [customerList,setCustomerList]=useState([])
    const [changeData,setChangeData]=useState("")
    const [input , setInput]= useState({
        id:"",
        isActive:1,
        deactivateReason:"",
        deactivateReasonInput:""
    });
    const [inputUpdate , setInputUpdate]= useState({
        id:"",
        vehicleTechnicianId:"",
        vehicleHubId:"",
        customerId:""
    });
    const changeDateFormate = (date) => {
        if (date) {
            let new_date = String(date);
            let ndate = new_date.split('T');
            let format_date = format(new Date(ndate[0]), 'dd MMM yyyy');
            let format_time = new Date(date).toLocaleTimeString('en-US');;
            return format_date +'<br/>'+format_time;
        }
    }
    const filterVehicleList = (e) => {
        const filterStatus = e.target.value;
        dispatch(action.Vehicle_list()).then((data)=>{
            let newVehicleList = [];
            data.data.map(item => {
                let driver_details = "";
                if(item.vehicleDriverData){
                    driver_details = item.vehicleDriverData.name+'<br/>'+changeDateFormate(item.vehicleDriverData.vehicleAssignDate);
                }
                let deactivateReason = '';
                if(item.deactivateReason !== ''){
                    if(item.deactivateReason === 'At Hub'){
                        deactivateReason = <span class="badge badge-pill badge-primary mb-1">At Hub</span>;
                    } else if(item.deactivateReason === 'At Dealership For Repair') {
                        deactivateReason = <span class="badge badge-pill badge-warning mb-1">At Dealership For Repair</span>;
                    } else if(item.deactivateReason === 'Waiting For Spair Parts') {
                        deactivateReason = <span class="badge badge-pill badge-danger mb-1">At Dealership For Repair</span>;
                    } else if(item.deactivateReason === 'Insurance Claim') {
                        deactivateReason = <span class="badge badge-pill badge-info mb-1">Insurance Claim</span>;
                    } else if(item.deactivateReason === 'Under Maintenance') {
                        deactivateReason = <span class="badge badge-pill badge-danger mb-1">Under Maintenance</span>;
                    } else {
                        deactivateReason = <span class="badge badge-pill badge-secondary mb-1">{item.deactivateReasonInput}</span>;
                    }
                }
                if(filterStatus == '1'){
                    if(item.isActive == 1){
                        newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, , item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style="cursor: pointer;" href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id="${item._id}">View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                    }
                } else if(filterStatus == '0') {
                    if(item.isActive == 0){
                        newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, , item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style="cursor: pointer;" href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                    }
                } else if(filterStatus == '8') {
                    if(item.onField == 1){
                        newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, , item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style="cursor: pointer;" href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                    }
                } else if(filterStatus == '9') {
                    if(item.onField == 0){
                        newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, , item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style="cursor: pointer;" href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                    }
                // } else if(filterStatus == '10') {
                //     if(item.isRepaired == 0){
                //         newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, , item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style="cursor: pointer;" href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                //     }
                // } else if(filterStatus == '11') {
                //     if(item.isRepaired == 1){
                //         newVehicleList.push([, item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style="cursor: pointer;" href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                //     }
                // } else if(filterStatus == '12') {
                //     if(item.isRepaired == 2){
                //         newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, , item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style="cursor: pointer;" href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                //     }
                } else if(filterStatus == '2') {
                    newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, , item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style="cursor: pointer;" href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                    
                // } else if(filterStatus == '3') {
                //     if(item.deactivateReason == 'At Hub'){
                //         newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, , item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style="cursor: pointer;" href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                //     }
                // } else if(filterStatus == '4') {
                //     if(item.deactivateReason == 'At Dealership For Repair'){
                //         newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, , item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style="cursor: pointer;" href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                //     }
                // } else if(filterStatus == '5') {
                //     if(item.deactivateReason == 'Waiting For Spair Parts'){
                //         newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, , item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style="cursor: pointer;" href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                //     }
                // } else if(filterStatus == '6') {
                //     if(item.deactivateReason == 'Insurance Claim'){
                //         newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, , item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style={{cursor: "pointer"}} href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                //     }
                } else if(filterStatus == '13') {
                    if(item.deactivateReason == 'Under Maintenance'){
                        newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style={{cursor: "pointer"}} href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                    }
                // } else if(filterStatus == '7') {
                //     if(item.deactivateReason == 'Others'){
                //         newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:null, , item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id,<a style={{cursor: "pointer"}} href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
                //     }
                }
            })
            setVihicleList(newVehicleList)
        }).catch({}) 
    }
    const parkedOnfieldAlertPopup = (vehicle) => {
        dispatch(action.vehicle_parked_change(vehicle[6])).then((data)=>{
            toast.success(data.msg);
            let newVehicleList = [];
            vehicleList.map(item => {
                if(item[6] === vehicle[6]){
                    let vihicle_data = data.data;
                    let deactivateReason = '';
                    if(vihicle_data.deactivateReason !== ''){
                        if(vihicle_data.deactivateReason === 'At Hub'){
                            deactivateReason = <span class="badge badge-pill badge-primary">At Hub</span>;
                        } else if(vihicle_data.deactivateReason === 'At Dealership For Repair') {
                            deactivateReason = <span class="badge badge-pill badge-warning">At Dealership For Repair</span>;
                        } else if(vihicle_data.deactivateReason === 'Waiting For Spair Parts') {
                            deactivateReason = <span class="badge badge-pill badge-danger">At Dealership For Repair</span>;
                        } else if(vihicle_data.deactivateReason === 'Insurance Claim') {
                            deactivateReason = <span class="badge badge-pill badge-info">Insurance Claim</span>;
                        } else if(vihicle_data.deactivateReason === 'Under Maintenance') {
                            deactivateReason = <span class="badge badge-pill badge-danger">Under Maintenance</span>;
                        } else {
                            deactivateReason = <span class="badge badge-pill badge-secondary">{vihicle_data.deactivateReasonInput}</span>;
                        }
                    }
                    newVehicleList.push([vihicle_data.hasOwnProperty("sr_number") ? vihicle_data.sr_number:null, vihicle_data.registered === 1 ? vihicle_data.number: vihicle_data.name ? vihicle_data.name:null, deactivateReason, vihicle_data.isActive, vihicle_data.onField ? vihicle_data.onField:0, vihicle_data.isRepaired ? vihicle_data.isRepaired : vihicle_data.isRepaired == 0 ? 0:2,vihicle_data._id, <a style={{cursor: "pointer"}} href={`/adminside/vehicle/hub/view/${vihicle_data._id}`} class="btn btn-info btn-sm view-btn view-act-btn">View</a>, vihicle_data._id, vihicle_data, vihicle_data.deactivateReason ? vihicle_data.deactivateReason:'']);
                } else {
                    newVehicleList.push(item);
                }
            })
            setVihicleList(newVehicleList)
        }).catch(error=>{
            toast.error(error);
        })
    }
    const activeDeactiveAlertPopup = (vehicle) => {
        if(vehicle[3] === 0){
            dispatch(action.vehicle_status_change(vehicle[6])).then((data)=>{
                toast.success(data.msg);
                let newVehicleList = [];
                vehicleList.map(item => {
                    if(item[6] === vehicle[6]){
                        let vihicle_data = data.data;
                        let deactivateReason = '';
                        if(vihicle_data.deactivateReason !== ''){
                            if(vihicle_data.deactivateReason === 'At Hub'){
                                deactivateReason = <span class="badge badge-pill badge-primary mb-1">At Hub</span>;
                            } else if(vihicle_data.deactivateReason === 'At Dealership For Repair') {
                                deactivateReason = <span class="badge badge-pill badge-warning mb-1">At Dealership For Repair</span>;
                            } else if(vihicle_data.deactivateReason === 'Waiting For Spair Parts') {
                                deactivateReason = <span class="badge badge-pill badge-danger mb-1">At Dealership For Repair</span>;
                            } else if(vihicle_data.deactivateReason === 'Insurance Claim') {
                                deactivateReason = <span class="badge badge-pill badge-info mb-1">Insurance Claim</span>;
                            } else if(vihicle_data.deactivateReason === 'Under Maintenance') {
                                deactivateReason = <span class="badge badge-pill badge-danger mb-1">Under Maintenance</span>;
                            } else {
                                deactivateReason = <span class="badge badge-pill badge-secondary mb-1">{vihicle_data.deactivateReasonInput}</span>;
                            }
                        }
                        newVehicleList.push([vihicle_data.hasOwnProperty("sr_number") ? vihicle_data.sr_number:null, vihicle_data.registered === 1 ? vihicle_data.number: vihicle_data.name ? vihicle_data.name:null, deactivateReason, vihicle_data.isActive, vihicle_data.onField ? vihicle_data.onField:0, vihicle_data.isRepaired ? vihicle_data.isRepaired : vihicle_data.isRepaired == 0 ? 0:2, vihicle_data._id,<a style={{cursor: "pointer"}} href={`/adminside/vehicle/hub/view/${vihicle_data._id}`} class="btn btn-info btn-sm view-btn view-act-btn">View</a>, vihicle_data._id, vihicle_data, vihicle_data, vihicle_data.deactivateReason ? vihicle_data.deactivateReason:'']);
                    } else {
                        newVehicleList.push(item);
                    }
                })
                setVihicleList(newVehicleList)
            }).catch(error=>{
                toast.error(error);
            })
        } else {
            dispatch(action1.vehicle_details(vehicle[6])).then((data)=>{
                let vehicle_details = data.data;
                setInput({
                    id:vehicle_details._id,
                    isActive:0,
                    deactivateReason:vehicle_details.deactivateReason,
                    deactivateReasonInput:vehicle_details.deactivateReasonInput
                });
            }).catch({})
            $("#deactivateVehicleReason").click();
        }
    }
    const setUpdateTechnician = (vehicle) => {
        let vehicle_details = vehicle[9];
        setInputUpdate({
            id:vehicle_details._id,
            vehicleTechnicianId: vehicle_details.vehicleTechnicianId
        });
    }
    const setUpdateHub = (vehicle) => {
        let vehicle_details = vehicle[9];
        console.log(vehicle_details)
        setInputUpdate({
            id:vehicle_details._id,
            vehicleHubId: vehicle_details.vehicleHubId
        });
    }
    const setUpdateCustomer = (vehicle) => {
        let vehicle_details = vehicle[9];
        console.log(vehicle_details)
        setInputUpdate({
            id:vehicle_details._id,
            customerId: vehicle_details.customerId
        });
    }
    useEffect(() => {
        dispatch(action.Vehicle_technician_list()).then((data)=>{
            setTechnicianList(data.data);
        }).catch({})
        dispatch(action.vehicle_hub_list_select()).then((data)=>{
            setHubList(data.data);
        }).catch({})
        dispatch(action.customer_list()).then((data)=>{
            setCustomerList(data.data);
        }).catch({})
    },[])
    const tableName = "vehicle-list";
    useEffect(() => {
        dispatch(action.Vehicle_list()).then((data)=>{
            let newVehicleList = [];
            data.data.map(item => {
                let driver_details = "";
                if(item.vehicleDriverData){
                    driver_details = item.vehicleDriverData.name+'<br/>'+changeDateFormate(item.vehicleDriverData.vehicleAssignDate);
                }
                let deactivateReason = '';
                if(item.deactivateReason !== ''){
                    if(item.deactivateReason === 'At Hub'){
                        deactivateReason = <span class="badge badge-pill badge-primary mb-1">At Hub</span>;
                    } else if(item.deactivateReason === 'At Dealership For Repair') {
                        deactivateReason = <span class="badge badge-pill badge-warning mb-1">At Dealership For Repair</span>;
                    } else if(item.deactivateReason === 'Waiting For Spair Parts') {
                        deactivateReason = <span class="badge badge-pill badge-danger mb-1">At Dealership For Repair</span>;
                    } else if(item.deactivateReason === 'Insurance Claim') {
                        deactivateReason = <span class="badge badge-pill badge-info mb-1">Insurance Claim</span>;
                    } else if(item.deactivateReason === 'Under Maintenance') {
                        deactivateReason = <span class="badge badge-pill badge-danger mb-1">Under Maintanance</span>;
                    } else {
                        deactivateReason = <span class="badge badge-pill badge-secondary mb-1">{item.deactivateReasonInput}</span>;
                    }
                }
                newVehicleList.push([item.hasOwnProperty("sr_number") ? item.sr_number:"", item.registered === 1 ? item.number: item.name ? item.name:null, deactivateReason, item.isActive, item.onField ? item.onField : 0, item.isRepaired ? item.isRepaired : item.isRepaired == 0 ? 0:2, item._id, <a style={{cursor: "pointer"}} href={`/adminside/vehicle/hub/view/${item._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={item._id}>View</a>, item._id, item, item.deactivateReason ? item.deactivateReason:'']);
            })
            setVihicleList(newVehicleList)
        }).catch({})
    },[changeData])
    useEffect(()=>{
        console.log(vehicleList)
        const table = $(`#${tableName}`).DataTable(
            {
                dom: 'Bfrtip',
                buttons: [
                    'copyHtml5', 'excelHtml5', 'pdfHtml5', 'csvHtml5'
                ],
                data: vehicleList,
                columns: [
                    { title: "Number"},
                    { title: "Reg. Number"},
                    { title: "Status"},
                    { title: "Customer Name"},
                    { title: "Change Hub"},
                    { title: "Assign Technician"},
                    { title: "Change Customer"},
                    { title: "Change Status to"},
                    { title: "View"}
                ],
                columnDefs: [
                    {
                        targets: [2],
                        className: 'w-25 center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <>
                                {rowData[4] === 1 ? <span className="badge badge-pill badge-success status-act-btn mb-1">On Field</span> : rowData[5] === 2 ? <><span className="badge badge-pill badge-danger status-act-btn mb-1">Parked</span></> :''}
                                {rowData[10] === 'Under Maintenance' ? rowData[2] : ''}
                                <br/>
                                {rowData[5] === 1 ? <span className="badge badge-pill badge-warning status-act-btn"> Working on</span> : rowData[5] === 0 ? <span className="badge badge-pill badge-danger status-act-btn"> Assigned</span>:rowData[10] === 'Under Maintenance' ? <span className="badge badge-pill badge-danger status-act-btn"> Repaired</span> : ""}
                            </>
                            ,td
                        )
                    },
                    {
                        targets: [3],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <>
                                { 
                                    rowData[9].vehicleCustomerData ? rowData[9].vehicleCustomerData.name :''
                                }
                            </>,td
                        )
                    },
                    {
                        targets: [4],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            rowData[5] === 1 || rowData[5] !== 2 ? <button type="button" class="btn btn-primary btn-sm view-act-btn" disabled>Change Hub</button> : <a style={{cursor: "pointer"}} data-toggle="modal" onClick={() => setUpdateHub(rowData)} data-target="#updateHubModalCenter" class="btn btn-primary btn-sm view-act-btn">Change Hub</a>,td
                        )
                    },
                    {
                        targets: [5],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            rowData[5] === 1 || rowData[5] !== 2 ? <button type="button" class="btn btn-primary btn-sm view-act-btn" disabled>Assign Technician</button> : <a style={{cursor: "pointer"}} data-toggle="modal" onClick={() => setUpdateTechnician(rowData)} data-target="#updateTechnicianModalCenter" class="btn btn-primary btn-sm view-act-btn">Assign Technician</a>,td
                        )
                    },
                    {
                        targets: [6],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            rowData[5] === 1 || rowData[5] !== 2 ? <button type="button" class="btn btn-primary btn-sm view-act-btn" disabled>Change Customer</button> : <a style={{cursor: "pointer"}} data-toggle="modal" onClick={() => setUpdateCustomer(rowData)} data-target="#updateCustomerModalCenter" class="btn btn-primary btn-sm view-act-btn">Change Customer</a>,td
                        )
                    },
                    {
                        targets: [7],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <a style={{cursor: "pointer"}} className={rowData[3] === 1 ? 'btn btn-danger btn-sm status-act-btn' : 'btn btn-success btn-sm status-act-btn'} onClick={() => activeDeactiveAlertPopup(rowData)}> {rowData[3] === 1 ? 'Deactive' : 'Active'} </a>,td
                        )
                    },
                    {
                        targets: [8],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <>{rowData[7]}</>,td
                        )
                    },
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                responsive: true,
                oLanguage: {
                    oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                    // sInfo: "Showing page _PAGE_ of _PAGES_",
                    sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                    sSearchPlaceholder: "Search...",
                    sLengthMenu: "Results :  _MENU_",
                },
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[vehicleList])
    const onUpdateHandler = (e) => {
        e.preventDefault()
        dispatch(action.vehicle_update_deactive_reason(input)).then((data)=>{
            toast.success(data.msg);
            setChangeData(1);
            $("#deactivateVehicleReasonModalCenter .close").click();
        }).catch(error=>{
            toast.error(error);
        })
    }
    const onUpdateTechnicianHandler = (e) => {
        e.preventDefault()
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Assigned!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(action.vehicle_update_technician({id : inputUpdate.id,vehicleTechnicianId : inputUpdate.vehicleTechnicianId})).then((data)=>{
                    toast.success(data.msg);
                    setChangeData(2);
                    $("#updateTechnicianModalCenter .close").click();
                }).catch(error=>{
                    toast.error(error);
                })
            }
        })
    }
    const onUpdateHubHandler = (e) => {
        e.preventDefault()
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Changed!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(action.vehicle_update_hub({id : inputUpdate.id,vehicleHubId : inputUpdate.vehicleHubId})).then((data)=>{
                    toast.success(data.msg);
                    setChangeData(3);
                    $("#updateHubModalCenter .close").click();
                }).catch(error=>{
                    toast.error(error);
                })
            }
        })
    }
    const onUpdateCustomerHandler = (e) => {
        e.preventDefault()
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Changed!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(action.vehicle_update_customer({id : inputUpdate.id,customerId : inputUpdate.customerId})).then((data)=>{
                    toast.success(data.msg);
                    let newVehicleList = [];
                    vehicleList.map(item => {
                        if(item[6] === inputUpdate.id){
                            let vihicle_data = data.data;
                            let deactivateReason = '';
                            if(vihicle_data.deactivateReason !== ''){
                                if(vihicle_data.deactivateReason === 'At Hub'){
                                    deactivateReason = <span class="badge badge-pill badge-primary">At Hub</span>;
                                } else if(vihicle_data.deactivateReason === 'At Dealership For Repair') {
                                    deactivateReason = <span class="badge badge-pill badge-warning">At Dealership For Repair</span>;
                                } else if(vihicle_data.deactivateReason === 'Waiting For Spair Parts') {
                                    deactivateReason = <span class="badge badge-pill badge-danger">At Dealership For Repair</span>;
                                } else if(vihicle_data.deactivateReason === 'Insurance Claim') {
                                    deactivateReason = <span class="badge badge-pill badge-info">Insurance Claim</span>;
                                } else if(vihicle_data.deactivateReason === 'Under Maintenance') {
                                    deactivateReason = <span class="badge badge-pill badge-danger">Under Maintenance</span>;
                                } else {
                                    deactivateReason = <span class="badge badge-pill badge-secondary">{vihicle_data.deactivateReasonInput}</span>;
                                }
                            }
                            newVehicleList.push([vihicle_data.hasOwnProperty("sr_number") ? vihicle_data.sr_number:"", vihicle_data.registered === 1 ? vihicle_data.number: vihicle_data.name ? vihicle_data.name:null, deactivateReason, vihicle_data.isActive, vihicle_data.onField ? vihicle_data.onField : 0, vihicle_data.isRepaired ? vihicle_data.isRepaired : vihicle_data.isRepaired == 0 ? 0:2, vihicle_data._id, <a style={{cursor: "pointer"}} href={`/adminside/vehicle/hub/view/${vihicle_data._id}`} class="btn btn-info btn-sm view-btn view-act-btn" data-id={vihicle_data._id}>View</a>, vihicle_data._id, vihicle_data, vihicle_data.deactivateReason ? vihicle_data.deactivateReason:'']);
                        } else {
                            newVehicleList.push(item);
                        }
                    })
                    setVihicleList(newVehicleList)
                    $("#updateCustomerModalCenter .close").click();
                }).catch(error=>{
                    toast.error(error);
                })
            }
        })
    }
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover  theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                <div className="layout-px-spacing">

                    <div className="row layout-top-spacing">

                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                            <div className="widget-content widget-content-area br-6">
                                <h5 className="" style={{margin: '10px'}}>Hub Vehicle List</h5>
                                <div className="form-group p-2 list-filter" id="list-filter-id">
                                    <label for="inputDriverStatusSelect" className="ml-1">Status</label>
                                    <select name="inputDriverStatusSelect" id="inputDriverStatusSelect"
                                        className="form-control selectpicker ml-2" onChange={(e) => filterVehicleList(e)} style={{width: '220px'}}>
                                        <option value="1">Active</option>
                                        <option value="0">Deactive</option>
                                        <option value="8">On Field</option>
                                        <option value="9">Parked</option>
                                        {/* <option value="10">Not Repaired</option>
                                        <option value="11">Repair Start</option>
                                        <option value="12">Repair Completed</option>
                                        <option value="3">At Hub</option>
                                        <option value="4">At Dealership For Repair</option>
                                        <option value="5">Waiting For Spair Parts</option>
                                        <option value="6">Insurance Claim</option> */}
                                        <option value="13">Under Maintenance</option>
                                        {/* <option value="7">Others</option> */}
                                        <option selected value="2">All</option>
                                    </select>
                                </div>
                                <div className="table-responsive mb-4 mt-4">
                                    <table id="vehicle-list" className="table table-hover" style={{width: '100%'}} >
                                        <thead>
                                            <tr>
                                                <th>Sr Number</th>
                                                <th>Vehicle Number</th>
                                                <th>Vehicle Status</th>
                                                <th>Customer Name</th>
                                                <th>Change Hub</th>
                                                <th>Assign Technician</th>
                                                <th>Change Customer</th>
                                                <th>Active Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <button hidden id="deactivateVehicleReason" type="button" class="btn btn-warning btn-sm add-act-btn" data-toggle="modal" data-target="#deactivateVehicleReasonModalCenter"></button>
                        <div className="modal fade" id="deactivateVehicleReasonModalCenter" role="dialog"
                            aria-labelledby="assignCustomerModalCenterTitle" style={{ display: 'none'}}
                            aria-hidden="true">
                            <div className="modal-dialog modal-md" role="document">
                                <div className="modal-content">
                                    <form onSubmit={onUpdateHandler}>           
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="assignCustomerModalCenterTitle">Deactivate Reason</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    className="feather feather-x">
                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="customer-list-class" id="customer-list-div-id">
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <div className="form-group required" id="vehicleStatusDeactiveMain">
                                                            <label>Vehicle Deactivate Reason *</label>
                                                            <div class="form-check">
                                                                {
                                                                    input.deactivateReason === "At Hub" ? <input class="form-check-input" type="radio" name="flexRadioDefault" value="At Hub" onClick={(e)=>setInput({...input ,  deactivateReason:"At Hub"})} id="flexRadioDefault1" checked/> : <input class="form-check-input" type="radio" name="flexRadioDefault" value="At Hub" onClick={(e)=>setInput({...input ,  deactivateReason:"At Hub"})} id="flexRadioDefault1"/>
                                                                }
                                                                <label class="form-check-label" for="flexRadioDefault1">
                                                                    At Hub
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                {
                                                                    input.deactivateReason === "At Dealership For Repair" ? <input class="form-check-input" type="radio" name="flexRadioDefault" value="At Dealership For Repair" id="flexRadioDefault2" onClick={(e)=>setInput({...input ,  deactivateReason:"At Dealership For Repair"})} checked/> : <input class="form-check-input" type="radio" name="flexRadioDefault" value="At Dealership For Repair" id="flexRadioDefault2" onClick={(e)=>setInput({...input ,  deactivateReason:"At Dealership For Repair"})}/>
                                                                }
                                                                <label class="form-check-label" for="flexRadioDefault2">
                                                                    At Dealership For Repair
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                {
                                                                    input.deactivateReason === "Waiting For Spair Parts" ? <input class="form-check-input" type="radio" name="flexRadioDefault" value="Waiting For Spair Parts" id="flexRadioDefault3" onClick={(e)=>setInput({...input ,  deactivateReason:"Waiting For Spair Parts"})} checked/> : <input class="form-check-input" type="radio" name="flexRadioDefault" value="Waiting For Spair Parts" id="flexRadioDefault3" onClick={(e)=>setInput({...input ,  deactivateReason:"Waiting For Spair Parts"})}/>
                                                                }
                                                                <label class="form-check-label" for="flexRadioDefault3">
                                                                    Waiting For Spair Parts
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                {
                                                                    input.deactivateReason === "Insurance Claim" ? <input class="form-check-input" type="radio" name="flexRadioDefault" value="Insurance Claim" id="flexRadioDefault4" onClick={(e)=>setInput({...input ,  deactivateReason:"Insurance Claim"})} checked/> : <input class="form-check-input" type="radio" name="flexRadioDefault" value="Insurance Claim" id="flexRadioDefault4" onClick={(e)=>setInput({...input ,  deactivateReason:"Insurance Claim"})}/>
                                                                }
                                                                <label class="form-check-label" for="flexRadioDefault4">
                                                                    Insurance Claim
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                {
                                                                    input.deactivateReason === "Under Maintenance" ? <input class="form-check-input" type="radio" name="flexRadioDefault" value="Under Maintenance" id="flexRadioDefault4" onClick={(e)=>setInput({...input ,  deactivateReason:"Under Maintenance"})} checked/> : <input class="form-check-input" type="radio" name="flexRadioDefault" value="Under Maintenance" id="flexRadioDefault4" onClick={(e)=>setInput({...input ,  deactivateReason:"Under Maintenance"})}/>
                                                                }
                                                                <label class="form-check-label" for="flexRadioDefault4">
                                                                    Under Maintenance
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                {
                                                                    input.deactivateReason === "Others" ? <input class="form-check-input" type="radio" name="flexRadioDefault" value="Others" id="flexRadioDefault4" onClick={(e)=>setInput({...input ,  deactivateReason:"Others"})} checked/> : <input class="form-check-input" type="radio" name="flexRadioDefault" value="Others" id="flexRadioDefault4" onClick={(e)=>setInput({...input ,  deactivateReason:"Others"})}/>
                                                                }
                                                                <label class="form-check-label" for="flexRadioDefault4">
                                                                    Others
                                                                </label>
                                                            </div>
                                                            {
                                                                input.deactivateReason === 'Others' ? <div class="form-check">
                                                                    <input class="form-control col-md-12" type="text" name="deactivateReasonInput"  id="deactivateReasonInput" onChange={(e)=>setInput({...input ,  deactivateReasonInput:e.target.value})} value={input.deactivateReasonInput}/><br/>
                                                                </div> :''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn close" data-dismiss="modal"><i className="flaticon-cancel-12"></i>Close</button>
                                            <button type="submit" id="assignCustomerToDriverBtn" className="btn btn-primary view-btn" >Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="updateTechnicianModalCenter" role="dialog"
                            aria-labelledby="updateTechnicianModalCenterTitle" style={{ display: 'none'}}
                            aria-hidden="true">
                            <div className="modal-dialog modal-sm" role="document">
                                <div className="modal-content">
                                    <form onSubmit={onUpdateTechnicianHandler}>           
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="updateTechnicianModalCenterTitle">Assign Technician</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    className="feather feather-x">
                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="customer-list-class" id="customer-list-div-id">
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputVehicleHub">Select Technicion *</label>
                                                        <select id="inputVehicleHub" name="type" className="form-control selectpicker" required onChange={(e)=>setInputUpdate({...inputUpdate ,  vehicleTechnicianId:e.target.value})} value={inputUpdate.vehicleTechnicianId}>
                                                            <option value="">Select Technicion</option>
                                                            {
                                                                technicianList.map((technician)=>(
                                                                    <>
                                                                        <option value={technician._id}>{technician.name}</option>
                                                                    </>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn close" data-dismiss="modal"><i className="flaticon-cancel-12"></i>Close</button>
                                            <button type="submit" id="assignCustomerToDriverBtn" className="btn btn-primary view-btn" >Assign</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="updateHubModalCenter" role="dialog"
                            aria-labelledby="updateHubModalCenterTitle" style={{ display: 'none'}}
                            aria-hidden="true">
                            <div className="modal-dialog modal-sm" role="document">
                                <div className="modal-content">
                                    <form onSubmit={onUpdateHubHandler}>           
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="updateHubModalCenterTitle">Change Hub</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    className="feather feather-x">
                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="customer-list-class" id="customer-list-div-id">
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputVehicleHub">Select Hub *</label>
                                                        <select id="inputVehicleHub" name="type" className="form-control selectpicker" required onChange={(e)=>setInputUpdate({...inputUpdate ,  vehicleHubId:e.target.value})} value={inputUpdate.vehicleHubId}>
                                                            <option value="">Select Hub</option>
                                                            {
                                                                hubList.map((hub)=>(
                                                                    <>
                                                                        <option value={hub._id}>{hub.name}</option>
                                                                    </>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn close" data-dismiss="modal"><i className="flaticon-cancel-12"></i>Close</button>
                                            <button type="submit" id="assignCustomerToDriverBtn" className="btn btn-primary view-btn" >Change</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="updateCustomerModalCenter" role="dialog"
                            aria-labelledby="updateCustomerModalCenterTitle" style={{ display: 'none'}}
                            aria-hidden="true">
                            <div className="modal-dialog modal-sm" role="document">
                                <div className="modal-content">
                                    <form onSubmit={onUpdateCustomerHandler}>           
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="updateCustomerModalCenterTitle">Change Customer</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    className="feather feather-x">
                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="customer-list-class" id="customer-list-div-id">
                                                <div className="form-group row">
                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputVehicleHub">Select Customer *</label>
                                                        <select id="inputVehicleHub" name="type" className="form-control selectpicker" required onChange={(e)=>setInputUpdate({...inputUpdate ,  customerId:e.target.value})} value={inputUpdate.customerId}>
                                                            <option value="">Select Customer</option>
                                                            {
                                                                customerList.map((customer)=>(
                                                                    <>
                                                                        <option value={customer._id}>{customer.businessName+' | '+customer.name}</option>
                                                                    </>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn close" data-dismiss="modal"><i className="flaticon-cancel-12"></i>Close</button>
                                            <button type="submit" id="assignCustomerToDriverBtn" className="btn btn-primary view-btn" >Change</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
            </div>
        </>
    )
}
export default Vehicle_List;