import {CUSTOMER_ADD_LOADING, CUSTOMER_ADD_SUCCESSFULL,CUSTOMER_ADD_ERROR} from '../../action/type';

import * as AuthService from '../../service/services'

export const add_customer=(credentail)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: CUSTOMER_ADD_LOADING,
                data: true
            })
            AuthService.AddCustomerList(credentail)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:CUSTOMER_ADD_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:CUSTOMER_ADD_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}