import React, { useEffect, useRef, useState} from 'react'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import Select from 'react-select'
import withReactContent from 'sweetalert2-react-content'
import Footer from '../../DashBoard/Footer';
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Loader from '../../common/Loader/Loader';
import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';
import { Marker, InfoWindow } from '@react-google-maps/api';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/Driver/driver_action';
import $ from 'jquery'
import { renderToString } from 'react-dom/server'
function Driver_List() {
    $.DataTable = require('datatables.net')
    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    const center = {lat: 21.170240, lng: 72.831062};
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.DriverListReducer)
    const tableRef = useRef();
    const [driverList,setDriverList]=useState([]);
    const [customerList,setCustomerList]=useState([]);
    const [customerAddressList,setCustomerAddressList]=useState([]);
    const [customerShiftList,setCustomerShiftList]=useState([]);
    const [assignDriver,setAssignDriver]=useState({
        driverName:"",
        driverId:"",
        customerId:"",
        customerAddressId:"",
        customerShiftId:"",
    });
    const [assignDriverValues,setAssignDriverValues]=useState({
        customerValue:"",
        customerAddressValue:"",
        customerShiftValue:""
    });
    const assignDriverSet = (driver, driver_id) => {
        setAssignDriver({
            driverName:driver.name,
            driverId:driver_id,
            customerId:driver.customerId ? driver.customerId:'',
            customerAddressId:driver.customerAddressId ? driver.customerAddressId:'',
            customerShiftId:driver.customerShiftId ? driver.customerShiftId:'',
        });
        setAssignDriverValues({
            customerValue: driver.customerId ? {value:driver.customerId, label: driver.driverCustomerData.businessName+' | '+driver.driverCustomerData.name } :'',
            customerAddressValue:driver.customerAddressId ? {value:driver.customerAddressId, label: driver.driverCustomerAddressData.address } :'',
            customerShiftValue:driver.customerShiftId ? {value:driver.customerShiftId, label: driver.driverCustomerShiftData.shiftName+' | '+driver.driverCustomerShiftData.shiftStartTime+' | '+driver.driverCustomerShiftData.shiftEndTime } :''
        });
        if(driver.customerId){
            dispatch(action.customer_address_list(driver.customerId)).then((data)=>{
                let newAddressList = [];
                data.data.map(item => {
                    newAddressList.push({
                        value: item._id,
                        label: item.address
                    });
                })
                setCustomerAddressList(newAddressList)
            }).catch({})
            dispatch(action.customer_shift_list(driver.customerId)).then((data)=>{
                let newShiftList = [];
                data.data.map(item => {
                    newShiftList.push({
                        value: item._id,
                        label: item.shiftName+' | '+item.shiftStartTime+' | '+item.shiftEndTime
                    });
                })
                setCustomerShiftList(newShiftList)
            }).catch({})
        } else {
            setCustomerAddressList([]);
            setCustomerShiftList([]);
        }
        dispatch(action.customer_list()).then((data)=>{
            let newCustomerList = [];
            data.data.map(item => {
                newCustomerList.push({
                    value: item._id,
                    label: item.businessName+' | '+item.name
                });
            })
            setCustomerList(newCustomerList)
        }).catch({})
    }
    const changeAddressDropDown = (data) => {
        setAssignDriver({...assignDriver, customerAddressId:data.value});
        setAssignDriverValues({...assignDriverValues, customerAddressValue:data});
    }
    const changeShiftDropDown = (data) => {
        setAssignDriver({...assignDriver, customerShiftId:data.value});
        setAssignDriverValues({...assignDriverValues, customerShiftValue:data});
    }
    const changeCustomerDropDown = (data) => {
        const customer_id = data.value;
        setAssignDriver({
            driverName:assignDriver.driverName,
            driverId:assignDriver.driverId,
            customerId:customer_id,
            customerAddressId:'',
            customerShiftId:'',
        });
        setAssignDriverValues({...assignDriverValues, customerValue:data});
        dispatch(action.customer_address_list(customer_id)).then((data)=>{
            let newAddressList = [];
            data.data.map(item => {
                newAddressList.push({
                    value: item._id,
                    label: item.address
                });
            })
            setCustomerAddressList(newAddressList)
        }).catch({})
        dispatch(action.customer_shift_list(customer_id)).then((data)=>{
            let newShiftList = [];
            data.data.map(item => {
                newShiftList.push({
                    value: item._id,
                    label: item.shiftName+' | '+item.shiftStartTime+' | '+item.shiftEndTime
                });
            })
            setCustomerShiftList(newShiftList)
        }).catch({})
    }
    const activeDeactiveAlertPopup = (driver) => {
        dispatch(action.DriverActiveDeactive(driver[8])).then((data)=>{
            toast.success(data.msg);
            let newDriverList = [];
            driverList.map(item => {
                if(item[8] === driver[8]){
                    let driver_data = data.data;
                    newDriverList.push([item[0], item[1], item[2], item[3], item[4], item[5], driver_data.isActive, item[7], item[8], item[9], item[10]]);
                } else {
                    newDriverList.push(item);
                }
            })
            setDriverList(newDriverList)
        }).catch(error=>{
            toast.error(error);
        })
    }
    const filterDriverList = (e) => {
        const filterStatus = e.target.value;
        dispatch(action.Driver_list()).then((data)=>{
            let newDriverList = [];
            data.data.map(item => {
                if(filterStatus == '1'){
                    if(item.isActive == 1){
                        let shiftData = item.hasOwnProperty('driverCustomerShiftData') ? `${item.driverCustomerShiftData.shiftName} <br> ${item.driverCustomerShiftData.shiftStartTime} <br> ${item.driverCustomerShiftData.shiftEndTime}`:null;;
                        let customer_data = item.hasOwnProperty('driverCustomerData') ? item.driverCustomerData.businessName +' | ' + item.driverCustomerData.name:null;
                        let customer_address = item.hasOwnProperty('driverCustomerAddressData') ? item.driverCustomerAddressData.address:null;
                        let vehicleData = item.hasOwnProperty('driverVehicleData') ? item.driverVehicleData.number +' | ' + item.driverVehicleData.ownerName +' | ' + item.driverVehicleData.details:null;
                        newDriverList.push(['EV'+item.Driver_Id, item.name, item.mobile, vehicleData, customer_data, customer_address, shiftData, item.isActive, item._id, item._id, item]);
                    }
                } else if(filterStatus == '0') {
                    if(item.isActive == 0){
                        let shiftData = item.hasOwnProperty('driverCustomerShiftData') ? `${item.driverCustomerShiftData.shiftName} <br> ${item.driverCustomerShiftData.shiftStartTime} <br> ${item.driverCustomerShiftData.shiftEndTime}`:null;;
                        let customer_data = item.hasOwnProperty('driverCustomerData') ? item.driverCustomerData.businessName +' | ' + item.driverCustomerData.name:null;
                        let customer_address = item.hasOwnProperty('driverCustomerAddressData') ? item.driverCustomerAddressData.address:null;
                        let vehicleData = item.hasOwnProperty('driverVehicleData') ? item.driverVehicleData.number +' | ' + item.driverVehicleData.ownerName +' | ' + item.driverVehicleData.details:null;
                        newDriverList.push(['EV'+item.Driver_Id, item.name, item.mobile, vehicleData, customer_data, customer_address, shiftData, item.isActive, item._id, item._id, item]);
                    }
                } else if(filterStatus == '2') {
                        let shiftData = item.hasOwnProperty('driverCustomerShiftData') ? `${item.driverCustomerShiftData.shiftName} <br> ${item.driverCustomerShiftData.shiftStartTime} <br> ${item.driverCustomerShiftData.shiftEndTime}`:null;;
                        let customer_data = item.hasOwnProperty('driverCustomerData') ? item.driverCustomerData.businessName +' | ' + item.driverCustomerData.name:null;
                        let customer_address = item.hasOwnProperty('driverCustomerAddressData') ? item.driverCustomerAddressData.address:null;
                        let vehicleData = item.hasOwnProperty('driverVehicleData') ? item.driverVehicleData.number +' | ' + item.driverVehicleData.ownerName +' | ' + item.driverVehicleData.details:null;
                        newDriverList.push(['EV'+item.Driver_Id, item.name, item.mobile, vehicleData, customer_data, customer_address, shiftData, item.isActive, item._id, item._id, item]);
                    
                }
            })
            setDriverList(newDriverList)
        }).catch({}) 
    }
    const DetachVehicleData = (driver_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't to detached vehicle!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, detached it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(action.driver_detached_vehicle(driver_id)).then((data)=>{
                    toast.success(data.msg);
                }).catch(error=>{
                    toast.error(error);
                })
            }
        })
    }
    const DriverLogoff = (driver_id) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Log Off!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(action.driver_logoff(driver_id)).then((data)=>{
                    toast.success(data.msg);
                }).catch(error=>{
                    toast.error(error);
                })
            }
        })
    }
    const DriverSelfieRequest = (driver_id) => {
        dispatch(action.driver_selfie_request(driver_id)).then((data)=>{
            toast.success(data.msg);
        }).catch(error=>{
            toast.error(error);
        })
    }
    useEffect(() => {
        dispatch(action.Driver_list()).then((data)=>{
            let newDriverList = [];
            data.data.map(item => {
                let shiftData = item.hasOwnProperty('driverCustomerShiftData') ? `${item.driverCustomerShiftData.shiftName} <br> ${item.driverCustomerShiftData.shiftStartTime} <br> ${item.driverCustomerShiftData.shiftEndTime}`:null;;
                let customer_data = item.hasOwnProperty('driverCustomerData') ? item.driverCustomerData.businessName +' | ' + item.driverCustomerData.name:null;
                let customer_address = item.hasOwnProperty('driverCustomerAddressData') ? item.driverCustomerAddressData.address:null;
                let vehicleData = item.hasOwnProperty('driverVehicleData') ? item.driverVehicleData.number +' | ' + item.driverVehicleData.ownerName +' | ' + item.driverVehicleData.details:null;
                newDriverList.push(['EV'+item.Driver_Id,item.name, item.mobile, vehicleData, customer_data, customer_address, shiftData, item.isActive, item._id, item._id, item]);
            })
            setDriverList(newDriverList)
        }).catch({})
    },[])
    useEffect(()=>{
        const tableName = "driver-list";
        const table = $(`#${tableName}`).DataTable({
            dom: 'Bfrtip',
            buttons: [
                'copyHtml5', 'excelHtml5', 'pdfHtml5', 'csvHtml5'
            ],
            data: driverList,
            columns: [
                { width: "10%", title: "Driver Id"},
                { width: "10%", title: "Full Name"},
                { width: "10%", title: "Mobile"},
                { width: "10%", title: "Vehicle"},
                { width: "10%", title: "Customer/Company"},
                { width: "10%", title: "Customer Address"},
                { width: "10%", title: "Shift Time"},
                { width: "10%", title: "Status"},
                { width: "30%", title: "Action"}
            ],
            columnDefs: [
                {
                    targets: [7],
                    className: 'center',
                    createdCell: (td, cellData, rowData) => ReactDOM.render(
                        <a style={{cursor: "pointer"}} className={rowData[7] === 1 ? 'btn btn-success btn-sm status-act-btn' : 'btn btn-danger btn-sm status-act-btn'} onClick={() => activeDeactiveAlertPopup(rowData)}> {rowData[7] === 1 ? 'Active' : 'Deactive'} </a>,td
                    )
                },
                {
                    targets: [8],
                    className: 'center',
                    createdCell: (td, cellData, rowData) => ReactDOM.render(
                        <>
                            {/* <a style={{cursor: "pointer", width:'100px'}} onClick={() => assignDriverSet(rowData[9], rowData[7])} className="btn btn-info btn-sm mb-2" data-toggle="modal" data-target="#assignCustomerModalCenter">Assign</a>&nbsp; */}
                            <a style={{cursor: "pointer"}} href={`/adminside/driver/view/${rowData[8]}`} className="btn btn-primary btn-sm mb-2">View</a>&nbsp;
                            <a style={{cursor: "pointer"}} href={`/adminside/driver/assign/${rowData[8]}`} className="btn btn-info btn-sm mb-2">Assign Multiple</a>&nbsp;
                            <a style={{cursor: "pointer", width:'100px'}} onClick={() => DriverLogoff(rowData[8])} className="btn btn-warning btn-sm mb-2">Log Off</a>&nbsp;
                            <a style={{cursor: "pointer"}} className="btn btn-dark btn-sm mb-2" onClick={() => DriverSelfieRequest(rowData[8])}>Selfie Request</a>&nbsp;
                            <a style={{cursor: "pointer"}} onClick={() => DetachVehicleData(rowData[8])} className="btn btn-danger btn-sm mb-2">Detach Vehicle</a>
                        </>,td
                    )
                }
            ],
            destroy: true,  // I think some clean up is happening here
            searching: true,
            oLanguage: {
                oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                //sInfo: "Showing page _PAGE_ of _PAGES_",
                sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                sSearchPlaceholder: "Search...",
                sLengthMenu: "Results :  _MENU_",
            },
        })
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[driverList])
    const onsubmitHandler = (e) => {
        e.preventDefault()
        let parameter = {
            id:assignDriver.driverId,
            customerId:assignDriver.customerId,
            customerAddressId:assignDriver.customerAddressId,
            customerShiftId:assignDriver.customerShiftId
        };
        dispatch(action.driver_assign_customer(parameter)).then((data)=>{
            toast.success(data.msg);
            dispatch(action.Driver_list()).then((data)=>{
                let newDriverList = [];
                data.data.map(item => {
                    let shiftData = item.hasOwnProperty('driverCustomerShiftData') ? `${item.driverCustomerShiftData.shiftName} <br> ${item.driverCustomerShiftData.shiftStartTime} <br> ${item.driverCustomerShiftData.shiftEndTime}`:null;;
                    let customer_data = item.hasOwnProperty('driverCustomerData') ? item.driverCustomerData.businessName +' | ' + item.driverCustomerData.name:null;
                    let customer_address = item.hasOwnProperty('driverCustomerAddressData') ? item.driverCustomerAddressData.address:null;
                    let vehicleData = item.hasOwnProperty('driverVehicleData') ? item.driverVehicleData.number +' | ' + item.driverVehicleData.ownerName +' | ' + item.driverVehicleData.details:null;
                    newDriverList.push(['EV'+item.Driver_Id, item.name, item.mobile, vehicleData, customer_data, customer_address, shiftData, item.isActive, item._id, item._id, item]);
                })
                setDriverList(newDriverList)
            }).catch({})
            $("#assignCustomerModalCenter .close").click();
            setAssignDriver({
                driverName:"",
                driverId:"",
                customerId:"",
                customerAddressId:"",
                customerShiftId:""
            });
            setAssignDriverValues({
                customerValue:"",
                customerAddressValue:"",
                customerShiftValue:""
            });
            setCustomerList([]);
            setCustomerShiftList([]);
            setCustomerShiftList([]);
        }).catch(error=>{
            toast.error(error);
        })
    }
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover  theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing">
                        <div className="row layout-top-spacing">

                            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                <div className="widget-content widget-content-area br-6">
                                    <ul className="nav nav-tabs  mb-3 mt-3" id="simpletab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="list-tab" data-toggle="tab" href="#list"
                                                role="tab" aria-controls="list" aria-selected="true">List</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="tracking-tab" data-toggle="tab" href="#tracking"
                                                role="tab" aria-controls="tracking" aria-selected="false">Tracking</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="simpletabContent">
                                        <div className="tab-pane fade show active" id="list" role="tabpanel"
                                            aria-labelledby="list-tab">
                                            <div className="form-group p-2 list-filter" id="list-filter-id">
                                                <label for="inputDriverStatusSelect" className="ml-1">Status</label>
                                                <select name="inputDriverStatusSelect" id="inputDriverStatusSelect"
                                                    className="form-control selectpicker ml-2" onChange={(e) => filterDriverList(e)} style={{width: '120px'}}>
                                                    <option value="1">Active</option>
                                                    <option value="0">Deactive</option>
                                                    <option selected value="2">All</option>
                                                </select>
                                            </div>
                                            <div className="table-responsive mb-4 mt-4">
                                                <table id="driver-list" className="table table-hover" style={{width:"100%"}}>
                                                    {/* <thead>
                                                        <tr>
                                                            <th>Full Name</th>
                                                            <th>Mobile</th>
                                                            <th>Vehicle</th>
                                                            <th>Customer/Company</th>
                                                            <th>Customer Address</th>
                                                            <th>Shift Time</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    
                                                    </tbody> */}
                                                </table>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade show" id="tracking" role="tabpanel"
                                            aria-labelledby="tracking-tab">
                                            <div className="row">
                                                <div className="form-group col-sm-12">
                                                    {/* <div className="map map-600" id="map"></div> */}
                                                    <LoadScriptNext googleMapsApiKey="AIzaSyB6PMCguHWiTCN0nLcEasXVxUmWOpHS5gY">
                                                    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={11}>
                                                        <Marker visible={false} zIndex={1111} position={center}></Marker>
                                                    </GoogleMap>
                                                </LoadScriptNext>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="assignCustomerModalCenter" role="dialog"
                                aria-labelledby="assignCustomerModalCenterTitle" style={{ display: 'none'}}
                                aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <form onSubmit={onsubmitHandler}>           
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="assignCustomerModalCenterTitle">Assign
                                                    Customer/Company</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        className="feather feather-x">
                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="customer-list-class" id="customer-list-div-id">
                                                    <div className="form-group col-12 required">
                                                        <label for="inputDriverFullName">Driver: </label>
                                                        <label id="inputDriverFullName">{assignDriver.driverName !== '' ? assignDriver.driverName : 'test driver'}</label>
                                                    </div>

                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputCustomerSelect">Select Customer/Company *</label>
                                                        <div className="form-group">
                                                            <Select value={assignDriverValues.customerValue || 'Select'} onChange={value => changeCustomerDropDown(value)} options={customerList} required/>
                                                        </div>
                                                    </div>

                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputCustomerAddressSelect">Select Address *</label>
                                                        <div className="form-group">
                                                            <Select value={assignDriverValues.customerAddressValue || 'Select'} onChange={changeAddressDropDown}  options={customerAddressList} required/>
                                                        </div>
                                                    </div>

                                                    <div className="form-group col-md-12 required">
                                                        <label for="inputCustomerShiftSelect">Select Shift *</label>
                                                        <div className="form-group">
                                                            <Select value={assignDriverValues.customerShiftValue || 'Select'} onChange={changeShiftDropDown} options={customerShiftList} required/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn" data-dismiss="modal"><i className="flaticon-cancel-12"></i>Close</button>
                                                <button type="submit" id="assignCustomerToDriverBtn" className="btn btn-primary view-btn" >Assign</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}
export default Driver_List