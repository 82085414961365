import {
    VIHICLE_LIST_SUCCESSFULL, 
    VIHICLE_LIST_ERROR,
    VIHICLE_LIST_LOADING,
    VIHICLE_STATUS_CHANGE_SUCCESSFULL,
    VIHICLE_STATUS_CHANGE_ERROR,
    VIHICLE_STATUS_CHANGE_LOADING,
    VIHICLE_ADD_SUCCESSFULL,
    VIHICLE_ADD_ERROR,
    VIHICLE_ADD_LOADING,
    VIHICLE_DELETE_FILE_SUCCESSFULL,
    VIHICLE_DELETE_FILE_ERROR,
    VIHICLE_DELETE_FILE_LOADING,
    VIHICLE_DETAILS_SUCCESSFULL,
    VIHICLE_DETAILS_ERROR,
    VIHICLE_DETAILS_LOADING,
    VIHICLE_EDIT_SUCCESSFULL,
    VIHICLE_EDIT_ERROR,
    VIHICLE_EDIT_LOADING,
    VIHICLE_LEASE_COMPANY_LIST_SELECT_SUCCESSFULL,
    VIHICLE_LEASE_COMPANY_LIST_SELECT_ERROR,
    VIHICLE_LEASE_COMPANY_LIST_SELECT_LOADING,
    VIHICLE_TELEMATICS_UNIT_LIST_SELECT_SUCCESSFULL,
    VIHICLE_TELEMATICS_UNIT_LIST_SELECT_ERROR,
    VIHICLE_TELEMATICS_UNIT_LIST_SELECT_LOADING,
    VIHICLE_HUB_LIST_SELECT_SUCCESSFULL,
    VIHICLE_HUB_LIST_SELECT_ERROR,
    VIHICLE_HUB_LIST_SELECT_LOADING,
    BIKE_TYPE_LIST_SELECT_SUCCESSFULL,
    BIKE_TYPE_LIST_SELECT_ERROR,
    BIKE_TYPE_LIST_SELECT_LOADING,
    BIKE_TYPE_DETAILS_SUCCESSFULL,
    BIKE_TYPE_DETAILS_ERROR,
    BIKE_TYPE_DETAILS_LOADING,
    VEHICLE_LEASE_COMPANY_DETAILS_LOADING,
    VEHICLE_LEASE_COMPANY_DETAILS_SUCCESSFULL,
    VEHICLE_LEASE_COMPANY_DETAILS_ERROR,
    DRIVER_LIST_VEHICLE_USE_SUCCESSFULL,
    DRIVER_LIST_VEHICLE_USE_ERROR,
    DRIVER_LIST_VEHICLE_USE_LOADING
} from '../../action/type';

import * as AuthService from '../../service/services'

export const Vehicle_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_LIST_LOADING,
                data: true
            })
            AuthService.VehicleList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const driver_use_vehicle_list=(start_date, end_date, vehicle_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_LIST_LOADING,
                data: true
            })
            AuthService.AllVehicleWorkList(start_date, end_date, vehicle_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_status_change=(vehicle_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_STATUS_CHANGE_LOADING,
                data: true
            })
            AuthService.VehicleStatusChange(vehicle_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_STATUS_CHANGE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_STATUS_CHANGE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_file_upload=(credential)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_STATUS_CHANGE_LOADING,
                data: true
            })
            AuthService.VehicleFileUpload(credential)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_STATUS_CHANGE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_STATUS_CHANGE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const add_vehicle=(credential)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_ADD_LOADING,
                data: true
            })
            AuthService.AddVehicle(credential)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_ADD_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_ADD_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const delete_vehicle_file=(credential)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_DELETE_FILE_LOADING,
                data: true
            })
            AuthService.DeleteVehicleFile(credential)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_DELETE_FILE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_DELETE_FILE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_details=(vehicle_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_DETAILS_LOADING,
                data: true
            })
            AuthService.VehicleDetails(vehicle_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const edit_vehicle=(credential)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_EDIT_LOADING,
                data: true
            })
            AuthService.EditVehicle(credential)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_EDIT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_EDIT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_lease_company_list_select=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_LEASE_COMPANY_LIST_SELECT_LOADING,
                data: true
            })
            AuthService.VehicleLeaseCompanyListSelect()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_LEASE_COMPANY_LIST_SELECT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_LEASE_COMPANY_LIST_SELECT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_lease_company_details=(vehicle_lease_company_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_LEASE_COMPANY_DETAILS_LOADING,
                data: true
            })
            AuthService.VehicleLeaseCompanyDetails(vehicle_lease_company_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_LEASE_COMPANY_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_LEASE_COMPANY_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_telematics_unit_list_select=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_TELEMATICS_UNIT_LIST_SELECT_LOADING,
                data: true
            })
            AuthService.VehicleTelematicsUnitListSelect()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_TELEMATICS_UNIT_LIST_SELECT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_TELEMATICS_UNIT_LIST_SELECT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_hub_list_select=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_HUB_LIST_SELECT_LOADING,
                data: true
            })
            AuthService.VehicleHubsListSelect()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_HUB_LIST_SELECT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_HUB_LIST_SELECT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_bike_type_select=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: BIKE_TYPE_LIST_SELECT_LOADING,
                data: true
            })
            AuthService.GetBikeTypeListSelect()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:BIKE_TYPE_LIST_SELECT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:BIKE_TYPE_LIST_SELECT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const bike_type_details=(bike_type_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: BIKE_TYPE_DETAILS_LOADING,
                data: true
            })
            AuthService.BikeTypeDetails(bike_type_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:BIKE_TYPE_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:BIKE_TYPE_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}