import React, { useEffect, useRef, useState} from 'react'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Footer from '../../DashBoard/Footer';
import Loader from '../../common/Loader/Loader';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/Admin/admin_action';
import $ from 'jquery'
import { renderToString } from 'react-dom/server';

const Admin_List = props => {
    $.DataTable = require('datatables.net');
    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.AdminListReducer)
    const tableRef = useRef();
    const [adminList,setAdminList]=useState([]);
    const tableName = "admin-list";
    useEffect(() => {
        dispatch(action.admin_list()).then((data)=>{
            let newAdminList = [];
            data.AdminList.map(item => {
                newAdminList.push([item.name, item.email, `<a style="cursor: pointer;" href="/adminside/admin/view/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn">View</a>`]);
            })
            setAdminList(newAdminList)
        }).catch({})
    },[])
    useEffect(()=>{
        const table = $(`#${tableName}`).DataTable(
            {
                data: adminList,
                columns: [
                    { title: "Full Name"},
                    { title: "Email"},
                    { title: "Action"}
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                buttons: [{
                    extend: 'excel',
                    className: 'excelButton view-btn btn text-white float-right mt-2 ml-2',
                }],
                oLanguage: {
                    oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                    //sInfo: "Showing page _PAGE_ of _PAGES_",
                    sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                    sSearchPlaceholder: "Search...",
                    sLengthMenu: "Results :  _MENU_",
                },
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[adminList])
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing">
                        <div className="row layout-top-spacing">

                            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                <div className="widget-content widget-content-area br-6">
                                    <h5 className="" style={{margin: '10px'}}>Admin List</h5>
                                    <div className="table-responsive mb-4 mt-4">
                                        <table id="admin-list" className="table table-hover" style={{width:'100%'}}></table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}
export default Admin_List