import React from 'react';
import './Loader.css';

function Loader() {
  return (
    <div id="load_screen"> <div class="loader"> <div class="loader-content">
        <div class="spinner-grow align-self-center"></div>
    </div></div></div>
  )
}

export default Loader