import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyBmPt4-8vcN_2Njfvj0WP2eYzJxrUzXPlA",
    authDomain: "evify-app-627cf.firebaseapp.com",
    projectId: "evify-app-627cf",
    storageBucket: "evify-app-627cf.appspot.com",
    messagingSenderId: "458983408513",
    appId: "1:458983408513:web:4aa91b6943d6fa2271de80"
}
var userAgent = navigator.userAgent || navigator.vendor || window.opera;
var isiOSDevice = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
const firebaseApp = !isiOSDevice ? firebase.initializeApp(firebaseConfig) : {};
const messaging = !isiOSDevice ? firebase.messaging() : "";

export const requestFirebaseNotificationPermission = () => {
    if(!isiOSDevice){
      return new Promise((resolve, reject) => {
        messaging
          .requestPermission()
          .then(() => messaging.getToken())
          .then((firebaseToken) => {
            resolve(firebaseToken);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
}

export const onMessageListener = () =>{
  if(!isiOSDevice){
      return new Promise((resolve) => {
        messaging.onMessage((payload) => {
          resolve(payload);
        });
      });
    }
}