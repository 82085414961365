import React,{useEffect, useState, useRef}  from 'react'
import { useNavigate, useParams }from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Footer from '../../DashBoard/Footer';
import Loader from '../../common/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import * as action from '../../../action/VehicleLeaseCompany/vehicle_lease_company_action';
import {baseURL} from '../../../service/baseService';
const ViewVehicle = props => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {vehicle_lease_company_id} = useParams();
    const [disabled, setDisabled]=useState(true);
    const [editButtonHide, setEditButtonHide]=useState(false);
    const [cancelButtonHide, setCancelButtonHide]=useState(true);
    const [submitButtonHide, setSubmitButtonHide]=useState(true);
    const [addBatchButtonHide, setAddBatchButtonHide]=useState(true);
    const [deleteBatchButtonHide, setDeleteBatchButtonHide]=useState(true);
    const {loading} = useSelector(state => state.VehicleLeaseCompanyEditReducer)
    const [modalImgURL, setModalImgURL]=useState("");
    const [input , setInput]= useState({
        id:vehicle_lease_company_id,
        name:"",
    });
    const [errors,setErrors] = useState({});
    const batchFocus = useRef();
    const nameFocus = useRef();
    const changeDateFormate = (date) =>{
        if(date){
            let new_date = String(date);
            let ndate = new_date.split('T');
            return ndate[0];
        }
    }
    const [batch,setBatchRow]=useState([
        {
            id:1, 
            batchNumber:"Batch1",
            numberOfVehicle:"",
            leaseTerms:"", 
            startDate:"", 
            noOfLeaseRental:"", 
            securityDeposite:"",
            advance:"",
            salvageValue:"",
            monthlyRental:"",
            contractFile:"",
            pdc:"",
        }
    ])
    const uploadContractFile = (e, batchId) => {
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("image", file, file.name);
        dispatch(action.vehicle_file_upload(formData)).then((data)=>{
            let oldBatch = batch.filter(item => item.id === batchId)?.[0]
            oldBatch = {...oldBatch, ['contractFile']: data.data.url}
            let newBatch = []
            batch.map(item => {
                if(item.id === batchId){
                    newBatch.push(oldBatch)
                } else 
                newBatch.push(item)
            })
            setBatchRow(newBatch)
        }).catch({})
    }
    const addExtraBatchInForm = () => {
        setBatchRow([...batch, {
            id: batch.length+1,
            batchNumber:"Batch"+(batch.length+1),
            numberOfVehicle:"", 
            leaseTerms:"", 
            startDate:"", 
            noOfLeaseRental:"", 
            securityDeposite:"",
            advance:"",
            salvageValue:"",
            monthlyRental:"",
            contractFile:"",
            pdc:"",
            new:true
        }])
    }
    const addBatchValue = (e, batchId) => {
        let oldBatch = batch.filter(item => item.id === batchId)?.[0]
        oldBatch = {...oldBatch, [e.target.name]: e.target.value}
        let newBatch = []
        batch.map(item => {
            if(item.id === batchId){
                newBatch.push(oldBatch)
            } else 
            newBatch.push(item)
        })
        setBatchRow(newBatch)
    }
    const addBatchValueForPDC = (value, batchId) => {
        let oldBatch = batch.filter(item => item.id === batchId)?.[0]
        oldBatch = {...oldBatch, ['pdc']: value}
        let newBatch = []
        batch.map(item => {
            if(item.id === batchId){
                newBatch.push(oldBatch)
            } else 
            newBatch.push(item)
        })
        setBatchRow(newBatch)
    }
    const deleteExtraBatchInForm = (batchId) => {
        setBatchRow(batch.filter(item => item.id !== batchId))
    }
    useEffect(() => {
        dispatch(action.vehicle_lease_company_details(vehicle_lease_company_id)).then((data)=>{
            let vehicle_lease_company_details = data.data;
            setInput({
                id:vehicle_lease_company_id,
                name:vehicle_lease_company_details.name,
            });
            let newBatchList = [];
            let i = 1;
            data.data.batch.map(item => {
                newBatchList.push({
                    id:item._id,
                    batchNumber:"Batch"+i,
                    numberOfVehicle:item.numberOfVehicle, 
                    leaseTerms:item.leaseTerms, 
                    startDate:item.startDate, 
                    noOfLeaseRental:item.noOfLeaseRental, 
                    securityDeposite:item.securityDeposite,
                    advance:item.advance,
                    salvageValue:item.salvageValue,
                    monthlyRental:item.monthlyRental,
                    contractFile:item.contractFile,
                    pdc:parseInt(item.pdc),
                    new:false
                });
                i++;
            })
            setBatchRow(newBatchList)
        }).catch({})
    },[])  
    const setEditFormEditable = (type) => {
        if(type){
            setDisabled(false);
            setAddBatchButtonHide(false);
            setDeleteBatchButtonHide(false);
            setCancelButtonHide(false);
            setSubmitButtonHide(false);
            setEditButtonHide(true);
        } else {
            setDisabled(true);
            setAddBatchButtonHide(true);
            setDeleteBatchButtonHide(true);
            setCancelButtonHide(true);
            setSubmitButtonHide(true);
            setEditButtonHide(false);
        }
    }
    const onsubmitHandler = (e) => {
        e.preventDefault()
        setErrors({});
        let customErrors = {};
        let batcherrors = {};
        batch.map(item => {
            let errors = {};
            if(item.batchNumber === ''){
                errors = {...errors, batchNumber: "Please enter batch number"};
            } else if(item.numberOfVehicle === ''){
                errors = {...errors, numberOfVehicle: 'Please enter number of vehicle'}
            } else if(item.leaseTerms === ''){
                errors = {...errors, leaseTerms: 'Please enter lease terms'}
            } else if(item.startDate === ''){
                errors = {...errors, startDate: 'Please enter start date'}
            } else if(item.noOfLeaseRental === ''){
                errors = {...errors, noOfLeaseRental: 'Please enter number of lease rental'}
            } else if(item.securityDeposite === ''){
                errors = {...errors, securityDeposite: 'Please enter security deposite'}
            } else if(item.advance === ''){
                errors = {...errors, advance: 'Please enter advance amount'}
            } else if(item.salvageValue === ''){
                errors = {...errors, salvageValue: 'Please enter salvage value amount'}
            } else if(item.monthlyRental === ''){
                errors = {...errors, monthlyRental: 'Please enter monthly rental'}
            } else if(item.pdc === ''){
                errors = {...errors, pdc: 'Please select PDC'}
            } else if(item.contractFile === ''){
                errors = {...errors, contractFile: 'Please select contract file'}
            }
            if(Object.keys(errors).length > 0) {
                batcherrors[item.id] = errors;
            } 
        })
        if(!/^[a-zA-Z0-9 ]+$/.test(input.name)){
            customErrors = {...customErrors, name: "Please enter company name"}
            nameFocus.current.focus();
        } else if(Object.keys(batcherrors).length > 0){
            customErrors = {...customErrors, batch : batcherrors}
            batchFocus.current.focus();
        }
        if(Object.keys(customErrors).length > 0){
            setErrors(customErrors)
            return true
        }
        let newBatch = [];
        batch.map(item => {
            if(item.new){
                newBatch.push({
                    batchNumber:item.batchNumber, 
                    numberOfVehicle:item.numberOfVehicle, 
                    leaseTerms:item.leaseTerms, 
                    startDate:item.startDate, 
                    noOfLeaseRental:item.noOfLeaseRental, 
                    securityDeposite:item.securityDeposite,
                    advance:item.advance,
                    salvageValue:item.salvageValue,
                    monthlyRental:item.monthlyRental,
                    contractFile:item.contractFile,
                    pdc:item.pdc
                });
            } else {
                newBatch.push({
                    _id:item.id,
                    batchNumber:item.batchNumber, 
                    numberOfVehicle:item.numberOfVehicle, 
                    leaseTerms:item.leaseTerms, 
                    startDate:item.startDate, 
                    noOfLeaseRental:item.noOfLeaseRental, 
                    securityDeposite:item.securityDeposite,
                    advance:item.advance,
                    salvageValue:item.salvageValue,
                    monthlyRental:item.monthlyRental,
                    contractFile:item.contractFile,
                    pdc:item.pdc
                });
            }
        })
        let parameter = {
            id:vehicle_lease_company_id,
            name:input.name,
            batch:newBatch
        };
        dispatch(action.edit_vehicle_lease_company(parameter)).then((data)=>{
            toast.success(data.msg);
            setDisabled(true);
            setAddBatchButtonHide(true);
            setDeleteBatchButtonHide(true);
            setCancelButtonHide(true);
            setSubmitButtonHide(true);
            setEditButtonHide(false);
        }).catch(error=>{
            toast.error(error);
        })
}
    return (
        <>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
        <Header />
        <Sub_Header />
        <div className="main-container" id="container">
            {
                loading ? <Loader /> : null
            }
            <div className="overlay"></div>
            <div className="search-overlay"></div>

            <SideBar></SideBar>
            <div id="content" className="main-content">
                <div className="layout-px-spacing">

                    <div className="row layout-top-spacing">
                    
                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                            <div className="widget-content widget-content-area br-6">
                                <div className="input-group edit-btn-group">
                                    <h5 className="">Vehicle Lease Company Details</h5>
                                    <span id="editidspan" className="btn btn-warning btn-sm float-right ms-3 mb-1" hidden={editButtonHide} style={{cursor: 'pointer'}} onClick={ ()=>setEditFormEditable(true)}> Edit </span>
                                </div>
                                <br/>
                                <form onSubmit={onsubmitHandler}>
                                <div id="append-div-id">
                                        <div className="form-group row">
                                            <div className="form-group col-md-12 required">
                                                <label for="inputVehicleLeaseCompanyname">Company Name *</label>
                                                <input type="text" disabled={disabled} className="form-control" name="name" pattern="^[a-zA-Z0-9 ]+$" id="inputVehicleLeaseCompanyname" placeholder="Enter vehicle lease company name" onChange={(e)=>setInput({...input ,  name:e.target.value})} value={input.name}/>
                                                <span style={{color : 'red'}}>{errors?.name}</span>
                                            </div>
                                        </div>
                                        {
                                            Object.entries(batch).map(([key,batchItem])=>(
                                                <>
                                                    <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                                        <div className="widget-content widget-content-area br-6">
                                                            <div className="d-flex justify-content-end">
                                                            {
                                                                key === '0' ?  <span id="addAnotherDocIdspan" className="btn btn-warning btn-sm float-right ms-3" style={{cursor: "pointer"}} onClick={ ()=>addExtraBatchInForm()} hidden={addBatchButtonHide}> Add Other Batch </span> :
                                                                <span hidden={deleteBatchButtonHide} className="btn btn-danger btn-sm float-right ms-3" style={{ cursor: 'pointer'}} onClick={ ()=>deleteExtraBatchInForm(batchItem.id)}> Delete This </span>
                                                            }
                                                            </div>
                                                            <div className="form-group row" ref={batchFocus}>
                                                                <div className="form-group col-md-4 required">
                                                                    <label for={`inputBatchNumber${batchItem.id}`}>Batch Number *</label>
                                                                    <input type="text" disabled className="form-control" name="batchNumber" id={`inputBatchNumber${batchItem.id}`} placeholder="Enter Batch Number" onChange={(e)=>addBatchValue(e,batchItem.id)} value={batchItem.batchNumber}/>
                                                                    <span style={{color : 'red'}}>{errors.batch ? errors.batch[batchItem.id]?.batchNumber ? errors.batch[batchItem.id]?.batchNumber:'':''}</span>
                                                                </div>
                                                                <div className="form-group col-md-4 required">
                                                                    <label for={`inputNumberOfVehicle${batchItem.id}`}>No. Of Vehicles *</label>
                                                                    <input disabled={disabled} type="number" className="form-control" name="numberOfVehicle" id={`inputNumberOfVehicle${batchItem.id}`} placeholder="Enter No. Of Vehicle" onChange={(e)=>addBatchValue(e,batchItem.id)} value={batchItem.numberOfVehicle}/>
                                                                    <span style={{color : 'red'}}>{errors.batch ? errors.batch[batchItem.id]?.numberOfVehicle ? errors.batch[batchItem.id]?.numberOfVehicle:'':''}</span>
                                                                </div>
                                                                <div className="form-group col-md-4 required">
                                                                    <label for={`inputLeaseTerms${batchItem.id}`}>Lease Terms {'(Months)'} *</label>
                                                                    <input disabled={disabled} type="number" className="form-control" name="leaseTerms" id={`inputLeaseTerms${batchItem.id}`} placeholder="Enter Lease Terms" onChange={(e)=>addBatchValue(e,batchItem.id)} value={batchItem.leaseTerms}/>
                                                                    <span style={{color : 'red'}}>{errors.batch ? errors.batch[batchItem.id]?.leaseTerms ? errors.batch[batchItem.id]?.leaseTerms:'':''}</span>
                                                                </div>
                                                                <div className="form-group col-md-4 required">
                                                                    <label for={`inputStartDate${batchItem.id}`}>Start Date *</label>
                                                                    <input disabled={disabled} type="date" className="form-control" name="startDate" id={`inputStartDate${batchItem.id}`} placeholder="Enter Lease Start Date" onChange={(e)=>addBatchValue(e,batchItem.id)} value={changeDateFormate(batchItem.startDate)}/>
                                                                    <span style={{color : 'red'}}>{errors.batch ? errors.batch[batchItem.id]?.startDate ? errors.batch[batchItem.id]?.startDate:'':''}</span>
                                                                </div>
                                                                <div className="form-group col-md-4 required">
                                                                    <label for={`inputNoOfLeaseRental${batchItem.id}`}>No. Of Lease Rental *</label>
                                                                    <input disabled={disabled} type="number" className="form-control" name="noOfLeaseRental" id={`inputNoOfLeaseRental${batchItem.id}`} placeholder="Enter No. Of Lease Rental" onChange={(e)=>addBatchValue(e,batchItem.id)} value={batchItem.noOfLeaseRental}/>
                                                                    <span style={{color : 'red'}}>{errors.batch ? errors.batch[batchItem.id]?.noOfLeaseRental ? errors.batch[batchItem.id]?.noOfLeaseRental:'':''}</span>
                                                                </div>
                                                                <div className="form-group col-md-4 required">
                                                                    <label for={`inputSecurityDeposite${batchItem.id}`}>Security Deposite *</label>
                                                                    <input disabled={disabled} type="number" className="form-control" name="securityDeposite" id={`inputSecurityDeposite${batchItem.id}`} placeholder="Enter Security Deposite" onChange={(e)=>addBatchValue(e,batchItem.id)} value={batchItem.securityDeposite}/>
                                                                    <span style={{color : 'red'}}>{errors.batch ? errors.batch[batchItem.id]?.securityDeposite ? errors.batch[batchItem.id]?.securityDeposite:'':''}</span>
                                                                </div>
                                                                <div className="form-group col-md-4 required">
                                                                    <label for={`inputAdvance${batchItem.id}`}>Advance *</label>
                                                                    <input disabled={disabled} type="number" className="form-control" name="advance" id={`inputAdvance${batchItem.id}`} placeholder="Enter Advance Amount"  onChange={(e)=>addBatchValue(e,batchItem.id)} value={batchItem.advance}/>
                                                                    <span style={{color : 'red'}}>{errors.batch ? errors.batch[batchItem.id]?.advance ? errors.batch[batchItem.id]?.advance:'':''}</span>
                                                                </div>
                                                                <div className="form-group col-md-4 required">
                                                                    <label for={`inputSalvageValue${batchItem.id}`}>Salvage Value *</label>
                                                                    <input disabled={disabled} type="number" className="form-control" name="salvageValue" id={`inputSalvageValue${batchItem.id}`} placeholder="Enter Salvage Value Amount" onChange={(e)=>addBatchValue(e,batchItem.id)} value={batchItem.salvageValue}/>
                                                                    <span style={{color : 'red'}}>{errors.batch ? errors.batch[batchItem.id]?.salvageValue ? errors.batch[batchItem.id]?.salvageValue:'':''}</span>
                                                                </div>
                                                                <div className="form-group col-md-4 required">
                                                                    <label for={`inputMonthlyRental${batchItem.id}`}>Monthly Rental *</label>
                                                                    <input disabled={disabled} type="number" className="form-control" name="monthlyRental" id={`inputMonthlyRental${batchItem.id}`} placeholder="Enter Monthly Rental" onChange={(e)=>addBatchValue(e,batchItem.id)} value={batchItem.monthlyRental}/>
                                                                    <span style={{color : 'red'}}>{errors.batch ? errors.batch[batchItem.id]?.monthlyRental ? errors.batch[batchItem.id]?.monthlyRental:'':''}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="form-group col-md-3 required">
                                                                    <label>PDC *</label><br/>
                                                                    <div className="custom-control custom-radio custom-control-inline" id={`vehicleStatus${batchItem.id}`}>
                                                                        {
                                                                            batchItem.pdc === 1 ? <input disabled={disabled} type="radio" id={`status1${batchItem.id}`} name={`pdc${batchItem.id}`} className="custom-control-input" value="1" onChange={(e)=>addBatchValueForPDC(e.target.value,batchItem.id)} checked/> : <input disabled={disabled} type="radio" id={`status1${batchItem.id}`} name={`pdc${batchItem.id}`} className="custom-control-input" value="1" onChange={(e)=>addBatchValueForPDC(e.target.value,batchItem.id)}/>
                                                                        }
                                                                        <label className="custom-control-label status-radio" for={`status1${batchItem.id}`}>Yes</label>
                                                                    </div>
                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                        {
                                                                            batchItem.pdc === 0 ? <input disabled={disabled} type="radio" id={`status0${batchItem.id}`} name={`pdc${batchItem.id}`} className="custom-control-input" value="0" onChange={(e)=>addBatchValueForPDC(e.target.value,batchItem.id)} checked/> : <input disabled={disabled} type="radio" id={`status0${batchItem.id}`} name={`pdc${batchItem.id}`} className="custom-control-input" value="0" onChange={(e)=>addBatchValueForPDC(e.target.value,batchItem.id)}/>
                                                                        }
                                                                        <label className="custom-control-label status-radio" for={`status0${batchItem.id}`}>No</label>
                                                                    </div><br />
                                                                    <span style={{color : 'red'}}>{errors.batch ? errors.batch[batchItem.id]?.pdc ? errors.batch[batchItem.id]?.pdc:'':''}</span>
                                                                </div>
                                                                <div className="form-group col-md-9 required">
                                                                    <label for={`contractFile${batchItem.id}`}>Contrat Document *</label>
                                                                    <input disabled={disabled} accept="image/jpg,image/jpeg,image/png,image/bmp" multiple="" type="file" onChange={(e)=>uploadContractFile(e, batchItem.id)} name="contractFile[]" className="form-control p-2" id={`contractFile${batchItem.id}`} />
                                                                    <span style={{color : 'red'}}>{errors.batch ? errors.batch[batchItem.id]?.contractFile ? errors.batch[batchItem.id]?.contractFile:'':''}</span><br />
                                                                    {
                                                                        batchItem.contractFile !== '' ? <img src={`${baseURL}${batchItem.contractFile}`} alt="no-image" id={`contractFileImgId${batchItem.id}`} className="form-group p-2 img-responsive imgFile" onClick={ ()=>setModalImgURL(batchItem.contractFile)} data-toggle="modal" data-target="#showImageModalCenter" style={{width: "70px", height: "70px"}} /> : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }

                                        <div className="modal fade" id="showImageModalCenter" tabindex="-1" role="dialog" aria-labelledby="showImageModalCenterLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="img">
                                                <div className="modal-content">         
                                                    <div className="modal-body">
                                                        {
                                                            modalImgURL !== '' ? <img src={`${baseURL}${modalImgURL}`} alt="no-image" id="modalImgId" className="img-responsive" style={{width: "100%", height: "100%"}} />: ''
                                                        }
                                                    </div>            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" id="saveAndCancleButtons">
                                        <button onClick={ ()=>setEditFormEditable(false)} hidden={cancelButtonHide} type="reset" class="btn btn-danger mt-3">Cancel</button>&nbsp;
                                        <button hidden={submitButtonHide} type="submit" class="btn btn-success mt-3 view-btn">Update</button>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>

                </div>
                <Footer></Footer>
            </div>
        </div>
    </>
    )
}
export default ViewVehicle