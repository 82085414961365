import {
    TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL,
    TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_ERROR,
    TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_LOADING,
    TECHNICIAN_VEHICLE_LIST_SUCCESSFULL, 
    TECHNICIAN_VEHICLE_LIST_ERROR,
    TECHNICIAN_VEHICLE_LIST_LOADING,
    TECHNICIAN_VEHICLE_REPAIRED_CHANGE_SUCCESSFULL,
    TECHNICIAN_VEHICLE_REPAIRED_CHANGE_ERROR,
    TECHNICIAN_VEHICLE_REPAIRED_CHANGE_LOADING,
} from '../../action/type';

import * as AuthService from '../../service/services'

export const Vehicle_technician_dashboard_details=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_LOADING,
                data: true
            })
            AuthService.TechnicianVehicleDashboardDetails()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const Vehicle_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: TECHNICIAN_VEHICLE_LIST_LOADING,
                data: true
            })
            AuthService.TechnicianVehicleList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:TECHNICIAN_VEHICLE_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:TECHNICIAN_VEHICLE_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_technician_repair_change=(vehicle_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: TECHNICIAN_VEHICLE_REPAIRED_CHANGE_LOADING,
                data: true
            })
            AuthService.VehicleTechnicianRepairedChange(vehicle_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:TECHNICIAN_VEHICLE_REPAIRED_CHANGE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:TECHNICIAN_VEHICLE_REPAIRED_CHANGE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}