import axios from 'axios';
let baseurl = '';
if(window.location.origin === 'http://localhost:3000'){
    baseurl = 'http://localhost:3004';
} else {
    baseurl = window.location.origin;
}
export const baseURL=`${baseurl}/`;
const baseService=axios.create({
    baseURL:baseURL,
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token') //the token is a variable which holds the token
    }
})
export default baseService;