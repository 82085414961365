import React, { useEffect, useRef, useState} from 'react'
import { useNavigate, useParams }from 'react-router-dom'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Footer from '../../DashBoard/Footer';
import { ToastContainer, toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import { format } from 'date-fns'
import subDays from 'date-fns/subDays';
import Select from 'react-select'
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import Loader from '../../common/Loader/Loader';
import {ExportToExcelWithParcel} from './All_Driver_Attendance_Parcel_History_Excel'
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/Driver/driver_action';
import $ from 'jquery'
const AllCustomerReport = props => {
    $.DataTable = require('datatables.net')
    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.AllDriverAttendanceParcelReducer)
    var date = new Date();
    const [input , setInput]= useState([
        new Date(date.getFullYear(), date.getMonth(), 1),
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
    ]);
    const today = new Date();
    const defaultValue = new Date(today).toISOString().split('T')[0]
    const changeDateFormate = (date) => {
        if (date) {
            let new_date = String(date);
            let ndate = new_date.split('T');
            let format_date = format(new Date(ndate[0]), 'dd MMM yyyy');
            return format_date;
        }
    }
    const predefinedRanges = [
        {
            label: 'Current month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 60 days',
            value: [subDays(new Date(), 59), new Date()],
            placement: 'left'
        }
    ];
    const [dates,setDates]=useState([]);
    const [customerList,setCustomerList]=useState([]);
    const [totalList,setTotalList]=useState([]);
    useEffect(() => {
        dispatch(action.Get_All_Driver_Attendance_Parcel(input[0], input[1])).then((data)=>{
            setDates(data.data.dates);
            setCustomerList(data.data.customerList);
            setTotalList(data.data.totalList);
        }).catch({})
    },[input])
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing">

                        <div className="row layout-top-spacing">
                            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                <div className="widget-content widget-content-area br-6">
                                    <h5 className="" style={{ margin: '10px'}}>Customer Report</h5>
                                    <div className="table-responsive mb-4 mt-4">
                                        <div class="form-group row col-12 ml-1">
                                            <DateRangePicker ranges={predefinedRanges} value={input} onChange={setInput} style={{ width: 300 }} placeholder="Select Date Range"/>
                                            &nbsp;
                                            {
                                                loading ? <button disabled class="btn btn-success"><i class="fa fa-spinner fa-spin"></i> </button> :
                                                    (dates && dates.length > 0) ?
                                                    <ExportToExcelWithParcel dates={dates} customerList={customerList} totalList={totalList} startDate={input[0]} endDate={input[1]}/>
                                                    : <div className='btn btn-success'>Export to XLS</div> 
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}
export default AllCustomerReport