import React, { useEffect, useRef, useState} from 'react'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'date-fns'
import Footer from '../../DashBoard/Footer';
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Loader from '../../common/Loader/Loader';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/VehicleLeaseCompany/vehicle_lease_company_action';
import $ from 'jquery'
import { renderToString } from 'react-dom/server'
function Vehicle_List() {
    $.DataTable = require('datatables.net')
    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.VehicleLeaseCompanyListReducer)
    console.log(loading);
    const tableRef = useRef();
    const [leaseCompanyList,setLeaseCompanyList]=useState([]);
    const changeDateFormate = (date) =>{
        if (date) {
            let new_date = String(date);
            let ndate = new_date.split('T');
            let format_date = format(new Date(ndate[0]), 'dd MMM yyyy');
            return format_date;
        }
    }
    // const activeDeactiveAlertPopup = (company) => {
    //     dispatch(action.vehicle_lease_company_status_change(company[11])).then((data)=>{
    //         toast.success(data.msg);
    //         let newCompanyList = [];
    //         leaseCompanyList.map(item => {
    //             if(item[11] === company[11]){
    //                 let company_data = data.data;
    //                 newCompanyList.push([company_data.name, company_data.numberOfVehicle, company_data.leaseTerms, changeDateFormate(company_data.startDate), company_data.noOfLeaseRental, company_data.securityDeposite, company_data.advance, company_data.salvageValue, company_data.monthlyRental, company_data.isActive, `<a style="cursor: pointer;" href="/adminside/vehicleLeaseCompany/view/${company_data._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${company_data._id}">View</a>`, company_data._id, company_data]);
    //             } else {
    //                 newCompanyList.push(item);
    //             }
    //         })
    //         setLeaseCompanyList(newCompanyList)
    //     }).catch(error=>{
    //         toast.error(error);
    //     })
    // }
    const tableName = "vehicle-lease-company-list";
    useEffect(() => {
        dispatch(action.vehicle_lease_company_list()).then((data)=>{
            let newCompanyList = [];
            data.data.map(item => {
                newCompanyList.push([item.name, `<a style="cursor: pointer;" href="/adminside/vehicleLeaseCompany/view/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${item._id}">View</a>`, item._id, item]);
            })
            setLeaseCompanyList(newCompanyList)
        }).catch({})
    },[])
    useEffect(()=>{
        const table = $(`#${tableName}`).DataTable(
            {
                dom: 'Bfrtip',
                buttons: [
                    'copyHtml5', 'excelHtml5', 'pdfHtml5', 'csvHtml5'
                ],
                data: leaseCompanyList,
                columns: [
                    { title: "Company Name"},
                    // { title: "No. Of Vehicle"},
                    // { title: "Lease Terms"},
                    // { title: "Start Date"},
                    // { title: "No. Of Lease Rental"},
                    // { title: "Security Deposite"},
                    // { title: "Advance"},
                    // { title: "Savage Value"},
                    // { title: "Monthly Rental"},
                    // { title: "Status"},
                    { title: "Action"}
                ],
                // columnDefs: [
                //     {
                //         targets: [9],
                //         className: 'center',
                //         createdCell: (td, cellData, rowData) => ReactDOM.render(
                //             <a style={{cursor: "pointer"}} className={rowData[9] === 1 ? 'btn btn-success btn-sm status-act-btn' : 'btn btn-danger btn-sm status-act-btn'} onClick={() => activeDeactiveAlertPopup(rowData)}> {rowData[9] === 1 ? 'Active' : 'Deactive'} </a>,td
                //         )
                //     }
                // ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                oLanguage: {
                    oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                    //sInfo: "Showing page _PAGE_ of _PAGES_",
                    sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                    sSearchPlaceholder: "Search...",
                    sLengthMenu: "Results :  _MENU_",
                },
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[leaseCompanyList])
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover  theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                <div className="layout-px-spacing">

                    <div className="row layout-top-spacing">

                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                            <div className="widget-content widget-content-area br-6">
                                <h5 className="" style={{margin: '10px'}}>Vehicle Lease Company List</h5>
                                <div className="table-responsive mb-4 mt-4">
                                    <table id="vehicle-lease-company-list" className="table table-hover" style={{width:'100%'}}>
                                        <thead>
                                            <tr>
                                                <th>Company Name</th>
                                                {/* <th>No. Of Vehicle</th>
                                                <th>Lease Terms</th>
                                                <th>Start Date</th>
                                                <th>No. Of Lease Rental</th>
                                                <th>Security Deposite</th>
                                                <th>Advance</th>
                                                <th>Savage Value</th>
                                                <th>Monthly Rental</th>
                                                <th>Status</th> */}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <Footer></Footer>
            </div>
            </div>
        </>
    )
}
export default Vehicle_List