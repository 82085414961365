export const LOGIN_LOADING = "LOGIN_LOADING"
export const LOGIN_SUCCESSFULL="LOGIN_SUCCESSFULL"
export const LOGIN_ERROR="LOGIN_ERROR"
export const LOGOUT_LOADING = "LOGOUT_LOADING"
export const LOGOUT_SUCCESSFULL="LOGOUT_SUCCESSFULL"
export const LOGOUT_ERROR="LOGOUT_ERROR"
export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING"
export const NOTIFICATION_SUCCESSFULL="NOTIFICATION_SUCCESSFULL"
export const NOTIFICATION_ERROR="NOTIFICATION_ERROR"
export const CUSTOMER_LIST_SUCCESSFULL="CUSTOMER_LIST_SUCCESSFULL"
export const CUSTOMER_LIST_ERROR="CUSTOMER_LIST_ERROR"
export const CUSTOMER_LIST_LOADING = "CUSTOMER_LIST_LOADING"
export const CUSTOMER_ADD_SUCCESSFULL="CUSTOMER_ADD_SUCCESSFULL"
export const CUSTOMER_ADD_ERROR="CUSTOMER_ADD_ERROR"
export const CUSTOMER_ADD_LOADING = "CUSTOMER_ADD_LOADING"
export const CUSTOMER_ADD_ADDRESS_SUCCESSFULL="CUSTOMER_ADD_ADDRESS_SUCCESSFULL"
export const CUSTOMER_ADD_ADDRESS_ERROR="CUSTOMER_ADD_ADDRESS_ERROR"
export const CUSTOMER_ADD_ADDRESS_LOADING = "CUSTOMER_ADD_ADDRESS_LOADING"
export const CUSTOMER_EDIT_ADDRESS_SUCCESSFULL="CUSTOMER_EDIT_ADDRESS_SUCCESSFULL"
export const CUSTOMER_EDIT_ADDRESS_ERROR="CUSTOMER_EDIT_ADDRESS_ERROR"
export const CUSTOMER_EDIT_ADDRESS_LOADING = "CUSTOMER_EDIT_ADDRESS_LOADING"
export const CUSTOMER_STATUS_CHANGE_SUCCESSFULL="CUSTOMER_STATUS_CHANGE_SUCCESSFULL"
export const CUSTOMER_STATUS_CHANGE_ERROR="CUSTOMER_STATUS_CHANGE_ERROR"
export const CUSTOMER_STATUS_CHANGE_LOADING = "CUSTOMER_STATUS_CHANGE_LOADING"
export const CUSTOMER_ADD_SHIFT_SUCCESSFULL="CUSTOMER_ADD_SHIFT_SUCCESSFULL"
export const CUSTOMER_ADD_SHIFT_ERROR="CUSTOMER_ADD_SHIFT_ERROR"
export const CUSTOMER_ADD_SHIFT_LOADING = "CUSTOMER_ADD_SHIFT_LOADING"
export const CUSTOMER_EDIT_SHIFT_SUCCESSFULL="CUSTOMER_EDIT_SHIFT_SUCCESSFULL"
export const CUSTOMER_EDIT_SHIFT_ERROR="CUSTOMER_EDIT_SHIFT_ERROR"
export const CUSTOMER_EDIT_SHIFT_LOADING = "CUSTOMER_EDIT_SHIFT_LOADING"
export const CUSTOMER_EDIT_SUCCESSFULL="CUSTOMER_EDIT_SUCCESSFULL"
export const CUSTOMER_EDIT_ERROR="CUSTOMER_EDIT_ERROR"
export const CUSTOMER_EDIT_LOADING = "CUSTOMER_EDIT_LOADING"
export const CUSTOMER_DETAILS_SUCCESSFULL="CUSTOMER_DETAILS_SUCCESSFULL"
export const CUSTOMER_DETAILS_ERROR="CUSTOMER_DETAILS_ERROR"
export const CUSTOMER_DETAILS_LOADING = "CUSTOMER_DETAILS_LOADING"
export const ADDRESS_LIST_SUCCESSFULL="ADDRESS_LIST_SUCCESSFULL"
export const ADDRESS_LIST_ERROR="ADDRESS_LIST_ERROR"
export const ADDRESS_LIST_LOADING = "ADDRESS_LIST_LOADING"
export const ADDRESS_STATUS_CHANGE_SUCCESSFULL="ADDRESS_STATUS_CHANGE_SUCCESSFULL"
export const ADDRESS_STATUS_CHANGE_ERROR="ADDRESS_STATUS_CHANGE_ERROR"
export const ADDRESS_STATUS_CHANGE_LOADING = "ADDRESS_STATUS_CHANGE_LOADING"
export const SHIFT_LIST_SUCCESSFULL="SHIFT_LIST_SUCCESSFULL"
export const SHIFT_LIST_ERROR="SHIFT_LIST_ERROR"
export const SHIFT_LIST_LOADING = "SHIFT_LIST_LOADING"
export const SHIFT_STATUS_CHANGE_SUCCESSFULL="SHIFT_STATUS_CHANGE_SUCCESSFULL"
export const SHIFT_STATUS_CHANGE_ERROR="SHIFT_STATUS_CHANGE_ERROR"
export const SHIFT_STATUS_CHANGE_LOADING = "SHIFT_STATUS_CHANGE_LOADING"
export const VIHICLE_LIST_SUCCESSFULL="VIHICLE_LIST_SUCCESSFULL"
export const VIHICLE_LIST_ERROR="VIHICLE_LIST_ERROR"
export const VIHICLE_LIST_LOADING = "VIHICLE_LIST_LOADING"
export const CUSTOMER_GENERATE_INVOICE_SUCCESSFULL="CUSTOMER_GENERATE_INVOICE_SUCCESSFULL"
export const CUSTOMER_GENERATE_INVOICE_ERROR="CUSTOMER_GENERATE_INVOICE_ERROR"
export const CUSTOMER_GENERATE_INVOICE_LOADING = "CUSTOMER_GENERATE_INVOICE_LOADING"
export const CUSTOMER_LATEST_INVOICE_NO_SUCCESSFULL="CUSTOMER_LATEST_INVOICE_NO_SUCCESSFULL"
export const CUSTOMER_LATEST_INVOICE_NO_ERROR="CUSTOMER_LATEST_INVOICE_NO_ERROR"
export const CUSTOMER_LATEST_INVOICE_NO_LOADING = "CUSTOMER_LATEST_INVOICE_NO_LOADING"
export const UPLOAD_CUSTOMER_INVOICE_LOADING = "UPLOAD_CUSTOMER_INVOICE_LOADING"
export const UPLOAD_CUSTOMER_INVOICE_SUCCESSFULL = "UPLOAD_CUSTOMER_INVOICE_SUCCESSFULL"
export const UPLOAD_CUSTOMER_INVOICE_ERROR = "UPLOAD_CUSTOMER_INVOICE_ERROR"
export const CUSTOMER_INVOICE_ADDED_LOADING = "CUSTOMER_INVOICE_ADDED_LOADING"
export const CUSTOMER_INVOICE_ADDED_SUCCESSFULL = "CUSTOMER_INVOICE_ADDED_SUCCESSFULL"
export const CUSTOMER_INVOICE_ADDED_ERROR = "CUSTOMER_INVOICE_ADDED_ERROR"
export const CUSTOMER_INVOICE_LIST_LOADING = "CUSTOMER_INVOICE_LIST_LOADING"
export const CUSTOMER_INVOICE_LIST_SUCCESSFULL = "CUSTOMER_INVOICE_LIST_SUCCESSFULL"
export const CUSTOMER_INVOICE_LIST_ERROR = "CUSTOMER_INVOICE_LIST_ERROR"
export const DELETE_CUSTOMER_INVOICE_LOADING = "DELETE_CUSTOMER_INVOICE_LOADING"
export const DELETE_CUSTOMER_INVOICE_SUCCESSFULL = "DELETE_CUSTOMER_INVOICE_SUCCESSFULL"
export const DELETE_CUSTOMER_INVOICE_ERROR = "DELETE_CUSTOMER_INVOICE_ERROR"
export const VIHICLE_STATUS_CHANGE_SUCCESSFULL = "VIHICLE_STATUS_CHANGE_SUCCESSFULL"
export const VIHICLE_STATUS_CHANGE_ERROR = "VIHICLE_STATUS_CHANGE_ERROR"
export const VIHICLE_STATUS_CHANGE_LOADING = "VIHICLE_STATUS_CHANGE_LOADING"
export const VIHICLE_FILE_UPLOAD_SUCCESSFULL = "VIHICLE_FILE_UPLOAD_SUCCESSFULL"
export const VIHICLE_FILE_UPLOAD_ERROR = "VIHICLE_FILE_UPLOAD_ERROR"
export const VIHICLE_FILE_UPLOAD_LOADING = "VIHICLE_FILE_UPLOAD_LOADING"
export const VIHICLE_ADD_SUCCESSFULL="VIHICLE_ADD_SUCCESSFULL"
export const VIHICLE_ADD_ERROR="VIHICLE_ADD_ERROR"
export const VIHICLE_ADD_LOADING = "VIHICLE_ADD_LOADING"
export const VIHICLE_DELETE_FILE_SUCCESSFULL = "VIHICLE_DELETE_FILE_SUCCESSFULL"
export const VIHICLE_DELETE_FILE_ERROR = "VIHICLE_DELETE_FILE_ERROR"
export const VIHICLE_DELETE_FILE_LOADING = "VIHICLE_DELETE_FILE_LOADING"
export const VIHICLE_DETAILS_SUCCESSFULL="VIHICLE_DETAILS_SUCCESSFULL"
export const VIHICLE_DETAILS_ERROR="VIHICLE_DETAILS_ERROR"
export const VIHICLE_DETAILS_LOADING = "VIHICLE_DETAILS_LOADING"
export const VIHICLE_EDIT_SUCCESSFULL="VIHICLE_EDIT_SUCCESSFULL"
export const VIHICLE_EDIT_ERROR="VIHICLE_EDIT_ERROR"
export const VIHICLE_EDIT_LOADING = "VIHICLE_EDIT_LOADING"
export const VIHICLE_LEASE_COMPANY_LIST_SELECT_SUCCESSFULL="VIHICLE_LEASE_COMPANY_LIST_SELECT_SUCCESSFULL"
export const VIHICLE_LEASE_COMPANY_LIST_SELECT_ERROR="VIHICLE_LEASE_COMPANY_LIST_SELECT_ERROR"
export const VIHICLE_LEASE_COMPANY_LIST_SELECT_LOADING = "VIHICLE_LEASE_COMPANY_LIST_SELECT_LOADING"
export const VIHICLE_TELEMATICS_UNIT_LIST_SELECT_SUCCESSFULL="VIHICLE_TELEMATICS_UNIT_LIST_SELECT_SUCCESSFULL"
export const VIHICLE_TELEMATICS_UNIT_LIST_SELECT_ERROR="VIHICLE_TELEMATICS_UNIT_LIST_SELECT_ERROR"
export const VIHICLE_TELEMATICS_UNIT_LIST_SELECT_LOADING = "VIHICLE_TELEMATICS_UNIT_LIST_SELECT_LOADING"
export const VIHICLE_HUB_LIST_SELECT_SUCCESSFULL="VIHICLE_HUB_LIST_SELECT_SUCCESSFULL"
export const VIHICLE_HUB_LIST_SELECT_ERROR="VIHICLE_HUB_LIST_SELECT_ERROR"
export const VIHICLE_HUB_LIST_SELECT_LOADING = "VIHICLE_HUB_LIST_SELECT_LOADING"
export const ADD_VEHICLE_LEASE_COMPANY_LOADING = "ADD_VEHICLE_LEASE_COMPANY_LOADING"
export const ADD_VEHICLE_LEASE_COMPANY_SUCCESSFULL = "ADD_VEHICLE_LEASE_COMPANY_SUCCESSFULL"
export const ADD_VEHICLE_LEASE_COMPANY_ERROR = "ADD_VEHICLE_LEASE_COMPANY_ERROR"
export const FILE_UPLOAD_VEHICLE_LEASE_COMPANY_LOADING = "FILE_UPLOAD_VEHICLE_LEASE_COMPANY_LOADING"
export const FILE_UPLOAD_VEHICLE_LEASE_COMPANY_SUCCESSFULL = "FILE_UPLOAD_VEHICLE_LEASE_COMPANY_SUCCESSFULL"
export const FILE_UPLOAD_VEHICLE_LEASE_COMPANY_ERROR = "FILE_UPLOAD_VEHICLE_LEASE_COMPANY_ERROR"
export const VEHICLE_LEASE_COMPANY_LIST_LOADING = "VEHICLE_LEASE_COMPANY_LIST_LOADING"
export const VEHICLE_LEASE_COMPANY_LIST_SUCCESSFULL = "VEHICLE_LEASE_COMPANY_LIST_SUCCESSFULL"
export const VEHICLE_LEASE_COMPANY_LIST_ERROR = "VEHICLE_LEASE_COMPANY_LIST_ERROR"
export const VEHICLE_LEASE_COMPANY_STATUS_CHANGE_SUCCESSFULL="VEHICLE_LEASE_COMPANY_STATUS_CHANGE_SUCCESSFULL"
export const VEHICLE_LEASE_COMPANY_STATUS_CHANGE_ERROR="VEHICLE_LEASE_COMPANY_STATUS_CHANGE_ERROR"
export const VEHICLE_LEASE_COMPANY_STATUS_CHANGE_LOADING = "VEHICLE_LEASE_COMPANY_STATUS_CHANGE_LOADING"
export const VEHICLE_LEASE_COMPANY_DETAILS_SUCCESSFULL="VEHICLE_LEASE_COMPANY_DETAILS_SUCCESSFULL"
export const VEHICLE_LEASE_COMPANY_DETAILS_ERROR="VEHICLE_LEASE_COMPANY_DETAILS_ERROR"
export const VEHICLE_LEASE_COMPANY_DETAILS_LOADING = "VEHICLE_LEASE_COMPANY_DETAILS_LOADING"
export const VEHICLE_LEASE_COMPANY_EDIT_SUCCESSFULL="VEHICLE_LEASE_COMPANY_EDIT_SUCCESSFULL"
export const VEHICLE_LEASE_COMPANY_EDIT_ERROR="VEHICLE_LEASE_COMPANY_EDIT_ERROR"
export const VEHICLE_LEASE_COMPANY_EDIT_LOADING = "VEHICLE_LEASE_COMPANY_EDIT_LOADING"
export const VEHICLE_TELEMATICS_UNIT_LIST_LOADING = "VEHICLE_TELEMATICS_UNIT_LIST_LOADING"
export const VEHICLE_TELEMATICS_UNIT_LIST_SUCCESSFULL = "VEHICLE_TELEMATICS_UNIT_LIST_SUCCESSFULL"
export const VEHICLE_TELEMATICS_UNIT_LIST_ERROR = "VEHICLE_TELEMATICS_UNIT_LIST_ERROR"
export const ADD_VEHICLE_TELEMATICS_UNIT_SUCCESSFULL = "ADD_VEHICLE_TELEMATICS_UNIT_SUCCESSFULL"
export const ADD_VEHICLE_TELEMATICS_UNIT_ERROR = "ADD_VEHICLE_TELEMATICS_UNIT_ERROR"
export const ADD_VEHICLE_TELEMATICS_UNIT_LOADING = "ADD_VEHICLE_TELEMATICS_UNIT_LOADING"
export const VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_SUCCESSFULL = "VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_SUCCESSFULL"
export const VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_ERROR = "VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_ERROR"
export const VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_LOADING = "VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_LOADING"
export const VEHICLE_TELEMATICS_UNIT_DETAILS_SUCCESSFULL = "VEHICLE_TELEMATICS_UNIT_DETAILS_SUCCESSFULL"
export const VEHICLE_TELEMATICS_UNIT_DETAILS_ERROR = "VEHICLE_TELEMATICS_UNIT_DETAILS_ERROR"
export const VEHICLE_TELEMATICS_UNIT_DETAILS_LOADING = "VEHICLE_TELEMATICS_UNIT_DETAILS_LOADING"
export const VEHICLE_TELEMATICS_UNIT_EDIT_SUCCESSFULL = "VEHICLE_TELEMATICS_UNIT_EDIT_SUCCESSFULL"
export const VEHICLE_TELEMATICS_UNIT_EDIT_ERROR = "VEHICLE_TELEMATICS_UNIT_EDIT_ERROR"
export const VEHICLE_TELEMATICS_UNIT_EDIT_LOADING = "VEHICLE_TELEMATICS_UNIT_EDIT_LOADING"
export const VEHICLE_HUB_LIST_LOADING = "VEHICLE_HUB_LIST_LOADING"
export const VEHICLE_HUB_LIST_SUCCESSFULL = "VEHICLE_HUB_LIST_SUCCESSFULL"
export const VEHICLE_HUB_LIST_ERROR = "VEHICLE_HUB_LIST_ERROR"
export const ADD_VEHICLE_HUB_SUCCESSFULL = "ADD_VEHICLE_HUB_SUCCESSFULL"
export const ADD_VEHICLE_HUB_ERROR = "ADD_VEHICLE_HUB_ERROR"
export const ADD_VEHICLE_HUB_LOADING = "ADD_VEHICLE_HUB_LOADING"
export const VEHICLE_HUB_STATUS_CHANGE_SUCCESSFULL = "VEHICLE_HUB_STATUS_CHANGE_SUCCESSFULL"
export const VEHICLE_HUB_STATUS_CHANGE_ERROR = "VEHICLE_HUB_STATUS_CHANGE_ERROR"
export const VEHICLE_HUB_STATUS_CHANGE_LOADING = "VEHICLE_HUB_STATUS_CHANGE_LOADING"
export const VEHICLE_HUB_DETAILS_SUCCESSFULL = "VEHICLE_HUB_DETAILS_SUCCESSFULL"
export const VEHICLE_HUB_DETAILS_ERROR = "VEHICLE_HUB_DETAILS_ERROR"
export const VEHICLE_HUB_DETAILS_LOADING = "VEHICLE_HUB_DETAILS_LOADING"
export const VEHICLE_HUB_EDIT_SUCCESSFULL = "VEHICLE_HUB_EDIT_SUCCESSFULL"
export const VEHICLE_HUB_EDIT_ERROR = "VEHICLE_HUB_EDIT_ERROR"
export const VEHICLE_HUB_EDIT_LOADING = "VEHICLE_HUB_EDIT_LOADING"
export const VEHICLE_HUB_MANAGER_LIST_SUCCESSFULL = "VEHICLE_HUB_MANAGER_LIST_SUCCESSFULL"
export const VEHICLE_HUB_MANAGER_LIST_ERROR = "VEHICLE_HUB_MANAGER_LIST_ERROR"
export const VEHICLE_HUB_MANAGER_LIST_LOADING = "VEHICLE_HUB_MANAGER_LIST_LOADING"
export const ADMIN_LIST_LOADING = "ADMIN_LIST_LOADING"
export const ADMIN_LIST_SUCCESSFULL = "ADMIN_LIST_SUCCESSFULL"
export const ADMIN_LIST_ERROR = "ADMIN_LIST_ERROR"
export const ADMIN_ADD_LOADING = "ADMIN_ADD_LOADING"
export const ADMIN_ADD_SUCCESSFULL = "ADMIN_ADD_SUCCESSFULL"
export const ADMIN_ADD_ERROR = "ADMIN_ADD_ERROR"
export const ADMIN_DETAILS_LOADING = "ADMIN_DETAILS_LOADING"
export const ADMIN_DETAILS_SUCCESSFULL = "ADMIN_DETAILS_SUCCESSFULL"
export const ADMIN_DETAILS_ERROR = "ADMIN_DETAILS_ERROR"
export const ADMIN_EDIT_LOADING = "ADMIN_EDIT_LOADING"
export const ADMIN_EDIT_SUCCESSFULL = "ADMIN_EDIT_SUCCESSFULL"
export const ADMIN_EDIT_ERROR = "ADMIN_EDIT_ERROR"
export const DRIVER_LIST_SUCCESSFULL="DRIVER_LIST_SUCCESSFULL"
export const DRIVER_LIST_ERROR="DRIVER_LIST_ERROR"
export const DRIVER_LIST_LOADING = "DRIVER_LIST_LOADING"
export const DRIVER_STATUS_CHANGE_SUCCESSFULL="DRIVER_STATUS_CHANGE_SUCCESSFULL"
export const DRIVER_STATUS_CHANGE_ERROR="DRIVER_STATUS_CHANGE_ERROR"
export const DRIVER_STATUS_CHANGE_LOADING = "DRIVER_STATUS_CHANGE_LOADING"
export const DRIVER_FILE_UPLOAD_SUCCESSFULL = "DRIVER_FILE_UPLOAD_SUCCESSFULL"
export const DRIVER_FILE_UPLOAD_ERROR = "DRIVER_FILE_UPLOAD_ERROR"
export const DRIVER_FILE_UPLOAD_LOADING = "DRIVER_FILE_UPLOAD_LOADING"
export const DRIVER_DELETE_FILE_SUCCESSFULL = "DRIVER_DELETE_FILE_SUCCESSFULL"
export const DRIVER_DELETE_FILE_ERROR = "DRIVER_DELETE_FILE_ERROR"
export const DRIVER_DELETE_FILE_LOADING = "DRIVER_DELETE_FILE_LOADING"
export const DRIVER_ADD_SUCCESSFULL="DRIVER_ADD_SUCCESSFULL"
export const DRIVER_ADD_ERROR="DRIVER_ADD_ERROR"
export const DRIVER_ADD_LOADING = "DRIVER_ADD_LOADING"
export const DRIVER_DETACHED_VEHICLE_SUCCESSFULL="DRIVER_DETACHED_VEHICLE_SUCCESSFULL"
export const DRIVER_DETACHED_VEHICLE_ERROR="DRIVER_DETACHED_VEHICLE_ERROR"
export const DRIVER_DETACHED_VEHICLE_LOADING = "DRIVER_DETACHED_VEHICLE_LOADING"
export const DRIVER_LOGOFF_SUCCESSFULL="DRIVER_LOGOFF_SUCCESSFULL"
export const DRIVER_LOGOFF_ERROR="DRIVER_LOGOFF_ERROR"
export const DRIVER_LOGOFF_LOADING = "DRIVER_LOGOFF_LOADING"
export const DRIVER_SELFIE_REQUEST_SUCCESSFULL="DRIVER_SELFIE_REQUEST_SUCCESSFULL"
export const DRIVER_SELFIE_REQUEST_ERROR="DRIVER_SELFIE_REQUEST_ERROR"
export const DRIVER_SELFIE_REQUEST_LOADING = "DRIVER_SELFIE_REQUEST_LOADING"
export const DRIVER_ASSIGN_CUSTOMER_SUCCESSFULL="DRIVER_ASSIGN_CUSTOMER_SUCCESSFULL"
export const DRIVER_ASSIGN_CUSTOMER_ERROR="DRIVER_ASSIGN_CUSTOMER_ERROR"
export const DRIVER_ASSIGN_CUSTOMER_LOADING = "DRIVER_ASSIGN_CUSTOMER_LOADING"
export const DRIVER_DETAILS_SUCCESSFULL="DRIVER_DETAILS_SUCCESSFULL"
export const DRIVER_DETAILS_ERROR="DRIVER_DETAILS_ERROR"
export const DRIVER_DETAILS_LOADING = "DRIVER_DETAILS_LOADING"
export const DRIVER_EDIT_SUCCESSFULL="DRIVER_EDIT_SUCCESSFULL"
export const DRIVER_EDIT_ERROR="DRIVER_EDIT_ERROR"
export const DRIVER_EDIT_LOADING = "DRIVER_EDIT_LOADING"
export const UNVERIFIED_DRIVER_LIST_SUCCESSFULL="UNVERIFIED_DRIVER_LIST_SUCCESSFULL"
export const UNVERIFIED_DRIVER_LIST_ERROR="UNVERIFIED_DRIVER_LIST_ERROR"
export const UNVERIFIED_DRIVER_LIST_LOADING = "UNVERIFIED_DRIVER_LIST_LOADING"
export const DRIVER_VERIFY_SUCCESSFULL = "DRIVER_VERIFY_SUCCESSFULL"
export const DRIVER_VERIFY_ERROR = "DRIVER_VERIFY_ERROR"
export const DRIVER_VERIFY_LOADING = "DRIVER_VERIFY_LOADING"
export const DRIVER_DELETE_SUCCESSFULL = "DRIVER_DELETE_SUCCESSFULL"
export const DRIVER_DELETE_ERROR = "DRIVER_DELETE_ERROR"
export const DRIVER_DELETE_LOADING = "DRIVER_DELETE_LOADING"
export const DRIVER_WORK_LIST_SUCCESSFULL="DRIVER_WORK_LIST_SUCCESSFULL"
export const DRIVER_WORK_LIST_ERROR="DRIVER_WORK_LIST_ERROR"
export const DRIVER_WORK_LIST_LOADING = "DRIVER_WORK_LIST_LOADING"
export const DRIVER_ATTENDANCE_LIST_SUCCESSFULL="DRIVER_ATTENDANCE_LIST_SUCCESSFULL"
export const DRIVER_ATTENDANCE_LIST_ERROR="DRIVER_ATTENDANCE_LIST_ERROR"
export const DRIVER_ATTENDANCE_LIST_LOADING = "DRIVER_ATTENDANCE_LIST_LOADING"
export const DRIVER_MULTIPLE_ASSIGN_LIST_SUCCESSFULL="DRIVER_MULTIPLE_ASSIGN_LIST_SUCCESSFULL"
export const DRIVER_MULTIPLE_ASSIGN_LIST_ERROR="DRIVER_MULTIPLE_ASSIGN_LIST_ERROR"
export const DRIVER_MULTIPLE_ASSIGN_LIST_LOADING = "DRIVER_MULTIPLE_ASSIGN_LIST_LOADING"
export const DRIVER_MULTIPLE_ASSIGN_DELETE_SUCCESSFULL="DRIVER_MULTIPLE_ASSIGN_DELETE_SUCCESSFULL"
export const DRIVER_MULTIPLE_ASSIGN_DELETE_ERROR="DRIVER_MULTIPLE_ASSIGN_DELETE_ERROR"
export const DRIVER_MULTIPLE_ASSIGN_DELETE_LOADING = "DRIVER_MULTIPLE_ASSIGN_DELETE_LOADING"
export const DRIVER_ASSIGN_SINGLE_SUCCESSFULL="DRIVER_ASSIGN_SINGLE_SUCCESSFULL"
export const DRIVER_ASSIGN_SINGLE_ERROR="DRIVER_ASSIGN_SINGLE_ERROR"
export const DRIVER_ASSIGN_SINGLE_LOADING = "DRIVER_ASSIGN_SINGLE_LOADING"
export const ALL_DRIVER_WORK_LIST_SUCCESSFULL = "ALL_DRIVER_WORK_LIST_SUCCESSFULL"
export const ALL_DRIVER_WORK_LIST_ERROR = "ALL_DRIVER_WORK_LIST_ERROR"
export const ALL_DRIVER_WORK_LIST_LOADING = "ALL_DRIVER_WORK_LIST_LOADING"
export const ALL_UNDELIVERED_REASON_LIST_SUCCESSFULL = "ALL_UNDELIVERED_REASON_LIST_SUCCESSFULL"
export const ALL_UNDELIVERED_REASON_LIST_ERROR = "ALL_UNDELIVERED_REASON_LIST_ERROR"
export const ALL_UNDELIVERED_REASON_LIST_LOADING = "ALL_UNDELIVERED_REASON_LIST_LOADING"
export const DRIVER_WORK_DETAILS_SUCCESSFULL = "DRIVER_WORK_DETAILS_SUCCESSFULL"
export const DRIVER_WORK_DETAILS_ERROR = "DRIVER_WORK_DETAILS_ERROR"
export const DRIVER_WORK_DETAILS_LOADING = "DRIVER_WORK_DETAILS_LOADING"
export const DRIVER_MULTIPLE_FILE_UPLOAD_SUCCESSFULL = "DRIVER_MULTIPLE_FILE_UPLOAD_SUCCESSFULL"
export const DRIVER_MULTIPLE_FILE_UPLOAD_ERROR = "DRIVER_MULTIPLE_FILE_UPLOAD_ERROR"
export const DRIVER_MULTIPLE_FILE_UPLOAD_LOADING = "DRIVER_MULTIPLE_FILE_UPLOAD_LOADING"
export const DRIVER_EDIT_WORK_SUCCESSFULL = "DRIVER_EDIT_WORK_SUCCESSFULL"
export const DRIVER_EDIT_WORK_ERROR = "DRIVER_EDIT_WORK_ERROR"
export const DRIVER_EDIT_WORK_LOADING = "DRIVER_EDIT_WORK_LOADING"
export const GET_DRIVER_LOCATION_SUCCESSFULL = "GET_DRIVER_LOCATION_SUCCESSFULL"
export const GET_DRIVER_LOCATION_ERROR = "GET_DRIVER_LOCATION_ERROR"
export const GET_DRIVER_LOCATION_LOADING = "GET_DRIVER_LOCATION_LOADING"
export const GET_DRIVER_LOGIN_REQUEST_LIST_SUCCESSFULL = "GET_DRIVER_LOGIN_REQUEST_LIST_SUCCESSFULL"
export const GET_DRIVER_LOGIN_REQUEST_LIST_ERROR = "GET_DRIVER_LOGIN_REQUEST_LIST_ERROR"
export const GET_DRIVER_LOGIN_REQUEST_LIST_LOADING = "GET_DRIVER_LOGIN_REQUEST_LIST_LOADING"
export const APPROVE_DRIVER_LOGIN_REQUEST_SUCCESSFULL = "APPROVE_DRIVER_LOGIN_REQUEST_SUCCESSFULL"
export const APPROVE_DRIVER_LOGIN_REQUEST_ERROR = "APPROVE_DRIVER_LOGIN_REQUEST_ERROR"
export const APPROVE_DRIVER_LOGIN_REQUEST_LOADING = "APPROVE_DRIVER_LOGIN_REQUEST_LOADING"
export const DELETE_DRIVER_LOGIN_REQUEST_SUCCESSFULL = "DELETE_DRIVER_LOGIN_REQUEST_SUCCESSFULL"
export const DELETE_DRIVER_LOGIN_REQUEST_ERROR = "DELETE_DRIVER_LOGIN_REQUEST_ERROR"
export const DELETE_DRIVER_LOGIN_REQUEST_LOADING = "DELETE_DRIVER_LOGIN_REQUEST_LOADING"
export const HUB_VIHICLE_LIST_SUCCESSFULL="HUB_VIHICLE_LIST_SUCCESSFULL"
export const HUB_VIHICLE_LIST_ERROR="HUB_VIHICLE_LIST_ERROR"
export const HUB_VIHICLE_LIST_LOADING = "HUB_VIHICLE_LIST_LOADING"
export const HUB_VIHICLE_STATUS_CHANGE_SUCCESSFULL = "HUB_VIHICLE_STATUS_CHANGE_SUCCESSFULL"
export const HUB_VIHICLE_STATUS_CHANGE_ERROR = "HUB_VIHICLE_STATUS_CHANGE_ERROR"
export const HUB_VIHICLE_STATUS_CHANGE_LOADING = "HUB_VIHICLE_STATUS_CHANGE_LOADING"
export const HUB_VIHICLE_PARKED_CHANGE_SUCCESSFULL = "HUB_VIHICLE_PARKED_CHANGE_SUCCESSFULL"
export const HUB_VIHICLE_PARKED_CHANGE_ERROR = "HUB_VIHICLE_PARKED_CHANGE_ERROR"
export const HUB_VIHICLE_PARKED_CHANGE_LOADING = "HUB_VIHICLE_PARKED_CHANGE_LOADING"
export const HUB_VEHICLE_TECHNICIAN_LIST_SUCCESSFULL="HUB_VEHICLE_TECHNICIAN_LIST_SUCCESSFULL"
export const HUB_VEHICLE_TECHNICIAN_LIST_ERROR="HUB_VEHICLE_TECHNICIAN_LIST_ERROR"
export const HUB_VEHICLE_TECHNICIAN_LIST_LOADING = "HUB_VEHICLE_TECHNICIAN_LIST_LOADING"
export const HUB_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL="HUB_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL"
export const HUB_VEHICLE_DASHBOARD_DETAILS_ERROR="HUB_VEHICLE_DASHBOARD_DETAILS_ERROR"
export const HUB_VEHICLE_DASHBOARD_DETAILS_LOADING = "HUB_VEHICLE_DASHBOARD_DETAILS_LOADING"
export const TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL="TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL"
export const TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_ERROR="TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_ERROR"
export const TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_LOADING = "TECHNICIAN_VEHICLE_DASHBOARD_DETAILS_LOADING"
export const TECHNICIAN_VEHICLE_LIST_SUCCESSFULL="TECHNICIAN_VEHICLE_LIST_SUCCESSFULL"
export const TECHNICIAN_VEHICLE_LIST_ERROR="TECHNICIAN_VEHICLE_LIST_ERROR"
export const TECHNICIAN_VEHICLE_LIST_LOADING = "TECHNICIAN_VEHICLE_LIST_LOADING"
export const TECHNICIAN_VEHICLE_REPAIRED_CHANGE_SUCCESSFULL = "TECHNICIAN_VEHICLE_REPAIRED_CHANGE_SUCCESSFULL"
export const TECHNICIAN_VEHICLE_REPAIRED_CHANGE_ERROR = "TECHNICIAN_VEHICLE_REPAIRED_CHANGE_ERROR"
export const TECHNICIAN_VEHICLE_REPAIRED_CHANGE_LOADING = "TECHNICIAN_VEHICLE_REPAIRED_CHANGE_LOADING"
export const HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_SUCCESSFULL = "HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_SUCCESSFULL"
export const HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_ERROR = "HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_ERROR"
export const HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_LOADING = "HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_LOADING"
export const HUB_VIHICLE_UPDATE_TECHNICIAN_SUCCESSFULL = "HUB_VIHICLE_UPDATE_TECHNICIAN_SUCCESSFULL"
export const HUB_VIHICLE_UPDATE_TECHNICIAN_ERROR = "HUB_VIHICLE_UPDATE_TECHNICIAN_ERROR"
export const HUB_VIHICLE_UPDATE_TECHNICIAN_LOADING = "HUB_VIHICLE_UPDATE_TECHNICIAN_LOADING"
export const WATCHMAN_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL="WATCHMAN_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL"
export const WATCHMAN_VEHICLE_DASHBOARD_DETAILS_ERROR="WATCHMAN_VEHICLE_DASHBOARD_DETAILS_ERROR"
export const WATCHMAN_VEHICLE_DASHBOARD_DETAILS_LOADING = "WATCHMAN_VEHICLE_DASHBOARD_DETAILS_LOADING"
export const WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_SUCCESSFULL="WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_SUCCESSFULL"
export const WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_ERROR="WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_ERROR"
export const WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_LOADING = "WATCHMAN_VEHICLE_STATUS_CHANGE_ONFIELD_LOADING"
export const WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_SUCCESSFULL="WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_SUCCESSFULL"
export const WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_ERROR="WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_ERROR"
export const WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_LOADING = "WATCHMAN_VEHICLE_STATUS_CHANGE_PARKED_LOADING"
export const BIKE_TYPE_LIST_LOADING = "BIKE_TYPE_LIST_LOADING"
export const BIKE_TYPE_LIST_SUCCESSFULL = "BIKE_TYPE_LIST_SUCCESSFULL"
export const BIKE_TYPE_LIST_ERROR = "BIKE_TYPE_LIST_ERROR"
export const ADD_BIKE_TYPE_SUCCESSFULL = "ADD_BIKE_TYPE_SUCCESSFULL"
export const ADD_BIKE_TYPE_ERROR = "ADD_BIKE_TYPE_ERROR"
export const ADD_BIKE_TYPE_LOADING = "ADD_BIKE_TYPE_LOADING"
export const BIKE_TYPE_STATUS_CHANGE_SUCCESSFULL = "BIKE_TYPE_STATUS_CHANGE_SUCCESSFULL"
export const BIKE_TYPE_STATUS_CHANGE_ERROR = "BIKE_TYPE_STATUS_CHANGE_ERROR"
export const BIKE_TYPE_STATUS_CHANGE_LOADING = "BIKE_TYPE_STATUS_CHANGE_LOADING"
export const BIKE_TYPE_DETAILS_SUCCESSFULL = "BIKE_TYPE_DETAILS_SUCCESSFULL"
export const BIKE_TYPE_DETAILS_ERROR = "BIKE_TYPE_DETAILS_ERROR"
export const BIKE_TYPE_DETAILS_LOADING = "BIKE_TYPE_DETAILS_LOADING"
export const BIKE_TYPE_EDIT_SUCCESSFULL = "BIKE_TYPE_EDIT_SUCCESSFULL"
export const BIKE_TYPE_EDIT_ERROR = "BIKE_TYPE_EDIT_ERROR"
export const BIKE_TYPE_EDIT_LOADING = "BIKE_TYPE_EDIT_LOADING"
export const BIKE_TYPE_DELETE_SUCCESSFULL = "BIKE_TYPE_DELETE_SUCCESSFULL"
export const BIKE_TYPE_DELETE_ERROR = "BIKE_TYPE_DELETE_ERROR"
export const BIKE_TYPE_DELETE_LOADING = "BIKE_TYPE_DELETE_LOADING"
export const WATCHMAN_GET_VEHICLE_DETAILS_SUCCESSFULL="WATCHMAN_GET_VEHICLE_DETAILS_SUCCESSFULL"
export const WATCHMAN_GET_VEHICLE_DETAILS_ERROR="WATCHMAN_GET_VEHICLE_DETAILS_ERROR"
export const WATCHMAN_GET_VEHICLE_DETAILS_LOADING = "WATCHMAN_GET_VEHICLE_DETAILS_LOADING"
export const CITY_LIST_SUCCESSFULL="CITY_LIST_SUCCESSFULL"
export const CITY_LIST_ERROR="CITY_LIST_ERROR"
export const CITY_LIST_LOADING = "CITY_LIST_LOADING"
export const STATE_LIST_SUCCESSFULL="STATE_LIST_SUCCESSFULL"
export const STATE_LIST_ERROR="STATE_LIST_ERROR"
export const STATE_LIST_LOADING = "STATE_LIST_LOADING"
export const CITY_LIST_BY_STATE_SUCCESSFULL="CITY_LIST_BY_STATE_SUCCESSFULL"
export const CITY_LIST_BY_STATE_ERROR="CITY_LIST_BY_STATE_ERROR"
export const CITY_LIST_BY_STATE_LOADING = "CITY_LIST_BY_STATE_LOADING"
export const DRIVER_SET_DEFAULT_CUSTOMER_SUCCESSFULL="DRIVER_SET_DEFAULT_CUSTOMER_SUCCESSFULL"
export const DRIVER_SET_DEFAULT_CUSTOMER_ERROR="DRIVER_SET_DEFAULT_CUSTOMER_ERROR"
export const DRIVER_SET_DEFAULT_CUSTOMER_LOADING = "DRIVER_SET_DEFAULT_CUSTOMER_LOADING"
export const GET_ADMIN_PROFILE_SUCCESSFULL="GET_ADMIN_PROFILE_SUCCESSFULL"
export const GET_ADMIN_PROFILE_ERROR="GET_ADMIN_PROFILE_ERROR"
export const GET_ADMIN_PROFILE_LOADING = "GET_ADMIN_PROFILE_LOADING"
export const UPDATE_ADMIN_PROFILE_SUCCESSFULL="UPDATE_ADMIN_PROFILE_SUCCESSFULL"
export const UPDATE_ADMIN_PROFILE_ERROR="UPDATE_ADMIN_PROFILE_ERROR"
export const UPDATE_ADMIN_PROFILE_LOADING = "UPDATE_ADMIN_PROFILE_LOADING"
export const BIKE_TYPE_LIST_SELECT_LOADING = "BIKE_TYPE_LIST_SELECT_LOADING"
export const BIKE_TYPE_LIST_SELECT_SUCCESSFULL = "BIKE_TYPE_LIST_SELECT_SUCCESSFULL"
export const BIKE_TYPE_LIST_SELECT_ERROR = "BIKE_TYPE_LIST_SELECT_ERROR"
export const DRIVER_LIST_SELECT_LOADING = "DRIVER_LIST_SELECT_LOADING"
export const DRIVER_LIST_SELECT_SUCCESSFULL = "DRIVER_LIST_SELECT_SUCCESSFULL"
export const DRIVER_LIST_SELECT_ERROR = "DRIVER_LIST_SELECT_ERROR"
export const DRIVER_LIST_VEHICLE_USE_LOADING = "DRIVER_LIST_VEHICLE_USE_LOADING"
export const DRIVER_LIST_VEHICLE_USE_SUCCESSFULL = "DRIVER_LIST_VEHICLE_USE_SUCCESSFULL"
export const DRIVER_LIST_VEHICLE_USE_ERROR = "DRIVER_LIST_VEHICLE_USE_ERROR"
export const DASHBOARD_VEHICLE_DETAILS_LOADING = "DASHBOARD_VEHICLE_DETAILS_LOADING"
export const DASHBOARD_VEHICLE_DETAILS_SUCCESSFULL = "DASHBOARD_VEHICLE_DETAILS_SUCCESSFULL"
export const DASHBOARD_VEHICLE_DETAILS_ERROR = "DASHBOARD_VEHICLE_DETAILS_ERROR"
export const DASHBOARD_DRIVER_DETAILS_LOADING = "DASHBOARD_DRIVER_DETAILS_LOADING"
export const DASHBOARD_DRIVER_DETAILS_SUCCESSFULL = "DASHBOARD_DRIVER_DETAILS_SUCCESSFULL"
export const DASHBOARD_DRIVER_DETAILS_ERROR = "DASHBOARD_DRIVER_DETAILS_ERROR"
export const DRIVER_ISSUES_LIST_LOADING = "DRIVER_ISSUES_LIST_LOADING"
export const DRIVER_ISSUES_LIST_SUCCESSFULL = "DRIVER_ISSUES_LIST_SUCCESSFULL"
export const DRIVER_ISSUES_LIST_ERROR = "DRIVER_ISSUES_LIST_ERROR"
export const DRIVER_ISSUES_IMAGES_LOADING = "DRIVER_ISSUES_IMAGES_LOADING"
export const DRIVER_ISSUES_IMAGES_SUCCESSFULL = "DRIVER_ISSUES_IMAGES_SUCCESSFULL"
export const DRIVER_ISSUES_IMAGES_ERROR = "DRIVER_ISSUES_IMAGES_ERROR"
export const GET_ALL_ATTENDANCE_LOADING = "GET_ALL_ATTENDANCE__LOADING"
export const GET_ALL_ATTENDANCE_SUCCESSFULL = "GET_ALL_ATTENDANCE_SUCCESSFULL"
export const GET_ALL_ATTENDANCE_ERROR = "GET_ALL_ATTENDANCE_ERROR"
export const GET_ALL_DRIVER_ATTENDANCE_LOADING = "GET_ALL_DRIVER_ATTENDANCE_LOADING"
export const GET_ALL_DRIVER_ATTENDANCE_SUCCESSFULL = "GET_ALL_DRIVER_ATTENDANCE_SUCCESSFULL"
export const GET_ALL_DRIVER_ATTENDANCE_ERROR = "GET_ALL_DRIVER_ATTENDANCE_ERROR"
export const GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_LOADING = "GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_LOADING"
export const GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_SUCCESSFULL = "GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_SUCCESSFULL"
export const GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_ERROR = "GET_ALL_DRIVER_ATTENDANCE_AND_PARCEL_ERROR"
export const HUB_VIHICLE_UPDATE_HUB_SUCCESSFULL = "HUB_VIHICLE_UPDATE_HUB_SUCCESSFULL"
export const HUB_VIHICLE_UPDATE_HUB_ERROR = "HUB_VIHICLE_UPDATE_HUB_ERROR"
export const HUB_VIHICLE_UPDATE_HUB_LOADING = "HUB_VIHICLE_UPDATE_HUB_LOADING"
export const HUB_VIHICLE_UPDATE_CUSTOMER_SUCCESSFULL = "HUB_VIHICLE_UPDATE_CUSTOMER_SUCCESSFULL"
export const HUB_VIHICLE_UPDATE_CUSTOMER_ERROR = "HUB_VIHICLE_UPDATE_CUSTOMER_ERROR"
export const HUB_VIHICLE_UPDATE_CUSTOMER_LOADING = "HUB_VIHICLE_UPDATE_CUSTOMER_LOADING"
export const DASHBOARD_CUSTOMER_DETAILS_DAILY_SUCCESSFULL = "DASHBOARD_CUSTOMER_DETAILS_DAILY_SUCCESSFULL"
export const DASHBOARD_CUSTOMER_DETAILS_DAILY_ERROR = "DASHBOARD_CUSTOMER_DETAILS_DAILY_ERROR"
export const DASHBOARD_CUSTOMER_DETAILS_DAILY_LOADING = "DASHBOARD_CUSTOMER_DETAILS_DAILY_LOADING"