import React from 'react'
import { format } from 'date-fns'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
export const ExportToExcel = props => {
    const apiData = props.apiData;
    const startDate = props.startDate;
    const endDate = props.endDate;
	const changeDateFormate = (date) => {
		if (date) {
			let new_date = String(date);
			let ndate = new_date.split('T');
			let format_date = format(new Date(ndate[0]), 'MMM yyyy');
			return format_date;
		}
	}
    const changeDate = (date) => {
		if (date) {
			let cd = new Date(date);
            let format_date = format(new Date(cd), 'MMM yyyy');
            return format_date;
		}
	}
    return (
      <>
	  	<ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success" table="AllAttendanceReportByDriver" filename="AllAttendanceReportByDriver" sheet="tablexls" buttonText="Export as XLS"/>
          <table id="AllAttendanceReportByDriver" style={{display:'none', textAlign:"center"}}>
			<thead>
				<tr style={{padding: '45px'}}>
					<th colSpan={3} style={{border:'1px solid black', textAlign:'center', backgroundColor:'#9fb95a'}}>Monthly</th>
				</tr>
                <tr style={{padding: '45px'}}>
					<th colSpan={3} style={{border:'1px solid black', textAlign:'center', backgroundColor:'#bedcf1'}}>{`${changeDate(startDate)}`}</th>
				</tr>
				<tr style={{padding: '45px'}}>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Driver Id</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Driver Name</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Total Present</th>
				</tr>
			</thead>
			<tbody>
				{
					apiData.map((item)=>(
						<tr style={{textAlign: 'center'}}>
							<td style={{border:'1px solid black'}}>EV{item.Driver_Id}</td>
							<td style={{border:'1px solid black'}}>{item.name}</td>
							<td style={{border:'1px solid black'}}>{item.attendance}</td>
						</tr>
					))
				}
			</tbody>
		</table>
	  </>
    );
};