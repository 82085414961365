import {
    HUB_VIHICLE_LIST_SUCCESSFULL, 
    HUB_VIHICLE_LIST_ERROR,
    HUB_VIHICLE_LIST_LOADING,
    HUB_VIHICLE_STATUS_CHANGE_SUCCESSFULL,
    HUB_VIHICLE_STATUS_CHANGE_ERROR,
    HUB_VIHICLE_STATUS_CHANGE_LOADING,
    HUB_VIHICLE_PARKED_CHANGE_SUCCESSFULL,
    HUB_VIHICLE_PARKED_CHANGE_ERROR,
    HUB_VIHICLE_PARKED_CHANGE_LOADING,
    HUB_VEHICLE_TECHNICIAN_LIST_SUCCESSFULL,
    HUB_VEHICLE_TECHNICIAN_LIST_ERROR,
    HUB_VEHICLE_TECHNICIAN_LIST_LOADING,
    HUB_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL,
    HUB_VEHICLE_DASHBOARD_DETAILS_ERROR,
    HUB_VEHICLE_DASHBOARD_DETAILS_LOADING,
    HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_SUCCESSFULL,
    HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_ERROR,
    HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_LOADING,
    HUB_VIHICLE_UPDATE_HUB_SUCCESSFULL,
    HUB_VIHICLE_UPDATE_HUB_ERROR,
    HUB_VIHICLE_UPDATE_HUB_LOADING,
    HUB_VIHICLE_UPDATE_TECHNICIAN_LOADING,
    HUB_VIHICLE_UPDATE_TECHNICIAN_SUCCESSFULL,
    HUB_VIHICLE_UPDATE_TECHNICIAN_ERROR,
    HUB_VIHICLE_UPDATE_CUSTOMER_LOADING,
    HUB_VIHICLE_UPDATE_CUSTOMER_SUCCESSFULL,
    HUB_VIHICLE_UPDATE_CUSTOMER_ERROR,
    VIHICLE_HUB_LIST_SELECT_SUCCESSFULL,
    VIHICLE_HUB_LIST_SELECT_ERROR,
    VIHICLE_HUB_LIST_SELECT_LOADING,
    CUSTOMER_LIST_SUCCESSFULL,
    CUSTOMER_LIST_ERROR, 
    CUSTOMER_LIST_LOADING,
} from '../../action/type';

import * as AuthService from '../../service/services'

export const Vehicle_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: HUB_VIHICLE_LIST_LOADING,
                data: true
            })
            AuthService.HubVehicleList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:HUB_VIHICLE_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:HUB_VIHICLE_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_status_change=(vehicle_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: HUB_VIHICLE_STATUS_CHANGE_LOADING,
                data: true
            })
            AuthService.VehicleStatusChange(vehicle_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:HUB_VIHICLE_STATUS_CHANGE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:HUB_VIHICLE_STATUS_CHANGE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_parked_change=(vehicle_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: HUB_VIHICLE_PARKED_CHANGE_LOADING,
                data: true
            })
            AuthService.VehicleHubParkedChange(vehicle_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:HUB_VIHICLE_PARKED_CHANGE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:HUB_VIHICLE_PARKED_CHANGE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const Vehicle_technician_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: HUB_VEHICLE_TECHNICIAN_LIST_LOADING,
                data: true
            })
            AuthService.HubVehicleTechnicianList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:HUB_VEHICLE_TECHNICIAN_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:HUB_VEHICLE_TECHNICIAN_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const Vehicle_hub_dashboard_details=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: HUB_VEHICLE_DASHBOARD_DETAILS_LOADING,
                data: true
            })
            AuthService.HubVehicleDashboardDetails()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:HUB_VEHICLE_DASHBOARD_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:HUB_VEHICLE_DASHBOARD_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_update_deactive_reason=(credentail)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_LOADING,
                data: true
            })
            AuthService.UpdateVehicleDeactiveReason(credentail)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:HUB_VIHICLE_UPDATE_DEACTIVATE_REASON_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_update_technician=(credentail)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: HUB_VIHICLE_UPDATE_TECHNICIAN_LOADING,
                data: true
            })
            AuthService.UpdateVehicleTechnician(credentail)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:HUB_VIHICLE_UPDATE_TECHNICIAN_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:HUB_VIHICLE_UPDATE_TECHNICIAN_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_update_hub=(credentail)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: HUB_VIHICLE_UPDATE_HUB_LOADING,
                data: true
            })
            AuthService.UpdateVehicleHub(credentail)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:HUB_VIHICLE_UPDATE_HUB_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:HUB_VIHICLE_UPDATE_HUB_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_update_customer=(credentail)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: HUB_VIHICLE_UPDATE_CUSTOMER_LOADING,
                data: true
            })
            AuthService.UpdateVehicleCustomer(credentail)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:HUB_VIHICLE_UPDATE_CUSTOMER_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:HUB_VIHICLE_UPDATE_CUSTOMER_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_hub_list_select=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VIHICLE_HUB_LIST_SELECT_LOADING,
                data: true
            })
            AuthService.VehicleHubsListSelect()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VIHICLE_HUB_LIST_SELECT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VIHICLE_HUB_LIST_SELECT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const customer_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: CUSTOMER_LIST_LOADING,
                data: true
            })
            AuthService.CustomerList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:CUSTOMER_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:CUSTOMER_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}