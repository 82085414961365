import {LOGIN_LOADING,LOGIN_SUCCESSFULL,LOGIN_ERROR, NOTIFICATION_SUCCESSFULL, NOTIFICATION_ERROR, NOTIFICATION_LOADING, LOGOUT_SUCCESSFULL, LOGOUT_ERROR, LOGOUT_LOADING, STATE_LIST_SUCCESSFULL, STATE_LIST_LOADING, STATE_LIST_ERROR, CITY_LIST_SUCCESSFULL, CITY_LIST_LOADING, CITY_LIST_ERROR, CITY_LIST_BY_STATE_SUCCESSFULL, CITY_LIST_BY_STATE_LOADING, CITY_LIST_BY_STATE_ERROR} from '../../action/type';

import * as AuthService from '../../service/services'

export const login=(credentails)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: LOGIN_LOADING,
                data: true
            })
            AuthService.LogIn(credentails)
            .then((response)=>{
                if(response.status===200)   
                {
                    localStorage.setItem("token",response.data.token)
                    localStorage.setItem("userrole",response.data.role)
                        dispatch({
                            type:LOGIN_SUCCESSFULL,
                            data:response.data
                        })
                        return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:LOGIN_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const notification=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: NOTIFICATION_LOADING,
                data: true
            })
            AuthService.NotificationList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:NOTIFICATION_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:NOTIFICATION_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const logout=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: LOGOUT_LOADING,
                data: true
            })
            AuthService.logout()
            .then((response)=>{
                if(response.status===200)   
                {
                    localStorage.removeItem("token")
                    localStorage.removeItem("userrole")
                    localStorage.removeItem("notification-token")
                    dispatch({
                        type:LOGOUT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:LOGOUT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const state_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: STATE_LIST_LOADING,
                data: true
            })
            AuthService.getStateList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:STATE_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:STATE_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const city_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: CITY_LIST_LOADING,
                data: true
            })
            AuthService.getCityList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:CITY_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:CITY_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const city_list_by_state=(stateId)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: CITY_LIST_BY_STATE_LOADING,
                data: true
            })
            AuthService.getCityListByState(stateId)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:CITY_LIST_BY_STATE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:CITY_LIST_BY_STATE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}