import React from 'react'
import { format } from 'date-fns'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
export const ExportToExcel = props => {
    const vehicleData = props.vehicleData;
	const changeDateFormate = (date) => {
        if (date) {
            let new_date = String(date);
            let ndate = new_date.split('T');
            let format_date = format(new Date(ndate[0]), 'dd MMM yyyy');
            return format_date;
        }
    }
    return (
      <>
	  	<ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success" table="getDetails" filename="vehicleUseReport" sheet="tablexls" buttonText="Export as XLS"/>
		<table id="getDetails" style={{display:'none'}}>
			<thead>
				<tr>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Date</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Driver</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Vehicle Number</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Type In Wheeler</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Customer</th>
				</tr>
			</thead>
			<tbody>
				{
					vehicleData.map((item)=>(
						<tr style={{textAlign: 'center'}}>
							<td>{changeDateFormate(item.createdAt)}</td>
							<td>{item.driverData.name}</td>
							<td>{item.vehicleData.number ? item.vehicleData.number:item.vehicleData.name}</td>
							<td>{item.vehicleData.type}</td>
							<td>{item.customerData.businessName+' - '+ item.customerData.name + ' - '+item.customerData.mobile}</td>
						</tr>
					))
				}
			</tbody>
		</table>
	  </>
    );
};