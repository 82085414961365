import {GET_ALL_DRIVER_ATTENDANCE_SUCCESSFULL, GET_ALL_DRIVER_ATTENDANCE_ERROR, GET_ALL_DRIVER_ATTENDANCE_LOADING} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case GET_ALL_DRIVER_ATTENDANCE_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case GET_ALL_DRIVER_ATTENDANCE_SUCCESSFULL:{
            return Object.assign({},state,{allDriverAttendanceData: action.data, loading: false})
        }
        case GET_ALL_DRIVER_ATTENDANCE_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}