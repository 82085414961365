import React,{useEffect, useState, useRef}  from 'react'
import { useNavigate }from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Footer from '../../DashBoard/Footer';
import Loader from '../../common/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import QrReader from "react-qr-reader";
import * as action from '../../../action/WatchmanVehiclesAdmin/vehicle_action';
import _ from "lodash";
import './DashBoard.css';
import $ from 'jquery'
const AddAdmin = props => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState("environment");
    const [startScan, setStartScan] = useState(false);
    const [outStartScan, setOutStartScan] = useState(false);
    const [loadingScan, setLoadingScan] = useState(false);
    const [outLoadingScan, setOutLoadingScan] = useState(false);
    const [data, setData] = useState("");
    const [outdata, setOutData] = useState("");
    const [dashboardDetails,setDashboardDetails]=useState({});
    const scanInRef = useRef(null);
    const scanOutRef = useRef(null);
    const MySwal = withReactContent(Swal);
    const InHandleScan = async (scanData) => {
        setLoadingScan(true);
        if (scanData && scanData !== "") {
            dispatch(action.get_vehicle_details(scanData)).then((data)=>{
                let vehicleDetails = data.data;
                // Swal.fire({
                //     title: 'Are you sure?',
                //     text: "You want to park this vehicle! : "+vehicleDetails.number,
                //     icon: 'warning',
                //     showCancelButton: true,
                //     confirmButtonColor: '#3085d6',
                //     cancelButtonColor: '#d33',
                //     confirmButtonText: 'Yes, Park it!'
                // }).then((result) => {
                //     if (result.isConfirmed) {
                        dispatch(action.vehicle_watchman_change_status_parked(scanData)).then((data1)=>{
                            toast.success(data1.msg);
                            setData(scanData);
                            setStartScan(false);
                            setLoadingScan(false);
                            dispatch(action.Vehicle_watchman_dashboard_details()).then((data2)=>{
                                setDashboardDetails(data2.data)
                            }).catch({})
                        }).catch(error=>{
                            toast.error(error);
                            setStartScan(false);
                        })
                //     } else {
                //         setStartScan(false);
                //     }
                // })
            }).catch(error=>{
                toast.error(error);
                setStartScan(false);
            })
            $("#showInModalCenter .close").click();
        }
    };
    const InHandleError = (err) => {
        console.error(err);
        setStartScan(false);
    };
    const outHandleScan = async (scanData) => {
        setLoadingScan(true);
        if (scanData && scanData !== "") {
            dispatch(action.get_vehicle_details(scanData)).then((data)=>{
                let vehicleDetails = data.data;
                // Swal.fire({
                //     title: 'Are you sure?',
                //     text: "You want to on field this vehicle! : "+vehicleDetails.number,
                //     icon: 'warning',
                //     showCancelButton: true,
                //     confirmButtonColor: '#3085d6',
                //     cancelButtonColor: '#d33',
                //     confirmButtonText: 'Yes, On Field it!'
                // }).then((result) => {
                //     if (result.isConfirmed) {
                        dispatch(action.vehicle_watchman_change_status_on_field(scanData)).then((data1)=>{
                            toast.success(data1.msg);
                            setOutData(scanData);
                            setOutStartScan(false);
                            setOutLoadingScan(false);
                            dispatch(action.Vehicle_watchman_dashboard_details()).then((data2)=>{
                                setDashboardDetails(data2.data)
                                setOutStartScan(false);
                            }).catch({})
                            
                        }).catch(error=>{
                            toast.error(error);
                            setOutStartScan(false);
                        })
                //     } else {
                //         setOutStartScan(false);
                //     }
                // })
            }).catch(error=>{
                toast.error(error);
                setOutStartScan(false);
            })
            $("#showOutModalCenter .close").click();
        }
    };
    const outHandleError = (err) => {
        console.error(err);
    };
    const scanOutStopCamara = () => {
        setOutStartScan(false);
    }
    const scanInStopCamara = () => {
        setStartScan(false);
    }
    const scanWhileInVehicle = () => {
        setStartScan(true)
    }
    const scanWhileOutVehicle = () => {
        setOutStartScan(true)
    }
    useEffect(() => {
        dispatch(action.Vehicle_watchman_dashboard_details()).then((data)=>{
            setDashboardDetails(data.data)
        }).catch({})
    },[])
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover  theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">

                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing">
                        <div className="row layout-top-spacing">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
                                <div className="widget-one" style={{background:'#FFFFFF'}}>
                                    <div className="widget-content">
                                        <div className="w-numeric-value">
                                            <div className="w-content w-100 d-flex">
                                                <button className="btn btn-success w-50" data-toggle="modal" data-target="#showInModalCenter" onClick={() => { scanWhileInVehicle(); }}>In</button>&nbsp;
                                                <button className="btn btn-danger w-50" data-toggle="modal" data-target="#showOutModalCenter" onClick={() => { scanWhileOutVehicle(); }}>Out</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
                                <div className="widget-one" style={{background:'#198754'}}>
                                    <div className="widget-content">
                                        <div className="w-numeric-value">
                                            <div className="w-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                            </div>
                                            <div className="w-content">
                                                <span className="w-value" id="totalParcelsId">{dashboardDetails.totalNoOfVehicle}</span>
                                                <span className="w-numeric-title">Total No. Of Vehicles</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
                                <div className="widget-one">
                                    <div className="widget-content">
                                        <div className="w-numeric-value">
                                            <div className="w-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                            </div>
                                            <div className="w-content">
                                                <span className="w-value" id="totalParcelsId">{dashboardDetails.noOfParkedVehicles}</span>
                                                <span className="w-numeric-title">No. Of Parked Vehicles</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
                                <div className="widget-one">
                                    <div className="widget-content">
                                        <div className="w-numeric-value">
                                            <div className="w-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                            </div>
                                            <div className="w-content">
                                                <span className="w-value" id="totalParcelsId">{dashboardDetails.noOfOnFiledVehicles}</span>
                                                <span className="w-numeric-title">No. Of On Field Vehicles</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <div className="modal fade" id="showInModalCenter" tabindex="-1" role="dialog" aria-labelledby="showImageModalCenterLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="img">
                    <div className="modal-content">       
                        <div className="modal-header">
                            <h5 className="modal-title">Scan Vehicle - In</h5>
                            <button type="button" onClick={scanInStopCamara} className="close" data-dismiss="modal" aria-label="Close">
                                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                            </button>
                        </div>    
                        <div className="modal-body">
                                {/* <button className={startScan ? 'btn btn-danger form-control' : 'btn btn-success form-control'} onClick={() => { setStartScan(!startScan); }}>{startScan ? "Stop In Scan" : "Start In Scan"}</button> */}
                            {startScan && (
                                <>
                                {/* <select className="form-control" onChange={(e) => setSelected(e.target.value)}>
                                    <option value={"environment"}>Back Camera</option>
                                    <option value={"user"}>Front Camera</option>
                                </select> */}
                                <QrReader
                                    facingMode={selected}
                                    delay={1000}
                                    onError={InHandleError}
                                    onScan={InHandleScan}
                                    // chooseDeviceId={()=>selected}
                                    style={{ width: "100%" }}
                                />
                                </>
                            )}
                            {/* {loadingScan && <p>Loading</p>}
                            {data !== "" && <p>{data}</p>} */}
                        </div>            
                    </div>
                </div>
            </div>
            <div className="modal fade" id="showOutModalCenter" tabindex="-1" role="dialog" aria-labelledby="showImageModalCenterLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="img">
                    <div className="modal-content">   
                        <div className="modal-header">
                        <h5 className="modal-title">Scan Vehicle - Out</h5>
                            <button type="button" onClick={scanOutStopCamara} className="close" data-dismiss="modal" aria-label="Close">
                                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                            </button>
                        </div>      
                        <div className="modal-body">
                                {/* <button className={outStartScan ? 'btn btn-danger form-control' : 'btn btn-success form-control'} onClick={() => { setOutStartScan(!outStartScan); }}>{outStartScan ? "Stop Out Scan" : "Start Out Scan"}</button> */}
                            {outStartScan && (
                                <>
                                {/* <select className="form-control" onChange={(e) => setSelected(e.target.value)}>
                                    <option value={"environment"}>Back Camera</option>
                                    <option value={"user"}>Front Camera</option>
                                </select> */}
                                <QrReader
                                    facingMode={selected}
                                    delay={1000}
                                    onError={outHandleError}
                                    onScan={outHandleScan}
                                    // chooseDeviceId={()=>selected}
                                    style={{ width: "100%" }}
                                    ref={scanOutRef}
                                />
                                </>
                            )}
                            {/* {outLoadingScan && <p>Loading</p>}
                            {data !== "" && <p>{outdata}</p>} */}
                        </div>            
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddAdmin