import {CUSTOMER_GENERATE_INVOICE_SUCCESSFULL,CUSTOMER_GENERATE_INVOICE_ERROR,CUSTOMER_GENERATE_INVOICE_LOADING} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case CUSTOMER_GENERATE_INVOICE_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case CUSTOMER_GENERATE_INVOICE_SUCCESSFULL:{
            return Object.assign({},state,{customerGenerateInvoiceData: action.data, loading: false})
        }
        case CUSTOMER_GENERATE_INVOICE_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}