import React, { useEffect, useRef, useState} from 'react'
import { useNavigate, useParams }from 'react-router-dom'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Footer from '../../DashBoard/Footer';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select'
import AsyncSelect from "react-select/async";
import { DatePicker } from 'rsuite';
import { format } from 'date-fns'
import Loader from '../../common/Loader/Loader';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/Driver/driver_action';
import $ from 'jquery'
import {baseURL} from '../../../service/baseService';
const View_Driver_Work_history = props => {
    $.DataTable = require('datatables.net')
    var moment = require('moment');
    const dispatch = useDispatch();
    const [modalImgURL, setModalImgURL]=useState("");
    const {driver_id} = useParams();
    const {loading} = useSelector(state => state.AllDriverWorkListReduces)
    const [driverWorkDetails,setDriverWorkDetails]=useState({
        parcelImageUrls:[],
    });
    useEffect(() => {
        dispatch(action.driver_work_details(driver_id)).then((data)=>{
            setDriverWorkDetails({
                parcelImageUrls:data.data.parcelImages,
            })
        }).catch({})
    },[])
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing">

                        <div className="row layout-top-spacing">
                            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                <div className="widget-content widget-content-area br-6">
                                    <div className="input-group edit-btn-group">
                                        <h5 className="">Driver Work Images</h5>
                                    </div>
                                    <br/>

                                    <form>
                                        <div className="form-group">
                                            <div id="append-div-id">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="thumbnail">
                                                            {
                                                                driverWorkDetails.parcelImageUrls.map((images)=>(
                                                                    <>
                                                                        <img src={`${baseURL}${images}`} alt="no-image" id="panImgId" className="p-2 img-responsive imgFile" onClick={ ()=>setModalImgURL(images)} data-toggle="modal" data-target="#showImageModalCenter" style={{width: "250px", height: "250px"}} />
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal fade" id="showImageModalCenter" tabindex="-1" role="dialog"
                                                    aria-labelledby="showImageModalCenterLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered modal-lg" role="img">
                                                        <div className="modal-content">
                                                            <div className="modal-body">
                                                            {
                                                                modalImgURL !== '' ? <img src={`${baseURL}${modalImgURL}`} alt="no-image" id="modalImgId" className="img-responsive" style={{width: "100%", height: "100%"}} />: ''
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}
export default View_Driver_Work_history