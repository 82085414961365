import { CUSTOMER_INVOICE_ADDED_LOADING,
    CUSTOMER_INVOICE_ADDED_SUCCESSFULL,
    CUSTOMER_INVOICE_ADDED_ERROR} from '../../action/type';

const INITIAL_STATE={
    addedCustomerInvoice: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case CUSTOMER_INVOICE_ADDED_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case CUSTOMER_INVOICE_ADDED_SUCCESSFULL:{
            return Object.assign({},state,{addedCustomerInvoice: action.data, loading: false})
        }
        case CUSTOMER_INVOICE_ADDED_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}