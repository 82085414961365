import React, { useEffect, useRef, useState} from 'react'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'date-fns'
import Footer from '../../DashBoard/Footer';
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Loader from '../../common/Loader/Loader';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/VehicleTelematicsUnit/vehicle_telematics_unit_action';
import $ from 'jquery'
import { renderToString } from 'react-dom/server'
function Vehicle_Telematics_Unit() {
    $.DataTable = require('datatables.net')
    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.VehicleTelematicsUnitListReducer)
    const tableRef = useRef();
    const [disabled, setDisabled]=useState(true);
    const [editButtonHide, setEditButtonHide]=useState(false);
    const [cancelButtonHide, setCancelButtonHide]=useState(true);
    const [submitButtonHide, setSubmitButtonHide]=useState(true);
    const [telematicsUnitList,setTelematicsUnitListList]=useState([]);
    const [addTelematicsUnit , setAddTelematicsUnit]= useState({
        name: "",
        telematicsNumber: "",
        subscriptionDate: "",
        renewadDate: "",
        isActive: 1,
    });
    const [editTelematicsUnit , setEditTelematicsUnit]= useState({
        id:"",
        name: "",
        telematicsNumber: "",
        subscriptionDate: "",
        renewadDate: "",
        isActive: 1,
    });
    const setEditFormEditable = (type) => {
        if(type){
            setDisabled(false);
            setCancelButtonHide(false);
            setSubmitButtonHide(false);
            setEditButtonHide(true);
        } else {
            setDisabled(true);
            setCancelButtonHide(true);
            setSubmitButtonHide(true);
            setEditButtonHide(false);
        }
    }
    const setUpdateTelematicsUnit = (telematicsUnit) => {
        let new_telematics_unit = telematicsUnit[7];
        setEditTelematicsUnit({
            id:new_telematics_unit._id,
            name: new_telematics_unit.name,
            telematicsNumber: new_telematics_unit.telematicsNumber,
            subscriptionDate: new_telematics_unit.subscriptionDate,
            renewadDate: (new_telematics_unit.renewadDate) ? new_telematics_unit.renewadDate:'',
            isActive: parseInt(new_telematics_unit.isActive),
        });
    }
    const changeDateFormate = (date) =>{
        if (date) {
            let new_date = String(date);
            let ndate = new_date.split('T');
            return ndate[0];
        }
    }
    const activeDeactiveAlertPopup = (telematics) => {
        dispatch(action.vehicle_telematics_unit_status_change(telematics[6])).then((data)=>{
            toast.success(data.msg);
            let newTelematicsList = [];
            telematicsUnitList.map(item => {
                if(item[6] === telematics[6]){
                    let telematics_data = data.data;
                    newTelematicsList.push([telematics_data.name, telematics_data.telematicsNumber, changeDateFormate(telematics_data.subscriptionDate), telematics_data.renewadDate ? changeDateFormate(telematics_data.renewadDate):null, telematics_data.isActive, `<a style="cursor: pointer;" href="/adminside/vehicleTelematicsUnit/view/${telematics_data._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${telematics_data._id}">View</a>`, telematics_data._id, telematics_data]);
                } else {
                    newTelematicsList.push(item);
                }
            })
            setTelematicsUnitListList(newTelematicsList)
        }).catch(error=>{
            toast.error(error);
        })
    }
    const tableName = "vehicle-telematics-unit-list";
    useEffect(() => {
        dispatch(action.vehicle_telematics_unit_list()).then((data)=>{
            let newTelematicsList = [];
            data.data.map(item => {
                newTelematicsList.push([item.name, item.telematicsNumber, changeDateFormate(item.subscriptionDate), item.renewadDate ? changeDateFormate(item.renewadDate): null, item.isActive, `<a style="cursor: pointer;" href="/adminside/vehicleTelematicsUnit/view/${item._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${item._id}">View</a>`, item._id, item]);
            })
            setTelematicsUnitListList(newTelematicsList)
        }).catch({})
    },[])
    useEffect(()=>{
        const table = $(`#${tableName}`).DataTable(
            {
                dom: 'Bfrtip',
                buttons: [
                    'copyHtml5', 'excelHtml5', 'pdfHtml5', 'csvHtml5'
                ],
                data: telematicsUnitList,
                columns: [
                    { title: "Name"},
                    { title: "No."},
                    { title: "Subscription Date"},
                    { title: "Renewad Date"},
                    { title: "Status"},
                    { title: "Action"}
                ],
                columnDefs: [
                    {
                        targets: [4],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <a style={{cursor: "pointer"}} className={rowData[4] === 1 ? 'btn btn-success btn-sm status-act-btn' : 'btn btn-danger btn-sm status-act-btn'} onClick={() => activeDeactiveAlertPopup(rowData)}> {rowData[4] === 1 ? 'Active' : 'Deactive'} </a>,td
                        )
                    }, 
                    {
                        targets: [5],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <a style={{cursor: "pointer"}} data-toggle="modal" onClick={() => setUpdateTelematicsUnit(rowData)} data-target="#editTelematicsUnitModalCenter" class="btn btn-info btn-sm view-btn view-act-btn">View</a>,td
                        )
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                oLanguage: {
                    oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                    //sInfo: "Showing page _PAGE_ of _PAGES_",
                    sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                    sSearchPlaceholder: "Search...",
                    sLengthMenu: "Results :  _MENU_",
                },
                'fnInitComplete': function (oSettings) {
                    $('#vehicle-telematics-unit-list_filter').prepend('<button type="button" class="btn btn-warning btn-sm add-act-btn" data-toggle="modal" data-target="#addTelematicsUnitModalCenter">Add Telematics Unit</button>');
                }
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[telematicsUnitList])
    const onsubmitHandler = (e) => {
        e.preventDefault()
        dispatch(action.add_vehicle_telematics_unit(addTelematicsUnit)).then((data)=>{
            toast.success(data.msg);
            let response = data.data;
            setTelematicsUnitListList([...telematicsUnitList, [response.name, response.telematicsNumber, changeDateFormate(response.subscriptionDate), response.renewadDate ? changeDateFormate(response.renewadDate):null, response.isActive, `<a style="cursor: pointer;" href="/adminside/vehicleTelematicsUnit/view/${response._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${response._id}">View</a>`, response._id, response]]);
            setAddTelematicsUnit({
                name: "",
                telematicsNumber: "",
                subscriptionDate: "",
                renewadDate: "",
                isActive: 1,
            });
            $("#addTelematicsUnitModalCenter .close").click();
        }).catch(error=>{
            toast.error(error);
        })
    }
    const onUpdateHandler = (e) => {
        e.preventDefault()
        dispatch(action.edit_vehicle_telematics_unit(editTelematicsUnit)).then((data)=>{
            toast.success(data.msg);
            let newTelematicsList = [];
            telematicsUnitList.map(item => {
                if(item[6] === editTelematicsUnit.id){
                    let telematics_data = data.data;
                    newTelematicsList.push([telematics_data.name, telematics_data.telematicsNumber, changeDateFormate(telematics_data.subscriptionDate), telematics_data.renewadDate ? changeDateFormate(telematics_data.renewadDate):null, telematics_data.isActive, `<a style="cursor: pointer;" href="/adminside/vehicleTelematicsUnit/view/${telematics_data._id}" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${telematics_data._id}">View</a>`, telematics_data._id, telematics_data]);
                } else {
                    newTelematicsList.push(item);
                }
            })
            setTelematicsUnitListList(newTelematicsList)
            setEditTelematicsUnit({
                id:'',
                name: '',
                telematicsNumber: '',
                subscriptionDate: '',
                renewadDate: '',
                isActive: 1,
            });
            setEditFormEditable(false);
            $("#editTelematicsUnitModalCenter .close").click();
        }).catch(error=>{
            toast.error(error);
        })
    }
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover  theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                <div className="layout-px-spacing">

                    <div className="row layout-top-spacing">

                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                            <div className="widget-content widget-content-area br-6">
                                <h5 className="" style={{margin: '10px'}}>Vehicle Telematics Unit List</h5>
                                <div className="table-responsive mb-4 mt-4">
                                    <table id="vehicle-telematics-unit-list" className="table table-hover" style={{width:'100%'}}>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>No.</th>
                                                <th>Subscription Date</th>
                                                <th>Renewad Date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <Footer></Footer>
            </div>
            </div>
            <div className="modal fade" id="addTelematicsUnitModalCenter" tabindex="-1" role="dialog" aria-labelledby="addTelematicsUnitModalCenterTitle" style={{display: 'none'}} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <form onSubmit={onsubmitHandler}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addTelematicsUnitModalCenterTitle">Add Telematics Unit</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="customer-address-class" id="customer-address-div-id">
                                    <div className="row" id="defaultaddressdiv0">
                                        <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputName-add">Name *</label>
                                                <input type="text" className="form-control" name="name" id="inputName-add" placeholder="Enter Name" required onChange={(e)=>setAddTelematicsUnit({...addTelematicsUnit ,  name:e.target.value})} value={addTelematicsUnit.name}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputTelematicsNumber-add">No. *</label>
                                                <input type="number" name="telematicsNumber" className="form-control" id="inputTelematicsNumber-add" placeholder="Enter No." required onChange={(e)=>setAddTelematicsUnit({...addTelematicsUnit ,  telematicsNumber:e.target.value})} value={addTelematicsUnit.telematicsNumber}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputSubscriptionDate-add">Subscription Date. *</label>
                                                <input type="date" name="subscriptionDate" className="form-control" id="inputSubscriptionDate-add" placeholder="Enter Subscription Date." required onChange={(e)=>setAddTelematicsUnit({...addTelematicsUnit ,  subscriptionDate:e.target.value})} value={addTelematicsUnit.subscriptionDate}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputRenewadDate-add">Renewad Date. </label>
                                                <input type="date" name="renewadDate" className="form-control" id="inputRenewadDate-add" placeholder="Enter Renewad Date." onChange={(e)=>setAddTelematicsUnit({...addTelematicsUnit ,  renewadDate:e.target.value})} value={addTelematicsUnit.renewadDate}/>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12 required">
                                            <label>Vehicle Telematics Unit Status *</label>
                                            <div className="custom-control custom-radio custom-control-inline" id="vehicleStatus">
                                                <input disabled type="radio" id="status2" name="vehicleStatus" className="custom-control-input" value="1" checked/>
                                                <label className="custom-control-label status-radio" for="status2">Active</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input disabled type="radio" id="status3" name="vehicleStatus" className="custom-control-input" value="0"/>
                                                <label className="custom-control-label status-radio" for="status3">Deactive</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>      
                            </div>
                            
                            <div className="modal-footer">
                                <button className="btn" data-dismiss="modal"><i className="flaticon-cancel-12"></i>Discard</button>
                                <button type="submit" className="btn btn-primary view-btn">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="editTelematicsUnitModalCenter" tabindex="-1" role="dialog" aria-labelledby="addTelematicsUnitModalCenterTitle" style={{display: 'none'}} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <form onSubmit={onUpdateHandler}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addTelematicsUnitModalCenterTitle">Update Telematics Unit</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="customer-address-class" id="customer-address-div-id">
                                    <div className="row" id="defaultaddressdiv0">
                                        <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputName-edit">Name *</label>
                                                <input disabled={disabled} type="text" className="form-control" name="name" id="inputName-edit" placeholder="Enter Name" required onChange={(e)=>setEditTelematicsUnit({...editTelematicsUnit ,  name:e.target.value})} value={editTelematicsUnit.name}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputTelematicsNumber-edit">No. *</label>
                                                <input disabled={disabled} type="number" name="telematicsNumber" className="form-control" id="inputTelematicsNumber-edit" placeholder="Enter No." required onChange={(e)=>setEditTelematicsUnit({...editTelematicsUnit ,  telematicsNumber:e.target.value})} value={editTelematicsUnit.telematicsNumber}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputSubscriptionDate-edit">Subscription Date. *</label>
                                                <input disabled={disabled} type="date" name="subscriptionDate" className="form-control" id="inputSubscriptionDate-edit" placeholder="Enter Subscription Date." required onChange={(e)=>setEditTelematicsUnit({...editTelematicsUnit ,  subscriptionDate:e.target.value})} value={changeDateFormate(editTelematicsUnit.subscriptionDate)}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group required">
                                                <label for="inputRenewadDate-edit">Renewad Date. </label>
                                                <input disabled={disabled} type="date" name="renewadDate" className="form-control" id="inputRenewadDate-edit" placeholder="Enter Renewad Date." onChange={(e)=>setEditTelematicsUnit({...editTelematicsUnit ,  renewadDate:e.target.value})} value={editTelematicsUnit.renewadDate ? changeDateFormate(editTelematicsUnit.renewadDate):''}/>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12 required">
                                            <label>Vehicle Telematics Unit Status *</label>
                                            <div className="custom-control custom-radio custom-control-inline" id="vehicleStatus">
                                                {
                                                    editTelematicsUnit.isActive === 1 ? <input disabled={disabled} type="radio" id="status1" name="vehicleStatus" className="custom-control-input" onClick={(e)=>setEditTelematicsUnit({...editTelematicsUnit ,  isActive:e.target.value})} value="1" checked/> : <input disabled={disabled} type="radio" id="status1" name="vehicleStatus" className="custom-control-input" onClick={(e)=>setEditTelematicsUnit({...editTelematicsUnit ,  isActive:e.target.value})} value="1" />
                                                }
                                                <label className="custom-control-label status-radio" for="status1">Active</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                {
                                                    editTelematicsUnit.isActive === 0 ? <input disabled={disabled} type="radio" id="status0" name="vehicleStatus" className="custom-control-input" value="0" onClick={(e)=>setEditTelematicsUnit({...editTelematicsUnit ,  isActive:e.target.value})} checked/> : <input disabled={disabled} type="radio" id="status0" name="vehicleStatus" className="custom-control-input" value="0" onClick={(e)=>setEditTelematicsUnit({...editTelematicsUnit ,  isActive:e.target.value})} />
                                                }
                                                <label className="custom-control-label status-radio" for="status0">Deactive</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>      
                            </div>
                            
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary view-btn" hidden={editButtonHide} onClick={ ()=>setEditFormEditable(true)}>Edit</button>
                                <button type="submit" className="btn btn-primary view-btn" hidden={submitButtonHide}>Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Vehicle_Telematics_Unit