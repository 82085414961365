import {DRIVER_EDIT_SUCCESSFULL, DRIVER_EDIT_ERROR,DRIVER_EDIT_LOADING} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case DRIVER_EDIT_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case DRIVER_EDIT_SUCCESSFULL:{
            return Object.assign({},state,{editDriverData: action.data, loading: false})
        }
        case DRIVER_EDIT_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}