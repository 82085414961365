import React from 'react'
import { format } from 'date-fns'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
export const ExportToExcel = props => {
    const apiData = props.apiData;
    const startDate = props.startDate;
    const endDate = props.endDate;
    const total = props.total;
    const driverDetails = props.driverDetails;
	const changeDateFormate = (date) => {
		if (date) {
			let new_date = String(date);
			let ndate = new_date.split('T');
			let format_date = format(new Date(ndate[0]), 'dd MMM');
			return format_date;
		}
	}
    const changeDate = (date) => {
		if (date) {
			let cd = new Date(date);
            let format_date = format(new Date(cd), 'dd MMM');
            return format_date;
		}
	}
    return (
      <>
	  	<ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success" table="dayWiseAttendanceReportByDriver" filename="dayWiseAttendanceReportByDriver" sheet="tablexls" buttonText="Export as XLS"/>
          <table id="dayWiseAttendanceReportByDriver" style={{display:'none', textAlign:"center"}}>
			<thead>
                <tr style={{padding: '45px'}}>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Driver Id</th>
					<th style={{border:'1px solid black'}}>EV{(driverDetails.Driver_Id) ? driverDetails.Driver_Id :driverDetails._id}</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Driver Name</th>
					<th style={{border:'1px solid black'}}>{driverDetails.name}</th>
				</tr>
                <tr style={{padding: '45px'}}>
					<th colSpan={4} style={{border:'1px solid black', textAlign:'center'}}>{`${changeDate(startDate)} To ${changeDate(endDate)}`}</th>
				</tr>
				<tr style={{padding: '45px'}}>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Date</th>
					<th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>A/P</th>
				</tr>
			</thead>
			<tbody>
				{
					apiData.map((item)=>(
						<tr style={{textAlign: 'center'}}>
							<td style={{border:'1px solid black'}}>{changeDateFormate(item.date)}</td>
							<td style={{border:'1px solid black'}}>{item.status}</td>
						</tr>
					))
				}
			</tbody>
            <tfoot>
                <tr>
                    <th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>Total<br/>Present</th>
                    <th style={{border:'1px solid black',backgroundColor:'#c5d9f1'}}>{total}</th>
                </tr>
            </tfoot>
		</table>
	  </>
    );
};