import React,{useEffect, useState}  from 'react'
import { useNavigate, useParams }from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Footer from '../../DashBoard/Footer';
import Loader from '../../common/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import _ from "lodash";
import * as action from '../../../action/Admin/admin_action';
const Update_Profile = props => {
    const dispatch = useDispatch();
    const [disabled, setDisabled]=useState(true);
    const [editButtonHide, setEditButtonHide]=useState(false);
    const [cancelButtonHide, setCancelButtonHide]=useState(true);
    const [submitButtonHide, setSubmitButtonHide]=useState(true);
    const setEditFormEditable = (type) => {
        if(type){
            setDisabled(false);
            setCancelButtonHide(false);
            setSubmitButtonHide(false);
            setEditButtonHide(true);
        } else {
            setDisabled(true);
            setCancelButtonHide(true);
            setSubmitButtonHide(true);
            setEditButtonHide(false);
        }
    }
    const [input , setInput]= useState({
        name:'',
        email:'',
        mobile:'',
        role_name:'',
    });
    useEffect(() => {
        dispatch(action.admin_profile_details()).then((data)=>{
            let admin_data = data.data;
            let role_name = '';
            if(admin_data.role === 'M'){
                role_name = 'Manager';
            } else if(admin_data.role === 'S'){
                role_name = 'Staff';
            } else if(admin_data.role === 'A'){
                role_name = 'Super Admin';
            } else if(admin_data.role === 'V'){
                role_name = 'Hub Manager';
            } else if(admin_data.role === 'T'){
                role_name = 'Technician';
            } else if(admin_data.role === 'W'){
                role_name = 'Watchman';
            }
            setInput({
                name:admin_data.name,
                email:admin_data.email,
                mobile:admin_data.mobile,
                role_name:role_name,
            });
        }).catch({})
    },[])
    const onsubmitHandler = (e) => {
        e.preventDefault()
        let parameter = {
            mobile: input.mobile,
            name: input.name,
        };
        dispatch(action.admin_profile_update(parameter)).then((data)=>{
            toast.success(data.msg);
            setEditFormEditable(false);
        }).catch(error=>{
            toast.error(error);
        })
    }
    return (
        <>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
        <Header />
        <Sub_Header />
        <div className="main-container" id="container">
            <div className="overlay"></div>
            <div className="search-overlay"></div>
            <SideBar></SideBar>
            <div id="content" className="main-content">
                <div className="layout-px-spacing">
                    <div className="row layout-top-spacing">
                        <div className="col-xl-12 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
                            <div className="user-profile layout-spacing">
                                <form onSubmit={onsubmitHandler}>
                                    <div className="widget-content widget-content-area">
                                        <div className="d-flex justify-content-between mx-3">
                                            <h3 className="">Profile</h3>
                                            <button type="button" className="mt-2 edit-profile " onClick={ ()=>setEditFormEditable(true)} hidden={editButtonHide}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg></button>
                                        </div>
                                        <div className="text-center user-info mb-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#10342c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                            <p className="" id="username">{input.name}</p>
                                        </div>
                                        <div className="user-info-list">
                                            <div className="input-group mb-4 col-md-12">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg></span>
                                                </div>
                                                <input type="text" value={input.role_name} className="form-control" id="inputUserRole" disabled />
                                            </div>
                                            <div className="input-group mb-4 col-md-12">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg></span>
                                                    </div>
                                                    <input type="text" className="form-control" id="inputUserName" value={input.name} placeholder="Enter Name" onChange={(e)=>setInput({...input ,  name:e.target.value})}  disabled={disabled} required />
                                                </div>
                                            </div>
                                            <div className="input-group mb-4 col-md-12">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg></span>
                                                    </div>
                                                    <input type="text" className="form-control" id="inputUserMobile" value={input.mobile} placeholder="Enter Mobile Number" onChange={(e)=>setInput({...input ,  mobile:e.target.value})} pattern="^[6789]\d{9}$" max="12" min="10" disabled={disabled} required />
                                                </div>
                                            </div>
                                            <div className="form-group" id="saveAndCancelButtons">
                                                <button type="button" hidden={cancelButtonHide} onClick={ ()=>setEditFormEditable(false)} class="btn btn-danger mt-3">cancel</button>&nbsp;
                                                <button type="submit" hidden={submitButtonHide} class="btn btn-success mt-3 view-btn">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </div>
    </>
    )
}
export default Update_Profile