import React,{useEffect, useState, useRef}  from 'react'
import { useNavigate }from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Footer from '../../DashBoard/Footer';
import Loader from '../../common/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import _ from "lodash";
import * as action from '../../../action/Admin/admin_action';
const AddAdmin = props => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const nameFocus = useRef();
    const emailFocus = useRef();
    const mobileFocus = useRef();
    const roleFocus = useRef();
    const passwordFocus = useRef();
    const {loading} = useSelector(state => state.AdminAddReducer)
    const [hubList,setHubList]=useState([])
    const [input , setInput]= useState({
        name:'',
        email:'',
        mobile:'',
        role:'',
        password:'',
        vehicleHubId:''
    });
    const [assignRoles , setAssignRoles]= useState([]);
    const [unassignRoles , setUnassignRoles]= useState([1,2,3,24,25,4,5,6,26,27,7,8,9,28,29,10,11,34,12,13,14,15,31,33,16,17,18,32,19,20,21,22,23,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49]);
    const roles = [
        {
            "roleId": 1,
            "sub": []
        },
        {
            "roleId": 2,
            "sub": [
                {
                    "roleId": 3,
                    "sub": [
                        {
                            "roleId": 24,
                            "sub": []
                        },
                        {
                            "roleId": 25,
                            "sub": []
                        },
                        {
                            "roleId": 50,
                            "sub": []
                        }
                    ]
                },
                {
                    "roleId": 4,
                    "sub": []
                }
            ]
        },
        {
            "roleId": 5,
            "sub": [
                {
                    "roleId": 6,
                    "sub": [
                        {
                            "roleId": 26,
                            "sub": []
                        },
                        {
                            "roleId": 27,
                            "sub": []
                        }
                    ]
                },
                {
                    "roleId": 7,
                    "sub": []
                }
            ]
        },
        {
            "roleId": 8,
            "sub": [
                {
                    "roleId": 9,
                    "sub": [
                        {
                            "roleId": 28,
                            "sub": []
                        },
                        {
                            "roleId": 29,
                            "sub": []
                        }
                    ]
                },
                {
                    "roleId": 10,
                    "sub": []
                },
                {
                    "roleId": 11,
                    "sub": [
                        {
                            "roleId": 34,
                            "sub": []
                        },
                    ]
                },
                {
                    "roleId": 12,
                    "sub": []
                },
                {
                    "roleId": 13,
                    "sub": []
                },
                {
                    "roleId": 51,
                    "sub": []
                },
                {
                    "roleId": 52,
                    "sub": []
                },
            ]
        },
        {
            "roleId": 14,
            "sub": [
                {
                    "roleId": 15,
                    "sub": [
                        {
                            "roleId": 31,
                            "sub": []
                        },
                        {
                            "roleId": 33,
                            "sub": []
                        }
                    ]
                },
                {
                    "roleId": 16,
                    "sub": []
                }
            ]
        },
        {
            "roleId": 17,
            "sub": [
                {
                    "roleId": 18,
                    "sub": [
                        {
                            "roleId": 32,
                            "sub": []
                        }
                    ]
                },
                {
                    "roleId": 19,
                    "sub": []
                },
                {
                    "roleId": 20,
                    "sub": []
                },
                {
                    "roleId": 21,
                    "sub": []
                }
            ]
        },
        {
            "roleId": 22,
            "sub": [
                {
                    "roleId": 23,
                    "sub": []
                }
            ]
        },
        {
            "roleId": 35,
            "sub": [
                {
                    "roleId": 36,
                    "sub": [
                        {
                            "roleId": 37,
                            "sub": []
                        },
                        {
                            "roleId": 38,
                            "sub": []
                        }
                    ]
                },
                {
                    "roleId": 39,
                    "sub": []
                }
            ]
        },
        {
            "roleId": 40,
            "sub": [
                {
                    "roleId": 41,
                    "sub": [
                        {
                            "roleId": 42,
                            "sub": []
                        },
                        {
                            "roleId": 43,
                            "sub": []
                        }
                    ]
                },
                {
                    "roleId": 44,
                    "sub": []
                }
            ]
        },
        {
            "roleId": 45,
            "sub": [
                {
                    "roleId": 46,
                    "sub": [
                        {
                            "roleId": 47,
                            "sub": []
                        },
                        {
                            "roleId": 48,
                            "sub": []
                        }
                    ]
                },
                {
                    "roleId": 49,
                    "sub": []
                }
            ]
        },
        {
            "roleId": 53,
            "sub": [
                {
                    "roleId": 54,
                    "sub": [
                        {
                            "roleId": 55,
                            "sub": []
                        },
                        {
                            "roleId": 56,
                            "sub": []
                        }
                    ]
                },
                {
                    "roleId": 57,
                    "sub": []
                }
            ]
        }
    ]
    const getAllRoles = (role)=>{
        if(!role.sub || !role.sub.length){
          return role;
        }
        return [role, _.flatMapDeep(role.sub, getAllRoles)];
      }
    let checkChangeEvent = (e) => {
        let data_value = parseInt(e.target.value);
        let fetchAllnestedRoles = _.flatMapDeep(roles, getAllRoles)
        let findrolesbyId = _.flatMapDeep([_.find(fetchAllnestedRoles, ({ roleId }) => roleId === data_value)],getAllRoles)
        let fetchAllIds= findrolesbyId.map(val => val.roleId)
        if(e.target.checked){
            setAssignRoles([...assignRoles, ...fetchAllIds]);
            setUnassignRoles(unassignRoles.filter(item => !fetchAllIds.includes(item)))
        }
        if(!e.target.checked){
            setUnassignRoles([...unassignRoles, ...fetchAllIds]);
            setAssignRoles(assignRoles.filter(item => !fetchAllIds.includes(item)))
        }
    }
    let checkAvailableValue = (value) => {
        const index = assignRoles.indexOf(value);
            if (index > -1) {
                return true;
            } else {
                return false;
            }
    }
    useEffect(() => {
        dispatch(action.vehicle_hub_list_select()).then((data)=>{
            setHubList(data.data);
        }).catch({})
    },[])
    const onsubmitHandler = (e) => {
        e.preventDefault()
        setErrors({})
        let customErrors = {}
        if(input.name === ''){
            customErrors = {...customErrors, name : "Please enter full name"}
            nameFocus.current.focus();
        } else if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.email)) {
            customErrors = {...customErrors, email: "Please enter valid email"}
            emailFocus.current.focus();
        } else if(!/^[6789]\d{9}$/.test(input.mobile)) {
            customErrors = {...customErrors, mobile: "Please enter valid mobile number"}
            mobileFocus.current.focus();
        } else if(input.role === '') {
            customErrors = {...customErrors, role: "Please select admin type"}
            roleFocus.current.focus();
        } else if(input.password === '') {
            customErrors = {...customErrors, password: "Please enter password"}
            passwordFocus.current.focus();
        }
        if(Object.keys(customErrors).length > 0) {
            setErrors(customErrors)
            return true
        } 
        let parameter = {};
        if(input.role === 'A'){
            parameter = {
                name:input.name,
                email:input.email,
                mobile:input.mobile,
                role:input.role,
                password:input.password,
            };
        } else if(input.role === 'W'){
            parameter = {
                name:input.name,
                email:input.email,
                mobile:input.mobile,
                role:input.role,
                password:input.password,
                assignRoles:assignRoles,
                unassignRoles:unassignRoles,
                vehicleHubId:input.vehicleHubId,
            };
        } else {
            parameter = {
                name:input.name,
                email:input.email,
                mobile:input.mobile,
                role:input.role,
                password:input.password,
                assignRoles:assignRoles,
                unassignRoles:unassignRoles
            };
        }
        dispatch(action.add_admin(parameter)).then((data)=>{
            navigate('/adminside/admin');
        }).catch(error=>{
            toast.error(error);
        })
    }
    return (
        <>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
        <Header />
        <Sub_Header />
        <div className="main-container" id="container">
            {
                loading ? <Loader /> : null
            }
            <div className="overlay"></div>
            <div className="search-overlay"></div>

            <SideBar></SideBar>
            <div id="content" className="main-content">
                <div className="layout-px-spacing">
                    <div className="row layout-top-spacing">
                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                            <div className="widget-content widget-content-area br-6">
                                <h5 className="">Add Admin</h5>
                                <br/>
                                <form onSubmit={onsubmitHandler}>
                                    <div id="append-div-id">
                                        <div className="form-group row">
                                            <div className="form-group col-md-4 required">
                                                <label for="inputName">Full Name *</label>
                                                <input type="text" name="inputName" ref={nameFocus} id="inputName" className="form-control"  placeholder="Enter Full Name" onChange={(e)=>setInput({...input ,  name:e.target.value})} value={input.name}/>
                                                <span style={{color: 'red'}}>{errors?.name}</span>
                                            </div>
                                            <div className="form-group col-md-4 required">
                                                <label for="inputEmail">Email *</label>
                                                <input type="email" className="form-control" ref={emailFocus} id="inputEmail" placeholder="Enter Valid Email Address" onChange={(e)=>setInput({...input ,  email:e.target.value})} value={input.email}/>
                                                <span style={{color: 'red'}}>{errors?.email}</span>
                                            </div>
                                            <div className="form-group col-md-4 required">
                                                <label for="inputMobile">Mobile Number *</label>
                                                <input type="text" className="form-control" ref={mobileFocus} id="inputMobile" placeholder="Enter Mobile Number" onChange={(e)=>setInput({...input ,  mobile:e.target.value})} value={input.mobile} title="Please enter valid mobile number"/>
                                                <span style={{color: 'red'}}>{errors?.mobile}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="form-group col-md-4 required">
                                                <label for="inputType">Admin Type *</label>
                                                <select name="inputType" id="inputType" ref={roleFocus} className="form-control" onChange={(e)=>setInput({...input ,  role:e.target.value})} title="Please select admin type">
                                                    <option value="">Select Role</option>
                                                    <option value="M">Manager</option>
                                                    <option value="S">Staff</option>
                                                    <option value="A">Super Admin</option>
                                                    <option value="H">Hub Manager</option>
                                                    <option value="T">Technician</option>
                                                    <option value="W">Watchman</option>
                                                    <option value="O">Owners</option>
                                                </select>
                                                <span style={{color: 'red'}}>{errors?.role}</span>
                                            </div>
                                            <div className="form-group col-md-4 required">
                                                <label for="inputPassword">Password *</label>
                                                <input type="password" name="inputPassword" ref={passwordFocus} id="inputPassword" className="form-control"  placeholder="Enter Password" autocomplete="new-password"  onChange={(e)=>setInput({...input ,  password:e.target.value})}/>
                                                <span style={{color: 'red'}}>{errors?.password}</span>
                                            </div>
                                            {
                                                input.role === 'W' ?
                                                <div className="form-group col-md-4 required">
                                                    <label for="inputVehicleHub">Select Vehicle Hub *</label>
                                                    <select id="inputVehicleHub" name="type" className="form-control selectpicker" required onChange={(e)=>setInput({...input ,  vehicleHubId:e.target.value})} value={input.vehicleHubId}>
                                                        <option value="">Select Vehicle Hub</option>
                                                        {
                                                            hubList.map((hub)=>(
                                                                <>
                                                                    <option value={hub._id}>{hub.name}</option>
                                                                </>
                                                            ))
                                                        }
                                                    </select>
                                                </div>:''
                                            }
                                        </div>
                                        <div id="menuassigndivid" className="form-group">
                                            <div className="form-group required">
                                                <label for="name">Please Select At Least Any Menu to Assign *</label>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input allcheckcls maintagname" name="menu_to_assign[]" id="menu_to_assignid1" value="1" onChange={(e) => checkChangeEvent(e)}/>
                                                        <label className="custom-control-label" for="menu_to_assignid1">Dashboard</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ width:'100%' }} />

                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="menu_to_assignid2" name="menu_to_assign[]" value="2" onChange={(e) => checkChangeEvent(e)}/>
                                                        <label className="custom-control-label" for="menu_to_assignid2">Customer</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <div className="col-md-12">
                                                            <div className="col-md-12">
                                                                <div className="level1justonlyclass">
                                                                    <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid3" value="3" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(3) ? true:false} disabled={checkAvailableValue(2) ? false:true}/>
                                                                    <label className="custom-control-label" for="menu_to_assignid3">Customer List</label>
                                                                    <div className="level2justonlyclass row" style={{ 'padding-left': '46px' }}>
                                                                        <div className="col-sm-2">
                                                                            <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid24" value="24" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(24) ? true:false} disabled={checkAvailableValue(2) ? false:true}/>
                                                                            <label className="custom-control-label" for="menu_to_assignid24">View Customer</label>
                                                                        </div>
                                                                        <div className="col-sm-2">
                                                                            <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid25" value="25" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(25) ? true:false} disabled={checkAvailableValue(2) ? false:true}/>
                                                                            <label className="custom-control-label" for="menu_to_assignid25">Edit Customer</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="level1justonlyclass">
                                                                    <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid4" value="4" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(4) ? true:false} disabled={checkAvailableValue(2) ? false:true}/>
                                                                    <label className="custom-control-label" for="menu_to_assignid4">Add Customer</label>
                                                                    <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="level1justonlyclass">
                                                                    <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid50" value="50" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(50) ? true:false} disabled={checkAvailableValue(2) ? false:true}/>
                                                                    <label className="custom-control-label" for="menu_to_assignid50">Report</label>
                                                                    <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ width:'100%' }} />

                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid5" value="5" onChange={(e) => checkChangeEvent(e)}/>
                                                        <label className="custom-control-label" for="menu_to_assignid5">Vehicle</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <div className="col-md-12">
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid6" value="6" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(6) ? true:false} disabled={checkAvailableValue(5) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid6">Vehicle List</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px' }}>
                                                                    <div className="col-sm-2">
                                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid26" value="26" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(26) ? true:false} disabled={checkAvailableValue(5) ? false:true}/>
                                                                        <label className="custom-control-label" for="menu_to_assignid26">View Vehicle</label>
                                                                    </div>
                                                                    <div className="col-sm-2">
                                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid27" value="27" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(27) ? true:false} disabled={checkAvailableValue(5) ? false:true}/>
                                                                        <label className="custom-control-label" for="menu_to_assignid27">Edit Vehicle</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid7" value="7" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(7) ? true:false} disabled={checkAvailableValue(5) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid7">Add Vehicle</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ width:'100%'}} />

                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid8" value="8" onChange={(e) => checkChangeEvent(e)}/>
                                                        <label className="custom-control-label" for="menu_to_assignid8">Driver</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <div className="col-md-12">
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid9" value="9" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(9) ? true:false} disabled={checkAvailableValue(8) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid9">Driver List</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                    <div className="col-sm-2">
                                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid28" value="28" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(28) ? true:false} disabled={checkAvailableValue(8) ? false:true}/>
                                                                        <label className="custom-control-label" for="menu_to_assignid28">View Driver</label>
                                                                    </div>
                                                                    <div className="col-sm-2">
                                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid29" value="29" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(29) ? true:false} disabled={checkAvailableValue(8) ? false:true}/>
                                                                        <label className="custom-control-label" for="menu_to_assignid29">Edit Driver</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid10" value="10" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(10) ? true:false} disabled={checkAvailableValue(8) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid10">Add Driver</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid11" value="11" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(11) ? true:false} disabled={checkAvailableValue(8) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid11">To Be Verified</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                    <div className="col-sm-2">
                                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid34" value="34" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(34) ? true:false} disabled={checkAvailableValue(8) ? false:true}/>
                                                                        <label className="custom-control-label" for="menu_to_assignid34">Verify Driver</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid12" value="12" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(12) ? true:false} disabled={checkAvailableValue(8) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid12">Daily Works</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid13" value="13" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(13) ? true:false} disabled={checkAvailableValue(8) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid13">Login Request</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid51" value="51" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(51) ? true:false} disabled={checkAvailableValue(8) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid51">Issues</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid52" value="52" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(52) ? true:false} disabled={checkAvailableValue(8) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid52">Attendance</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ width:'100%' }} />

                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid14" value="14" onChange={(e) => checkChangeEvent(e)}/>
                                                        <label className="custom-control-label" for="menu_to_assignid14">Admins</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <div className="col-md-12">
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid15" value="15" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(15) ? true:false} disabled={checkAvailableValue(14) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid15">Admin List</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                    <div className="col-sm-2">
                                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid31" value="31" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(31) ? true:false} disabled={checkAvailableValue(14) ? false:true}/>
                                                                        <label className="custom-control-label" for="menu_to_assignid31">View Admin</label>
                                                                    </div>
                                                                    <div className="col-sm-2">
                                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid33" value="33" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(33) ? true:false} disabled={checkAvailableValue(14) ? false:true}/>
                                                                        <label className="custom-control-label" for="menu_to_assignid33">Edit Admin</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid16" value="16" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(16) ? true:false} disabled={checkAvailableValue(14) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid16">Add Admin</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{width:'100%' }} />

                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid17" value="17" onChange={(e) => checkChangeEvent(e)}/>
                                                        <label className="custom-control-label" for="menu_to_assignid17">Accounts</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <div className="col-md-12">
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid18" value="18" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(18) ? true:false} disabled={checkAvailableValue(17) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid18">Transaction List</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                    <div className="col-sm-2">
                                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid32" value="32" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(32) ? true:false} disabled={checkAvailableValue(17) ? false:true}/>
                                                                        <label className="custom-control-label" for="menu_to_assignid32">Edit Transaction</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid19" value="19" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(19) ? true:false} disabled={checkAvailableValue(17) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid19">Add Transaction</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid20" value="20" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(20) ? true:false} disabled={checkAvailableValue(17) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid20">Bank Accounts</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="level1justonlyclass">
                                                                <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid21" value="21" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(21) ? true:false} disabled={checkAvailableValue(17) ? false:true}/>
                                                                <label className="custom-control-label" for="menu_to_assignid21">Category</label>
                                                                <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ width: '100%' }} />

                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid22" value="22" onChange={(e) => checkChangeEvent(e)}/>
                                                        <label className="custom-control-label" for="menu_to_assignid22">Custom Fields</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <div className="col-md-12">
                                                            <div className="col-md-12">
                                                                <div className="level1justonlyclass">
                                                                    <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid23" value="23" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(23) ? true:false} disabled={checkAvailableValue(22) ? false:true}/>
                                                                    <label className="custom-control-label" for="menu_to_assignid23">Undelivered Reason</label>
                                                                    <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ width:'100%'}} />
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid35" value="35" onChange={(e) => checkChangeEvent(e)}/>
                                                        <label className="custom-control-label" for="menu_to_assignid35">Lease Company</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <div className="col-md-12">
                                                            <div className="col-md-12">
                                                                <div className="level1justonlyclass">
                                                                    <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid36" value="36" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(36) ? true:false} disabled={checkAvailableValue(35) ? false:true}/>
                                                                    <label className="custom-control-label" for="menu_to_assignid36">Lease Company List</label>
                                                                    <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                        <div className="col-sm-2">
                                                                            <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid37" value="37" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(37) ? true:false} disabled={checkAvailableValue(35) ? false:true}/>
                                                                            <label className="custom-control-label" for="menu_to_assignid37">View Lease Company</label>
                                                                        </div>
                                                                        <div className="col-sm-2">
                                                                            <input type="checkbox" className="custom-control-input allcheckcls" name="menu_to_assign[]" id="menu_to_assignid38" value="38" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(38) ? true:false} disabled={checkAvailableValue(35) ? false:true}/>
                                                                            <label className="custom-control-label" for="menu_to_assignid38">Edit Lease Company</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="level1justonlyclass">
                                                                    <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid39" value="39" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(39) ? true:false} disabled={checkAvailableValue(35) ? false:true}/>
                                                                    <label className="custom-control-label" for="menu_to_assignid39">Add Lease Company</label>
                                                                    <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <hr style={{width:'100%' }} />
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid40" value="40" onChange={(e) => checkChangeEvent(e)}/>
                                                        <label className="custom-control-label" for="menu_to_assignid40">Telematics Unit</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <div className="col-md-12">
                                                            <div className="col-md-12">
                                                                <div className="level1justonlyclass">
                                                                    <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid41" value="41" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(41) ? true:false} disabled={checkAvailableValue(40) ? false:true}/>
                                                                    <label className="custom-control-label" for="menu_to_assignid41">Telematics Unit List</label>
                                                                    <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                        <div className="col-sm-2">
                                                                            <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid42" value="42" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(42) ? true:false} disabled={checkAvailableValue(40) ? false:true}/>
                                                                            <label className="custom-control-label" for="menu_to_assignid42">View Telematics Unit</label>
                                                                        </div>
                                                                        <div className="col-sm-2">
                                                                            <input type="checkbox" className="custom-control-input allcheckcls" name="menu_to_assign[]" id="menu_to_assignid43" value="43" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(43) ? true:false} disabled={checkAvailableValue(40) ? false:true}/>
                                                                            <label className="custom-control-label" for="menu_to_assignid43">Edit Telematics Unit</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="level1justonlyclass">
                                                                    <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid44" value="44" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(44) ? true:false} disabled={checkAvailableValue(40) ? false:true}/>
                                                                    <label className="custom-control-label" for="menu_to_assignid44">Add Telematics Unit</label>
                                                                    <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <hr style={{width:'100%' }} />
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid45" value="45" onChange={(e) => checkChangeEvent(e)}/>
                                                        <label className="custom-control-label" for="menu_to_assignid45">Hub Manager</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <div className="col-md-12">
                                                            <div className="col-md-12">
                                                                <div className="level1justonlyclass">
                                                                    <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid46" value="46" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(46) ? true:false} disabled={checkAvailableValue(45) ? false:true}/>
                                                                    <label className="custom-control-label" for="menu_to_assignid46">Hub Manager List</label>
                                                                    <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                        <div className="col-sm-2">
                                                                            <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid47" value="47" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(47) ? true:false} disabled={checkAvailableValue(45) ? false:true}/>
                                                                            <label className="custom-control-label" for="menu_to_assignid47">View Hub Manager</label>
                                                                        </div>
                                                                        <div className="col-sm-2">
                                                                            <input type="checkbox" className="custom-control-input allcheckcls" name="menu_to_assign[]" id="menu_to_assignid48" value="48" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(48) ? true:false} disabled={checkAvailableValue(45) ? false:true}/>
                                                                            <label className="custom-control-label" for="menu_to_assignid48">Edit Hub Manager</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="level1justonlyclass">
                                                                    <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid49" value="49" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(49) ? true:false} disabled={checkAvailableValue(45) ? false:true}/>
                                                                    <label className="custom-control-label" for="menu_to_assignid49">Add Hub Manager</label>
                                                                    <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{width:'100%' }} />
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid53" value="53" onChange={(e) => checkChangeEvent(e)}/>
                                                        <label className="custom-control-label" for="menu_to_assignid53">Bike Type</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group required">
                                                    <div className="custom-control custom-checkbox">
                                                        <div className="col-md-12">
                                                            <div className="col-md-12">
                                                                <div className="level1justonlyclass">
                                                                    <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid54" value="54" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(54) ? true:false} disabled={checkAvailableValue(53) ? false:true}/>
                                                                    <label className="custom-control-label" for="menu_to_assignid54">Bike Type List</label>
                                                                    <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                        <div className="col-sm-2">
                                                                            <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid55" value="55" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(55) ? true:false} disabled={checkAvailableValue(53) ? false:true}/>
                                                                            <label className="custom-control-label" for="menu_to_assignid55">View Bike Type</label>
                                                                        </div>
                                                                        <div className="col-sm-2">
                                                                            <input type="checkbox" className="custom-control-input allcheckcls" name="menu_to_assign[]" id="menu_to_assignid56" value="56" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(56) ? true:false} disabled={checkAvailableValue(53) ? false:true}/>
                                                                            <label className="custom-control-label" for="menu_to_assignid56">Edit Bike Type</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="level1justonlyclass">
                                                                    <input type="checkbox" className="custom-control-input" name="menu_to_assign[]" id="menu_to_assignid57" value="57" onChange={(e) => checkChangeEvent(e)} checked={checkAvailableValue(57) ? true:false} disabled={checkAvailableValue(53) ? false:true}/>
                                                                    <label className="custom-control-label" for="menu_to_assignid57">Add Bike Type</label>
                                                                    <div className="level2justonlyclass row" style={{ 'padding-left': '46px'}}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{width:'100%' }} />
                                        </div>
                                    </div>
                                    <button type="reset" class="btn btn-danger mt-3">Reset</button>&nbsp;
                                    <button type="submit" class="btn btn-success mt-3 view-btn">Add</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </div>
    </>
    )
}
export default AddAdmin