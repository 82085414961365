import {
    ADD_VEHICLE_TELEMATICS_UNIT_SUCCESSFULL,
    ADD_VEHICLE_TELEMATICS_UNIT_ERROR,
    ADD_VEHICLE_TELEMATICS_UNIT_LOADING,
    VEHICLE_TELEMATICS_UNIT_LIST_SUCCESSFULL,
    VEHICLE_TELEMATICS_UNIT_LIST_ERROR,
    VEHICLE_TELEMATICS_UNIT_LIST_LOADING,
    VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_SUCCESSFULL,
    VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_ERROR,
    VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_LOADING,
    VEHICLE_TELEMATICS_UNIT_DETAILS_SUCCESSFULL,
    VEHICLE_TELEMATICS_UNIT_DETAILS_ERROR,
    VEHICLE_TELEMATICS_UNIT_DETAILS_LOADING,
    VEHICLE_TELEMATICS_UNIT_EDIT_SUCCESSFULL,
    VEHICLE_TELEMATICS_UNIT_EDIT_ERROR,
    VEHICLE_TELEMATICS_UNIT_EDIT_LOADING
} from '../../action/type';

import * as AuthService from '../../service/services'

export const add_vehicle_telematics_unit=(credentail)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: ADD_VEHICLE_TELEMATICS_UNIT_LOADING,
                data: true
            })
            AuthService.AddVehicleTelematicsUnit(credentail)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:ADD_VEHICLE_TELEMATICS_UNIT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:ADD_VEHICLE_TELEMATICS_UNIT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_telematics_unit_list=()=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_TELEMATICS_UNIT_LIST_LOADING,
                data: true
            })
            AuthService.VehicleTelematicsUnitList()
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_TELEMATICS_UNIT_LIST_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_TELEMATICS_UNIT_LIST_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_telematics_unit_status_change=(vehicle_telematics_unit_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_LOADING,
                data: true
            })
            AuthService.VehicleTelematicsUnitStatusChange(vehicle_telematics_unit_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_TELEMATICS_UNIT_STATUS_CHANGE_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const vehicle_telematics_unit_details=(vehicle_telematics_unit_id)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_TELEMATICS_UNIT_DETAILS_LOADING,
                data: true
            })
            AuthService.VehicleTelematicsUnitDetails(vehicle_telematics_unit_id)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_TELEMATICS_UNIT_DETAILS_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_TELEMATICS_UNIT_DETAILS_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}
export const edit_vehicle_telematics_unit=(credential)=>{
    return(dispatch)=>{
        return new Promise((resolve,reject)=>{
            dispatch({
                type: VEHICLE_TELEMATICS_UNIT_EDIT_LOADING,
                data: true
            })
            AuthService.EditVehicleTelematicsUnit(credential)
            .then((response)=>{
                if(response.status===200)   
                {
                    dispatch({
                        type:VEHICLE_TELEMATICS_UNIT_EDIT_SUCCESSFULL,
                        data:response.data
                    })
                    return resolve(response.data);
                }
            })
            .catch((error)=>{
                if(error){
                    dispatch({
                        type:VEHICLE_TELEMATICS_UNIT_EDIT_ERROR,
                        data:{error_msg:error.response.data.msg}
                    })
                }
                return reject(error.response.data.msg)
            })
        })
    }
}