import React, { useEffect, useRef, useState} from 'react'
import { useNavigate, useParams }from 'react-router-dom'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import { format } from 'date-fns'
import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import {ExportToExcel} from './ExportToExcelForDriver'
import Loader from '../../common/Loader/Loader';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/Driver/driver_action';
import {baseURL} from '../../../service/baseService';
import $ from 'jquery'
const Driver_Issue_history = props => {
    $.DataTable = require('datatables.net')
    const driver_id = props.driver_id;
    console.log(driver_id);
    const dispatch = useDispatch();
    var date = new Date();
    const [input , setInput]= useState([
        new Date(date.getFullYear(), date.getMonth(), 1),
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
    ]);
    const changeDateFormate = (date) => {
        if (date) {
            let new_date = String(date);
            let ndate = new_date.split('T');
            let format_date = format(new Date(ndate[0]), 'dd MMM yyyy');
            return format_date;
        }
    }
    const predefinedRanges = [
        {
            label: 'Current month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 60 days',
            value: [subDays(new Date(), 59), new Date()],
            placement: 'left'
        }
    ];
    const [driverIssueList,setDriverIssueList]=useState([]);
    useEffect(() => {
        dispatch(action.Driver_issues_list(driver_id)).then((data)=>{
            let newDriverIssueList = [];
            console.log(data.data);
            let i = 1;  
            data.data.map(item => {
                console.log(item);
                newDriverIssueList.push([i, `<img src="${baseURL}${item.photoURL[0]}" alt="no-image" id="profileImgId" class="form-group p-2 img-responsive imgFile" style="width: 70px; height: 70px; border-radius: 50%;">`, item.driverData.name, item.issue === 'Others' ? item.issueText: item.issue, changeDateFormate(item.createdAt), item._id]);
                i++;
            })
            setDriverIssueList(newDriverIssueList)
        }).catch({})
    },[])
    useEffect(()=>{
        const tableName = "driver-issue-list";
        const table = $(`#${tableName}`).DataTable({
            dom: 'Bfrtip',
            data: driverIssueList,
            columns: [
                {title: "No."},
                {title: "Image"},
                {title: "Driver Name"},
                {title: "Issue"},
                {title: "Date"},
                {title: "View"},
            ],
            columnDefs: [
                {
                    targets: [5],
                    className: 'center',
                    createdCell: (td, cellData, rowData) => ReactDOM.render(
                        <>
                            {/* <a style={{cursor: "pointer", width:'100px'}} onClick={() => assignDriverSet(rowData[9], rowData[7])} className="btn btn-info btn-sm mb-2" data-toggle="modal" data-target="#assignCustomerModalCenter">Assign</a>&nbsp; */}
                            <a style={{cursor: "pointer"}} href={`/adminside/driver/Issueview/${rowData[5]}`} className="btn btn-primary btn-sm mb-2">View</a>&nbsp;
                        </>,td    
                    )
                }
            ],
            destroy: true,  // I think some clean up is happening here
            searching: true,
            oLanguage: {
                oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                //sInfo: "Showing page _PAGE_ of _PAGES_",
                sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                sSearchPlaceholder: "Search...",
                sLengthMenu: "Results :  _MENU_",
            },
        })
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[driverIssueList])
    return (
        <>
            <div className="table-responsive mb-4 mt-4">
                <table id="driver-issue-list" class="table table-hover"
                    style={{width:'100%'}}>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Image</th>
                            <th>Driver Name</th>
                            <th>Issue</th>
                            <th>Date</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default Driver_Issue_history