import {VEHICLE_HUB_EDIT_SUCCESSFULL, VEHICLE_HUB_EDIT_ERROR,VEHICLE_HUB_EDIT_LOADING} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case VEHICLE_HUB_EDIT_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case VEHICLE_HUB_EDIT_SUCCESSFULL:{
            return Object.assign({},state,{editVehicleHubData: action.data, loading: false})
        }
        case VEHICLE_HUB_EDIT_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}