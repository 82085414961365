import {HUB_VIHICLE_UPDATE_HUB_SUCCESSFULL, HUB_VIHICLE_UPDATE_HUB_ERROR,HUB_VIHICLE_UPDATE_HUB_LOADING} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case HUB_VIHICLE_UPDATE_HUB_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case HUB_VIHICLE_UPDATE_HUB_SUCCESSFULL:{
            return Object.assign({},state,{vehicleUpdatehubData: action.data, loading: false})
        }
        case HUB_VIHICLE_UPDATE_HUB_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}