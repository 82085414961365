import {GET_DRIVER_LOCATION_SUCCESSFULL, GET_DRIVER_LOCATION_ERROR, GET_DRIVER_LOCATION_LOADING} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case GET_DRIVER_LOCATION_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case GET_DRIVER_LOCATION_SUCCESSFULL:{
            return Object.assign({},state,{getDriverLocationData: action.data, loading: false})
        }
        case GET_DRIVER_LOCATION_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}