import { UPLOAD_CUSTOMER_INVOICE_LOADING,
    UPLOAD_CUSTOMER_INVOICE_SUCCESSFULL,
    UPLOAD_CUSTOMER_INVOICE_ERROR} from '../../action/type';

const INITIAL_STATE={
    UploadCustomerInvoice: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case UPLOAD_CUSTOMER_INVOICE_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case UPLOAD_CUSTOMER_INVOICE_SUCCESSFULL:{
            return Object.assign({},state,{UploadCustomerInvoice: action.data, loading: false})
        }
        case UPLOAD_CUSTOMER_INVOICE_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}