import React,{useEffect, useState}  from 'react'
import { useNavigate }from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Footer from '../../DashBoard/Footer';
import Loader from '../../common/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import * as action from '../../../action/TechnicianVehiclesAdmin/vehicle_action';
import _ from "lodash";
import './DashBoard.css';
const AddAdmin = props => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dashboardDetails,setDashboardDetails]=useState({});
    useEffect(() => {
        dispatch(action.Vehicle_technician_dashboard_details()).then((data)=>{
            setDashboardDetails(data.data)
        }).catch({})
    },[])
    return (
        <>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">

                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing">
                        <div className="row layout-top-spacing">
                            <div className="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
                                <div className="widget-one">
                                    <div className="widget-content">
                                        <div className="w-numeric-value">
                                            <div className="w-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                            </div>
                                            <div className="w-content">
                                                <span className="w-value" id="totalParcelsId">{dashboardDetails.noOfPendingRepairVehicles}</span>
                                                <span className="w-numeric-title">Assigend</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
                                <div className="widget-one">
                                    <div className="widget-content">
                                        <div className="w-numeric-value">
                                            <div className="w-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                            </div>
                                            <div className="w-content">
                                                <span className="w-value" id="totalParcelsId">{dashboardDetails.noOfWorkingVehicles}</span>
                                                <span className="w-numeric-title">Working on</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing">
                                <div className="widget-one">
                                    <div className="widget-content">
                                        <div className="w-numeric-value">
                                            <div className="w-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                            </div>
                                            <div className="w-content">
                                                <span className="w-value" id="totalParcelsId">{dashboardDetails.noOfRepairedVehicles}</span>
                                                <span className="w-numeric-title">Repaired</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default AddAdmin