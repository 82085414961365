import React,{useEffect, useState, useRef}  from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Header from './Header';
import Sub_Header from './Sub_Header';
import SideBar from './SideBar';
// import DashBoardContent from '../DashBoardContent/DashBoardContent.js';
import Footer from './Footer';
import ReactApexChart from "react-apexcharts";
import './DashBoard.css';
import '../../assets/css/users/user-profile.css';
import '../../assets/plugins/table/datatable/datatables.css';
import '../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../action/Dashboard/dashboard_action';
import $ from 'jquery'
const DashBoard = props => {
    const dispatch = useDispatch();
    $.DataTable = require('datatables.net')
    const [dashboardDetails,setDashboardDetails]=useState({});
    const [dashboardVehicleDetails,setDashboardVehiclesDetails]=useState({});
    const [dashboardCustomerDetails,setDashboardCustomerDetails]=useState({});
    const [totalSubmitedParcel,setTotalSubmittedParcel]=useState(0);
    const [customername, setCustomerName] = useState([]);
    const [totalParcel, setTotalParcel] = useState([]);
    const [totalSuccessfullParcel, setSuccessfullParcel] = useState([]);
    const [totalDrivers, setTotalDriver] = useState([]);
    const [totalDeleiveredParcelCount,settotalDeleiveredParcelCount] = useState([]);
    const [dashboardCustomerDetailsDaily,setdashboardCustomerDetailsDaily] = useState([]);
    const [MonthNames, setMonthNames] = useState([]);
    const [CustomerDetails, setCustomerDetails] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [customerColorList, setCustomerColorList] = useState([]);
    useEffect(() => {
        dispatch(action.dashboard_driver_details()).then((data)=>{
            setDashboardDetails(data.data)
        }).catch({})
    },[])
    useEffect(() => {
        dispatch(action.dashboard_vehicles_details()).then((data)=>{
            setDashboardVehiclesDetails(data.data)
        }).catch({})
    },[])
    useEffect(() => {
        dispatch(action.dashboard_customer_details_daily()).then((data)=>{
            let customerNewList = [];
            let customerColorList = [];
            let monthList = [];
            let customerData = data.data;
            let customerList = data.customerList;
            let Data = [];
            customerList.map(item => {
                customerNewList.push({name: item.name, data: []});
                customerColorList.push(item.colorCode);
            })
            customerData.map(item => {
                customerNewList.forEach((ele) => {
                if(item.customers.length > 0){
                        for(let cust of item.customers){
                            if(ele.name === cust.name){
                                ele.data.push(cust.deliveredParcelCount)
                            }
                        }
                    } else {
                        ele.data.push('0');
                    }
                })
                monthList.push(item.monthName);
            })
            console.log(monthList);
            console.log(customerColorList);
            setCustomerList(customerNewList);
            setCustomerColorList(customerColorList);
            setMonthNames(monthList);
        }).catch({})
    },[])
    const tableName = "customer_daily";
    useEffect(() => {
        dispatch(action.dashboard_driver_details()).then((data)=>{
            let newList = [];
            let custmernames = [];
            let totalP = [];
            let totalS = [];
            let totalD = [];
            let total = [];
            let customerData = data.data.submittedParcel;
            customerData.map(item => {
                total.push(item.successfulParcels);
                newList.push([item.customerName, item.totalDrivers, item.totalParcels, item.successfulParcels, item.percentage]);
                let customer_name = item.customerName.split(' ');
                custmernames.push(customer_name);
                totalP.push(parseInt(item.totalParcels));
                totalS.push(parseInt(item.successfulParcels));
                totalD.push(parseInt(item.totalDrivers));
            })
            total.pop();
            let newTotal = (total.reduce((a,v) =>  a = a + v , 0 ))
            setTotalSubmittedParcel(newTotal);
            custmernames.pop();
            setCustomerName(custmernames);
            totalP.pop();
            setTotalParcel(totalP);
            totalS.pop();
            setSuccessfullParcel(totalS);
            totalD.pop();
            setTotalDriver(totalD);
            setDashboardCustomerDetails(newList)
        }).catch({})
    },[])
    const chartData =  {
        chart: {
            id: "apexchart-example",
            fontFamily: 'Nunito, sans-serif',
            height: 365,
            type: 'area',
            zoom: {
                enabled: false
            },
            dropShadow: {
                enabled: true,
                opacity: 0.3,
                blur: 5,
                left: -7,
                top: 22
            },
            toolbar: {
                show: false
            },
        },
        colors: ['#1b55e2','#01c3c3', '#008f90'],
        dataLabels: {
            enabled: false
        },
        markers: {
            discrete: [{
                seriesIndex: 0,
                dataPointIndex: 7,
                fillColor: '#000',
                strokeColor: '#000',
                size: 5
            }, {
                seriesIndex: 2,
                dataPointIndex: 11,
                fillColor: '#000',
                strokeColor: '#000',
                size: 4
            }]
        },
        subtitle: {
            text: 'Total Delivered Parcel',
            align: 'left',
            margin: 0,
            offsetX: -10,
            offsetY: 35,
            floating: false,
            style: {
                fontSize: '14px',
                color:  '#888ea8'
            }
        },
        title: {
            text: totalSubmitedParcel, // '$10,840',
            align: 'left',
            margin: 0,
            offsetX: -10,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '25px',
                color:  '#0e1726'
            },
        },
        stroke: {
            show: true,
            curve: 'smooth',
            width: 2,
            lineCap: 'square'
        },
        labels: customername,
        xaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                show: true
            },
            labels: {
                offsetX: 2,
                offsetY: 3,
                style: {
                    fontSize: '12px',
                    fontFamily: 'Nunito, sans-serif',
                    cssClass: 'apexcharts-xaxis-title',
                },
            }
        },
        yaxis: {
            labels: {
                formatter: function(value, index) {
                    return (value )
                },
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                    fontFamily: 'Nunito, sans-serif',
                    cssClass: 'apexcharts-yaxis-title',
                },
            }
        },
        grid: {
            borderColor: '#e0e6ed',
            strokeDashArray: 5,
            xaxis: {
                lines: {
                    show: true
                }
            },   
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }, 
        }, 
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            offsetY: -50,
            fontSize: '16px',
            fontFamily: 'Nunito, sans-serif',
            markers: {
                width: 10,
                height: 10,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },    
            itemMargin: {
                horizontal: 0,
                vertical: 20
            }
        },
        tooltip: {
            theme: 'dark',
            marker: {
                show: true,
            },
            x: {
                show: false,
            }
        },
        fill: {
            colors: ['#1b55e2', '#01c3c3', '#008f90'],
            type:"solid",
            // gradient: {
            //     type: "vertical",
            //     shadeIntensity: 1,
            //     inverseColors: !1,
            //     opacityFrom: .28,
            //     opacityTo: .05,
            //     stops: [45, 100]
            // }
        },
        responsive: [{
            breakpoint: 575,
            options: {
                legend: {
                    offsetY: -30,
                },
            },
        }],
        series: [
            {
                name: "Total Parcels",
                data: totalParcel
            },
            {
                name: "Successfull Parcel",
                data: totalSuccessfullParcel
            },
            {
                name: "Total Drivers",
                data: totalDrivers
            }
        ],
    };
    var options = {
        chart: {
            width: "100%",
            height: 350,
            type: "bar"
        },
        colors: ['#1b55e2','#01c3c3', '#008f90'],
        plotOptions: {
            bar: {
                horizontal: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 1,
            colors: ["#fff"]
        },
        subtitle: {
            text: 'Total Delivered Parcel',
            align: 'left',
            margin: 0,
            offsetX: -10,
            offsetY: 35,
            floating: false,
            style: {
                fontSize: '14px',
                color:  '#888ea8'
            }
        },
        title: {
            text: totalSubmitedParcel, // '$10,840',
            align: 'left',
            margin: 0,
            offsetX: -10,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '25px',
                color:  '#0e1726'
            },
        },
        series: [
            {
                name: "Total Parcels",
                data: totalParcel
            },
            {
                name: "Successfull Parcel",
                data: totalSuccessfullParcel
            },
            {
                name: "Total Drivers",
                data: totalDrivers
            }
        ],
        xaxis: {
            categories: customername
        },
        legend: {
            position: "right",
            verticalAlign: "top",
            containerMargin: {
                left: 35,
                right: 60
            }
        },
        responsive: [
            {
                breakpoint: 1000,
                yaxis: {
                    categories: customername
                },
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            columnWidth: '80%'
                        },
                    },
                    legend: {
                        position: "bottom"
                    }
                }
            }
        ]
    };
    var CustomerChartoptions = {
        chart: {
            width: "100%",
            height: 350,
            type: "bar"
        },
        colors: customerColorList,
        plotOptions: {
            bar: {
                horizontal: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 1,
            colors: ["#fff"]
        },
        series: customerList,
        xaxis: {
            categories: MonthNames
        },
        legend: {
            position: "right",
            verticalAlign: "top",
            containerMargin: {
                left: 35,
                right: 60
            }
        },
        responsive: [
            {
                breakpoint: 1000,
                yaxis: {
                    categories: MonthNames
                },
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            columnWidth: '80%'
                        },
                    },
                    legend: {
                        position: "bottom"
                    }
                }
            }
        ]
    };
    useEffect(()=>{
        const table = $(`#${tableName}`).DataTable(
            {
                dom: 'Bfrtip',
                data: dashboardCustomerDetails,
                columns: [
                    { title: "Customer Name"},
                    { title: "Drivers"},
                    { title: "Total Parcel"},
                    { title: "Total Successfully Parcel"},
                    { title: "Eff(%)"}
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                ordering: false,
                oLanguage: {
                    oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                    //sInfo: "Showing page _PAGE_ of _PAGES_",
                    sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                    sSearchPlaceholder: "Search...",
                    sLengthMenu: "Results :  _MENU_",
                },
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[dashboardCustomerDetails])
    return (
        <>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">

                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                {/* <DashBoardContent /> */}
                    <div id="content" className="main-content">
                        <div className="layout-px-spacing">
                            <div className="row layout-top-spacing">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                                    <div className="widget-three">
                                        <div className="widget-heading">
                                            <h5 className="">No. of Drivers</h5>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 layout-spacing">
                                                <div className="widget-one" style={{background:'#198754'}}>
                                                    <div className="widget-content">
                                                        <div className="w-numeric-value">
                                                            <div className="w-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                                            </div>
                                                            <div className="w-content">
                                                                <span className="w-value" id="totalParcelsId">{dashboardDetails.totalDrivers}</span>
                                                                <span className="w-numeric-title">Total</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 layout-spacing">
                                                <div className="widget-one" style={{background:'#198754'}}>
                                                    <div className="widget-content">
                                                        <div className="w-numeric-value">
                                                            <div className="w-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-camera"><path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path><circle cx="12" cy="13" r="4"></circle></svg>
                                                            </div>
                                                            <div className="w-content">
                                                                <span className="w-value" id="totalParcelsId">{dashboardDetails.totalSelfies}</span>
                                                                <span className="w-numeric-title">Taken Selfies</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 layout-spacing">
                                                <div className="widget-one" style={{background:'#198754'}}>
                                                    <div className="widget-content">
                                                        <div className="w-numeric-value">
                                                            <div className="w-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize"><path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path></svg>
                                                            </div>
                                                            <div className="w-content">
                                                                <span className="w-value" id="totalParcelsId">{dashboardDetails.totalVehicleScanned}</span>
                                                                <span className="w-numeric-title">Scanned Bike</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                                    <div className="widget-three">
                                        <div className="widget-heading">
                                            <h5 className="">No. of Vehicle</h5>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 layout-spacing">
                                                <div className="widget-one">
                                                    <div className="widget-content">
                                                        <div className="w-numeric-value">
                                                            <div className="w-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                                            </div>
                                                            <div className="w-content">
                                                                <span className="w-value" id="totalParcelsId">{dashboardVehicleDetails.totalVehicles}</span>
                                                                <span className="w-numeric-title">Total</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 layout-spacing">
                                                <div className="widget-one">
                                                    <div className="widget-content">
                                                        <div className="w-numeric-value">
                                                            <div className="w-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                            </div>
                                                            <div className="w-content">
                                                                <span className="w-value" id="totalParcelsId">{dashboardVehicleDetails.totalActiveVehicles}</span>
                                                                <span className="w-numeric-title">Active</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 layout-spacing">
                                                <div className="widget-one">
                                                    <div className="widget-content">
                                                        <div className="w-numeric-value">
                                                            <div className="w-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                                            </div>
                                                            <div className="w-content">
                                                                <span className="w-value" id="totalParcelsId">{dashboardVehicleDetails.totalDeactiveVehicles}</span>
                                                                <span className="w-numeric-title">Deactive</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 layout-spacing">
                                                <div className="widget-one">
                                                    <div className="widget-content">
                                                        <div className="w-numeric-value">
                                                            <div className="w-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tool"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>
                                                            </div>
                                                            <div className="w-content">
                                                                <span className="w-value" id="totalParcelsId">{dashboardVehicleDetails.totalUnderMaintainenceVehicles}</span>
                                                                <span className="w-numeric-title">Under Maintenance</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                                    <div className="widget widget-chart-one">
                                        <div className="widget-heading">
                                            <h5 className="">No. Of Submit Parcel</h5>
                                            <ul className="tabs tab-pills">
                                                <li><a href="/" id="tb_1" className="tabmenu">Daily</a></li>
                                            </ul>
                                        </div>
                                        {/* <canvas ref={chartContainer} /> */}
                                        <div id='chart'>
                                            <ReactApexChart options={options} type="bar" series={options.series} height="500%"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                                    <div className="widget widget-chart-one">
                                        <div className="widget-heading">
                                            <h5 className=""> Rider in Each Company</h5>
                                            <ul className="tabs tab-pills">
                                                <li><a href="/" id="tb_1" className="tabmenu">Monthly</a></li>
                                            </ul>
                                        </div>
                                        {/* <canvas ref={chartContainer} /> */}
                                        <div id='chartmonth'>
                                            <ReactApexChart options={CustomerChartoptions} type="bar" series={CustomerChartoptions.series} height="500%"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                                    <div className="widget-content widget-content-area br-6">
                                        <h5 className="" style={{margin: '10px'}}>No. of Submitted Parcel</h5>
                                        <div className="table-responsive mb-4 mt-4">
                                            <table id="customer_daily" className="table table-hover" style={{width:'100%'}}>
                                                <thead>
                                                    <tr>
                                                        <th>Customer Name</th>
                                                        <th>Drivers</th>
                                                        <th>Total Parcel</th>
                                                        <th>Total Successfully Parcel</th>
                                                        <th>Eff</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                <Footer />
            </div>
        </>
    )
}
export default DashBoard