import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import * as auth_action from '../../../action/Auth/login_action';
import Loader from '../../common/Loader/Loader';
import { requestFirebaseNotificationPermission, onMessageListener  } from '../../../push-notification';
import "../../../assets/css/structure.css";
import './Login.css';

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [notificationList,setNotificaationList]=useState([]);
    const [deviceToken, setDeviceToken] = useState("");
    const {loading} = useSelector(state => state.loginReducer)
    const [input,
        setInput] = useState({
            Email: "",
            Password: "",
            showPassword: false
        });

    useEffect(() => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            console.log("iOS")
        } else {
            requestFirebaseNotificationPermission()
            .then((firebaseToken) => {
                console.log("firebaseToken",firebaseToken);
                localStorage.setItem("notification-token",firebaseToken)
                setDeviceToken(firebaseToken)
            })
            .catch((err) => {
                console.log("request notification permission denied",err)
            });

            onMessageListener()
            .then((payload) => {
                console.log("payload",payload)
            })
            .catch((err) => {
            console.log("get notification error",err)
            });
        }
        
    },[])
    const handleClickShowPassword = () => {
        setInput({ ...input, showPassword: !input.showPassword });
    };

    function login(e) {
        e.preventDefault()
        validate()
        dispatch(auth_action.login({ email: input.Email, password: input.Password, deviceToken: deviceToken })).then((data) => {
            localStorage.setItem('token', data.data.token);
            localStorage.setItem('email', data.data.email);
            localStorage.setItem('role', data.data.role);
            localStorage.setItem('assignRoles', data.data.assignRoles);
            dispatch(auth_action.notification()).then((data)=>{
                let newnotificationList = [];
                data.AdminNotificationList.map(item => {
                    newnotificationList.push({id: item._id, title:item.title, text:item.text});
                })
                setNotificaationList(newnotificationList)
            }).catch({})
            if(data.data.role === 'A'){
                navigate('/adminside/dashboard');
            } else if(data.data.role === 'H'){
                navigate('/adminside/hub/dashboard');
            } else if(data.data.role === 'T'){
                navigate('/adminside/technician/dashboard');
            } else if(data.data.role === 'W'){
                navigate('/adminside/watchman/dashboard');
            } else if(data.data.role === 'O'){
                navigate('/adminside/owners/dashboard');
            } else {
                navigate('/adminside/dashboard');
            }
        }).catch(error => {
            setInput({ ...input, emailError: error })
        })
    }
    function validate() {
        let emailError = ""
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!input.Email) {
            emailError = "Email is not allowed to be empty"
        } else if (reg.test(input.Email) == false) {
            emailError = "Email must be valid email"
        } else if (!input.Password) {
            emailError = "Password is not allowed to be empty"
        }
        if (emailError) {
            setInput({ ...input, emailError })
            return false
        }
        setInput({ ...input, emailError: "" })
        return true
    }
    return (
        <div className='form login-form'>
            {
                loading ? <Loader /> : null
            }
            <div className="form-container outer">
                <div className="form-form">
                    <div className="form-form-wrap">
                        <div className="form-container">
                            <div className="form-content">
                                <img className="login-logo" src="/assets/img/logo-color.png" />
                                <p className="login-logo-text"> EVIFY </p>
                                <form className="text-left" onSubmit={login}>
                                    <div className="form">
                                        {
                                            input.emailError ?
                                                <div className="alert alert-danger" role="alert" id="login-error-msg">
                                                    {input.emailError}
                                                </div>
                                                : null
                                        }
                                        <div id="username-field" className="field-wrapper input">
                                            <label>EMAIL</label>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" className="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg>
                                            <input id="username" name="username" type="text" className="form-control"
                                                placeholder="Email"
                                                value={input.Email}
                                                onChange={(e) => setInput({ ...input, Email: e.target.value })}
                                            />
                                        </div>
                                        <div id="password-field" className="field-wrapper input mb-2">
                                            <div className="d-flex justify-content-between">
                                                <label>PASSWORD</label>
                                                {/* <a href="auth_pass_recovery_boxed.html" class="forgot-pass-link">Forgot
                                            Password?</a>  */}
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" className="feather feather-lock">
                                                <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                            </svg>
                                            <input id="password" name="password"
                                                type={input.showPassword ? "text" : "password"}
                                                className="form-control"
                                                placeholder="Password"
                                                value={input.Password}
                                                onChange={(e) => setInput({ ...input, Password: e.target.value })}
                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" id="toggle-password" className="feather feather-eye"
                                                onClick={handleClickShowPassword}>
                                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" ></path>
                                                <circle cx="12" cy="12" r="3"></circle>
                                            </svg>
                                        </div>
                                        <div className="d-sm-flex justify-content-between">
                                            <div className="field-wrapper">
                                                <button type="submit" className="btn btn-primary view-btn" id="btnlogin" value="">Log
                                                    In</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;