import {CUSTOMER_EDIT_SUCCESSFULL,CUSTOMER_EDIT_ERROR,CUSTOMER_EDIT_LOADING} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case CUSTOMER_EDIT_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case CUSTOMER_EDIT_SUCCESSFULL:{
            return Object.assign({},state,{customerEditData: action.data, loading: false})
        }
        case CUSTOMER_EDIT_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}