import React, {useEffect, useState, useRef} from 'react'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../common/Loader/Loader';
import * as action from '../../../action/Customer/customer_action';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import $ from 'jquery'
// Modal.setAppElement("#root");
const CustomerShifts = props => {
    const {loading} = useSelector(state => state.shiftListReduces)
    const {Addloading} = useSelector(state => state.AddCustomerShiftReduces)
    const {Editloading} = useSelector(state => state.EditShiftReduces)
    $.DataTable = require('datatables.net')
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const shiftNameFocus = useRef();
    const shiftStartTimeFocus = useRef();
    const shiftEndTimeFocus = useRef();
    const shiftNameEditFocus = useRef();
    const shiftStartTimeEditFocus = useRef();
    const shiftEndTimeEditFocus = useRef();
    const [disabled, setDisabled]=useState(true);
    const [editButtonHide, setEditButtonHide]=useState(false);
    const [submitButtonHide, setSubmitButtonHide]=useState(true);
    const setEditFormEditable = (type) => {
        if(type){
            setDisabled(false);
            setSubmitButtonHide(false);
            setEditButtonHide(true);
        } else {
            setDisabled(true);
            setSubmitButtonHide(true);
            setEditButtonHide(false);
        }
    }
    const [addShift , setAddShift]= useState({
        customerId: props.customer,
        shiftName: "",
        shiftStartTime: "",
        shiftEndTime: ""
    });
    const [editShift , setEditShift]= useState({
        shiftId:"",
        shiftName: "",
        shiftStartTime: "",
        shiftEndTime: ""
    });
    const [shiftList, setShiftList]=useState([]);
    const activeDeactiveAlertPopup = (customer) => {
        dispatch(action.shift_status_change(customer[5])).then((data)=>{
            toast.success(data.msg);
            let newShiftList = [];
            shiftList.map(item => {
                if(item[5] === customer[5]){
                    let shift_data = data.data;
                    newShiftList.push([shift_data.shiftName, shift_data.shiftStartTime, shift_data.shiftEndTime, shift_data.isActive, `<a style="cursor: pointer;" data-toggle="modal" data-target="#shiftModalCenter" class="btn btn-info btn-sm view-btn view-act-btn">View</a>`, shift_data._id]);
                } else {
                    newShiftList.push(item);
                }
            })
            setShiftList(newShiftList)
        }).catch(error=>{
            toast.error(error);
        })
    }
    const setUpdateShift = (customer) => {
        setEditShift({
            shiftId:customer[5],
            shiftName: customer[0],
            shiftStartTime: customer[1],
            shiftEndTime: customer[2]
        });
    }
    const ClearModelData = () => {
        setAddShift({
            customerId: props.customer,
            shiftName: "",
            shiftStartTime: "",
            shiftEndTime: ""
        });
    }
    const tableName1 = "customer-shifts-list";
    useEffect(() => {
        dispatch(action.shift_list(props.customer)).then((data)=>{
            let newShiftList = [];
            data.data.map(item => {
                newShiftList.push([item.shiftName, item.shiftStartTime, item.shiftEndTime, item.isActive, `<a style="cursor: pointer;" data-toggle="modal" data-target="#shiftModalCenter" class="btn btn-info btn-sm view-btn view-act-btn">View</a>`, item._id]);
            })
            setShiftList(newShiftList)
        }).catch({})
    },[])
    useEffect(()=>{
        const table = $(`#${tableName1}`).DataTable(
            {
                data: shiftList,
                columns: [
                    { title: "Shift Name"},
                    { title: "Start Time"},
                    { title: "End Time"},
                    { title: "Status"},
                    { title: "Action"}
                ],
                columnDefs: [
                    {
                        targets: [3],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <a style={{cursor: "pointer"}} className={rowData[3] === 1 ? 'btn btn-success btn-sm status-act-btn' : 'btn btn-danger btn-sm status-act-btn'} onClick={() => activeDeactiveAlertPopup(rowData)}> {rowData[3] === 1 ? 'Active' : 'Deactive'} </a>,td
                        )
                    },
                    {
                        targets: [4],
                        className: 'center',
                        createdCell: (td, cellData, rowData) => ReactDOM.render(
                            <a style={{cursor: "pointer"}} data-toggle="modal" onClick={() => setUpdateShift(rowData)} data-target="#shiftModalCenter" class="btn btn-info btn-sm view-btn view-act-btn">View</a>,td
                        )
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                buttons: [{
                    extend: 'excel',
                    className: 'excelButton view-btn btn text-white float-right mt-2 ml-2',
                }],
                oLanguage: {
                    oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                    //sInfo: "Showing page _PAGE_ of _PAGES_",
                    sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                    sSearchPlaceholder: "Search...",
                    sLengthMenu: "Results :  _MENU_",
                },
                'fnInitComplete': function (oSettings) {
                    $('#customer-shifts-list_filter').prepend('<button type="button" id="ClearData" class="btn btn-warning btn-sm add-act-btn" data-toggle="modal" data-target="#addShiftModalCenter">Add Shift</button>');
                    const myButton = document.querySelector('#ClearData');
                    myButton.addEventListener('click', ClearModelData);
                },
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[shiftList])
    const onsubmitHandler = (e) => {
        e.preventDefault()
        setErrors({})
        let customErrors = {};
        if(addShift.shiftName === '') {
            customErrors = {...customErrors, shiftName: "Please enter shift name"}
            shiftNameFocus.current.focus();
        } else if(addShift.shiftStartTime === '') {
            customErrors = {...customErrors, shiftStartTime: "Please select start time"}
            shiftStartTimeFocus.current.focus();
        } else if(addShift.shiftEndTime === '') {
            customErrors = {...customErrors, shiftEndTime: "Please select end time"}
            shiftEndTimeFocus.current.focus();
        }
        if(Object.keys(customErrors).length > 0) {
            setErrors(customErrors)
            return true
        }
        dispatch(action.add_customer_shift(addShift)).then((data)=>{
            toast.success(data.msg);
            setAddShift({
                customerId: props.customer,
                shiftName: "",
                shiftStartTime: "",
                shiftEndTime: ""
            });
            let response = data.data;
            setShiftList([...shiftList, [response.shiftName, response.shiftStartTime, response.shiftEndTime, response.isActive, `<a style="cursor: pointer;" data-toggle="modal" data-target="#shiftModalCenter" class="btn btn-info btn-sm view-btn view-act-btn" data-id="${response._id}">View</a>`, response._id]]);
            $("#addShiftModalCenter .close").click();
        }).catch(error=>{
            toast.error(error);
        })
    }
    const onUpdateHandler = (e) => {
        e.preventDefault()
        setErrors({})
        let customErrors = {};
        if(editShift.shiftName === '') {
            customErrors = {...customErrors, editShiftName: "Please enter shift name"}
            shiftNameEditFocus.current.focus();
        } else if(editShift.shiftStartTime === '') {
            customErrors = {...customErrors, editShiftStartTime: "Please select start time"}
            shiftStartTimeEditFocus.current.focus();
        } else if(editShift.shiftEndTime === '') {
            customErrors = {...customErrors, editShiftEndTime: "Please select end time"}
            shiftEndTimeEditFocus.current.focus();
        }
        if(Object.keys(customErrors).length > 0) {
            setErrors(customErrors)
            return true
        }
        dispatch(action.edit_customer_shift(editShift)).then((data)=>{
            toast.success(data.msg);
            let newShiftList = [];
            shiftList.map(item => {
                if(item[5] === editShift.shiftId){
                    let shift_data = data.data;
                    newShiftList.push([shift_data.shiftName, shift_data.shiftStartTime, shift_data.shiftEndTime, shift_data.isActive, `<a style="cursor: pointer;" data-toggle="modal" data-target="#shiftModalCenter" class="btn btn-info btn-sm view-btn view-act-btn">View</a>`, shift_data._id]);
                } else {
                    newShiftList.push(item);
                }
            })
            setShiftList(newShiftList)
            setEditShift({
                shiftId:"",
                shiftName: "",
                shiftStartTime: "",
                shiftEndTime: ""
            });
            setEditFormEditable(false);
            $("#shiftModalCenter .close").click();
        }).catch(error=>{
            toast.error(error);
        })
    }
    return (
			<>
				{loading ? <Loader /> : null}
                {
                    Addloading ? <Loader /> : null
                }
                {
                    Editloading ? <Loader /> : null
                }
				{/* <ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
				/> */}
				<div className="table-responsive mb-4 mt-4">
					<table
						id="customer-shifts-list"
						className="table table-hover"
						style={{ width: "100%" }}
					>
						<thead>
							<tr>
								<th>Shift Name</th>
								<th>Start Time</th>
								<th>End Time</th>
								<th>Status</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody></tbody>
					</table>
				</div>
				<div className="modal fade" id="addShiftModalCenter" role="dialog" aria-labelledby="addShiftModalCenterTitle" style={{ display: "none" }} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <form onSubmit={onsubmitHandler}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="addShiftModalCenterTitle"> Add Shift </h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x">
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="customer-shift-class" id="customer-shift-div-id">
                                        <div className="form-group" id="defaultshiftdiv">
                                            <div className="form-group col-md-6">
                                                <label for="inputShiftName-add">Shift Name *</label>
                                                <input type="text" className="form-control" ref={shiftNameFocus} id="inputShiftName-add" name="shiftName" placeholder="Enter Shift Name" onChange={(e)=> setAddShift({ ...addShift, shiftName: e.target.value, }) } value={addShift.shiftName} />
                                                <span style={{color: 'red'}}>{errors?.shiftName}</span>
                                            </div>
                                            <div className="row col-md-9">
                                                <div className="form-group col-md-6">
                                                    <label for="inputShiftStartTime-add"> Shift Start Time * </label>
                                                    <input id="inputShiftStartTime-add" ref={shiftStartTimeFocus} name="shiftStartTime" className="form-control flatpickr flatpickr-input active" type="time" placeholder="Select Time" onChange={(e)=> setAddShift({ ...addShift, shiftStartTime: e.target.value, }) } value={addShift.shiftStartTime} />
                                                    <span style={{color: 'red'}}>{errors?.shiftStartTime}</span>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="inputShiftEndTime-add"> Shift End Time * </label>
                                                    <input id="inputShiftEndTime-add" ref={shiftEndTimeFocus} name="shiftEndTime" className="form-control flatpickr flatpickr-input active" type="time" placeholder="Select Time" onChange={(e)=> setAddShift({ ...addShift, shiftEndTime: e.target.value, }) } value={addShift.shiftEndTime} />
                                                    <span style={{color: 'red'}}>{errors?.shiftEndTime}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn" data-dismiss="modal">
                                        <i className="flaticon-cancel-12"></i>Discard </button>
                                    <button type="submit" className="btn btn-primary view-btn"> Add </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
				<div className="modal fade" id="shiftModalCenter" role="dialog" aria-labelledby="shiftModalCenterTitle" style={{ display: "none" }} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <form onSubmit={onUpdateHandler}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="shiftModalCenterTitle"> Update Shift </h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x">
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="customer-shift-class" id="customer-shift-div-id">
                                        <div id="defaultshiftdiv">
                                            <div className="form-group col-md-6">
                                                <label for="inputShiftName">Shift Name *</label>
                                                <input disabled={disabled} type="text" ref={shiftNameEditFocus} className="form-control" name="shiftName" id="inputShiftName" placeholder="Enter Shift Name" onChange={(e)=> setEditShift({ ...editShift, shiftName: e.target.value, }) } value={editShift.shiftName}/>
                                                <span style={{color: 'red'}}>{errors?.editShiftName}</span>
                                            </div>
                                            <div className="row col-md-9">
                                                <div className="form-group col-md-6">
                                                    <label for="inputShiftStartTime"> Shift Start Time * </label>
                                                    <input disabled={disabled} ref={shiftStartTimeEditFocus} id="inputShiftStartTime" name="shiftStartTime" className="form-control flatpickr flatpickr-input active" type="time" placeholder="Select Time" onChange={(e)=> setEditShift({ ...editShift, shiftStartTime: e.target.value, }) } value={editShift.shiftStartTime}/>
                                                    <span style={{color: 'red'}}>{errors?.editShiftStartTime}</span>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="inputShiftEndTime"> Shift End Time * </label>
                                                    <input disabled={disabled} ref={shiftEndTimeEditFocus} id="inputShiftEndTime" name="shiftEndTime" className="form-control flatpickr flatpickr-input active" type="time" placeholder="Select Time" onChange={(e)=> setEditShift({ ...editShift, shiftEndTime: e.target.value, }) } value={editShift.shiftEndTime}/>
                                                    <span style={{color: 'red'}}>{errors?.editShiftEndTime}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id="editShiftBtn" className="btn btn-primary view-btn" hidden={editButtonHide} onClick={ ()=>setEditFormEditable(true)}> Edit </button>
                                    <button type="submit" id="updateShiftBtn" className="btn btn-primary view-btn" hidden={submitButtonHide}> Update </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
			</>
		);
}
export default CustomerShifts