import {LOGIN_LOADING,LOGIN_SUCCESSFULL,LOGIN_ERROR} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case LOGIN_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case LOGIN_SUCCESSFULL:{
            return Object.assign({},state,{userData: action.data, loading: false})
        }
        case LOGIN_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}