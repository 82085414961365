import React, { useEffect, useRef, useState} from 'react'
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Footer from '../../DashBoard/Footer';
import Header from '../../DashBoard/Header';
import Sub_Header from '../../DashBoard/Sub_Header';
import SideBar from '../../DashBoard/SideBar';
import Loader from '../../common/Loader/Loader';
import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';
import { Marker, InfoWindow } from '@react-google-maps/api';
import '../../../assets/css/users/user-profile.css';
import '../../../assets/plugins/table/datatable/datatables.css';
import '../../../assets/plugins/table/datatable/dt-global_style.css';
import * as action from '../../../action/Driver/driver_action';
import $ from 'jquery'
import { renderToString } from 'react-dom/server'
function Driver_List() {
    $.DataTable = require('datatables.net')
    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    const center = {lat: 21.170240, lng: 72.831062};
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.UnverifiedDriverListReducer)
    const tableRef = useRef();
    const [driverList,setDriverList]=useState([]);
    const DriverVerify = (driver_id) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Verified!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(action.driver_verify(driver_id)).then((data)=>{
                    toast.success(data.msg);
                    let newDriverList = [];
                    driverList.map(item => {
                        if(item[2] !== driver_id){
                            newDriverList.push(item);
                        }
                    })
                    setDriverList(newDriverList)
                }).catch(error=>{
                    toast.error(error);
                })
            }
        })
    }
    const DriverDelete = (driver_id) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete It!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(action.driver_delete(driver_id)).then((data)=>{
                    toast.success(data.msg);
                    let newDriverList = [];
                    driverList.map(item => {
                        if(item[2] !== driver_id){
                            newDriverList.push(item);
                        }
                    })
                    setDriverList(newDriverList)
                }).catch(error=>{
                    toast.error(error);
                })
            }
        })
    }
    useEffect(() => {
        dispatch(action.unverified_driver_list()).then((data)=>{
            let newDriverList = [];
            data.data.map(item => {
                newDriverList.push([item.name, item.mobile, item._id, item]);
            })
            setDriverList(newDriverList)
        }).catch({})
    },[])
    useEffect(()=>{
        const tableName = "driver-list";
        const table = $(`#${tableName}`).DataTable({
            dom: 'Bfrtip',
            buttons: [
                'copyHtml5', 'excelHtml5', 'pdfHtml5', 'csvHtml5'
            ],
            data: driverList,
            columns: [
                { width: "20%", title: "Full Name"},
                { width: "20%", title: "Mobile"},
                { width: "60%", title: "Action"}
            ],
            columnDefs: [
                {
                    targets: [2],
                    className: 'center',
                    createdCell: (td, cellData, rowData) => ReactDOM.render(
                        <>
                            <a style={{cursor: "pointer"}} href={`/adminside/driver/view/${rowData[2]}`} className="btn btn-primary btn-sm mb-2">View</a>&nbsp;
                            <a style={{cursor: "pointer", width:'100px'}} className="btn btn-warning btn-sm mb-2" onClick={() => DriverVerify(rowData[2])}>Verify</a>&nbsp;
                            <a style={{cursor: "pointer"}} onClick={() => DriverDelete(rowData[2])} className="btn btn-dark btn-sm mb-2">Delete</a>
                        </>,td    
                    )
                }
            ],
            destroy: true,  // I think some clean up is happening here
            searching: true,
            oLanguage: {
                oPaginate: { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                //sInfo: "Showing page _PAGE_ of _PAGES_",
                sSearch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                sSearchPlaceholder: "Search...",
                sLengthMenu: "Results :  _MENU_",
            },
        })
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[driverList])
    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover  theme="light"/>
            <Header />
            <Sub_Header />
            <div className="main-container" id="container">
                {
                    loading ? <Loader /> : null
                }
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <SideBar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing">
                        <div className="row layout-top-spacing">
                            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                                <div className="widget-content widget-content-area br-6">
                                    <h5 className="">Driver To Be Verified</h5>
                                    <br/>
                                    <div className="table-responsive mb-4 mt-4">
                                        <table id="driver-list" className="table table-hover" style={{width:"100%"}}>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}
export default Driver_List