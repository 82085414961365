import {ADMIN_ADD_LOADING, ADMIN_ADD_SUCCESSFULL,ADMIN_ADD_ERROR} from '../../action/type';

const INITIAL_STATE={
    userData: {},
    error_msg:"",
    loading: false
}

export default(state=INITIAL_STATE,action)=>{
    switch(action.type)
    {
        case ADMIN_ADD_LOADING:{
            return Object.assign({},state,{loading: true})
        }
        case ADMIN_ADD_SUCCESSFULL:{
            return Object.assign({},state,{adminAddData: action.data, loading: false})
        }
        case ADMIN_ADD_ERROR:{
            return Object.assign({},state,{error_msg:action.data.error_msg, loading: false})
        }
        default:
            return state;
    }
}